import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
  } from "reactstrap"
import React from "react";

const ConfirmModal = ({
    isOpen,
    onClose,
    title,
    description,
    closeBtnLabel,
    confirmBtnLabel,
    onConfirm
  }) => (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {description}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>{confirmBtnLabel}</Button>
        <Button color="secondary" onClick={onClose}>{closeBtnLabel}</Button>
      </ModalFooter>
    </Modal>
  )

  export default React.memo(ConfirmModal);