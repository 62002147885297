import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMPLAINTS,
  GET_COMPLAINTS_COUNT,
  GET_COMPLAINTS_FILE,
} from "./actionTypes"
import { getComplaintsSuccess, getComplaintCountSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getComplaints,
  getComplaintsCount,
  getComplaintsFile,
} from "../../helpers/backend_helper"
import { getComplaintFileSuccess } from "store/actions"

function* fetchComplaints({ payload }) {
  try {
    const response = yield call(getComplaints, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getComplaintsSuccess(response.data.data))
    } else {
      yield put(getComplaintsSuccess([]))
    }
  } catch (error) {
    yield put(getComplaintsSuccess([]))
  }
}

function* fetchComplaintsCount({ payload }) {
  try {
    const response = yield call(getComplaintsCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getComplaintCountSuccess(response.data.data))
    } else {
      yield put(getComplaintCountSuccess({ daily: 0, monthly: 0, weekly: 0 }))
    }
  } catch (error) {
    yield put(getComplaintCountSuccess({ daily: 0, monthly: 0, weekly: 0 }))
  }
}

function* complaintsSaga() {
  yield takeEvery(GET_COMPLAINTS, fetchComplaints)
  yield takeEvery(GET_COMPLAINTS_COUNT, fetchComplaintsCount)
}

export default complaintsSaga
