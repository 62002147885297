import SocketContext from "context/socket"
import { getLocalStorage } from "helpers/common_functions"
import React from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import UserCard from "./OverView/UserCard"
import DailyStatisticsCard from "./OverView/DailyStatisticsCard"
import UserSessionChart from "./OverView/UserSessionChart"
import ComplaintTicketCountChart from "./OverView/ComplaintTicketCountChart"
import OverallRatingCountChart from "./OverView/OverallRatingCountChart"
import UserCountChart from "./OverView/UserCountChart"

const Dashboard = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <UserCard />
            <DailyStatisticsCard />
          </Row>
          <Row>
            <Col xl="6">
              <UserSessionChart />
            </Col>
            <Col xl="6">
              <ComplaintTicketCountChart />
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <OverallRatingCountChart />
            </Col>
            <Col xl="6">
              <UserCountChart />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
