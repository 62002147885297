import {
  GET_COMPLAINTS,
  GET_COMPLAINTS_COUNT,
  GET_COMPLAINTS_COUNT_SUCCESS,
  GET_COMPLAINTS_FILE,
  GET_COMPLAINTS_FILE_SUCCESS,
  GET_COMPLAINTS_SUCCESS,
} from "./actionTypes"

export const getComplaints = filter => ({
  type: GET_COMPLAINTS,
  payload: filter,
})

export const getComplaintsSuccess = list => ({
  type: GET_COMPLAINTS_SUCCESS,
  payload: list,
})

export const getComplaintCount = list => ({
  type: GET_COMPLAINTS_COUNT,
  payload: list,
})

export const getComplaintCountSuccess = list => ({
  type: GET_COMPLAINTS_COUNT_SUCCESS,
  payload: list,
})
