import "flatpickr/dist/themes/material_blue.css"
import {
  getAllAgents,
  getAllUsers,
  getCustomerAgentAuditDetail,
} from "helpers/backend_helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Flatpickr from "react-flatpickr"
import "react-image-lightbox/style.css"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  getCustomerAgentAudits,
  setAllAgents,
  setAllUsers,
} from "../../../store/actions"
import AuditTable from "./AuditTable"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { CSVLink } from "react-csv"

const CustomerAgentAudit = props => {
  const dispatch = useDispatch()
  const {
    customerAgentAudits,
    allUsers,
    allAgents,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const customerAgentAudits = [...state.audit.customerAgentAudits]
    const allUsers = [...state.app.allUsers]
    const allAgents = [...state.app.allAgents]
    const loadingAvalability = state.audit.customerAgentAuditsLoading
    const loading = state.audit.loading
    return {
      customerAgentAudits,
      allUsers,
      allAgents,
      loadingAvalability,
      loading,
    }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])
  const [selectedChat, setSelectedChat] = useState([])
  const [showChatModal, setShowChatModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState({
    id: "ALL",
    name: "ALL",
  })
  const [selectedAgent, setSelectedAgent] = useState({
    id: "ALL",
    name: "ALL",
  })

  const toggleChatModal = () => {
    setShowChatModal(!showChatModal)
  }
  const [usersDropDown, setUsersDropDown] = useState(false)
  const [agentDropDown, setAgentDropDown] = useState(false)

  const columns = [
    {
      dataField: "agentSessioneStartTime",
      text: "Created At",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "user.name",
      text: "User Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "User Phone Number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "agent.name",
      text: "Agent Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    // {
    //   dataField: "agent.email",
    //   text: "Agent Email",
    //   sort: true,
    //   formatter: (rowContent, row) => {
    //     if (rowContent && rowContent !== null) {
    //       return rowContent
    //     } else {
    //       return "-"
    //     }
    //   },
    // },
    {
      dataField: "agentSessioneEndTime",
      text: "Session End Time",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Detail Chat",
      formatter: (rowContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            getCustomerAgentAuditDetail(row.id)
              .then(response => {
                if (response.status === 200 && response.data.data !== null) {
                  setSelectedChat(response.data.data)
                  toggleChatModal()
                }
              })
              .catch(e => {
                toastr.error(e, "Error.")
              })
          }}
        >
          View Chat
        </Button>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: customerAgentAudits.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    dispatch(
      getCustomerAgentAudits({
        startDate: dateArray[0],
        endDate: dateArray[1],
        userId: selectedUser.id,
        agentId: selectedAgent.id,
      })
    )
  }, [])

  useEffect(() => {
    Promise.all([getAllUsers(), getAllAgents()])
      .then(values => {
        let usersResponse = values[0]
        let agentsResponse = values[1]
        // users master
        if (usersResponse && usersResponse.status === 200) {
          dispatch(setAllUsers(usersResponse.data.data))
        }

        // agents master
        if (agentsResponse && agentsResponse.status === 200) {
          dispatch(setAllAgents(agentsResponse.data.data))
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getCustomerAgentAudits({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
        userId: selectedUser.id,
        agentId: selectedAgent.id,
      })
    )
  }

  const onChangeAgents = agent => {
    setSelectedAgent(agent)
    dispatch(
      getCustomerAgentAudits({
        startDate: dateArray[0],
        endDate: dateArray[1],
        userId: selectedUser.id,
        agentId: agent.id,
      })
    )
  }

  const onChangeUsers = user => {
    setSelectedUser(user)
    dispatch(
      getCustomerAgentAudits({
        startDate: dateArray[0],
        endDate: dateArray[1],
        userId: user.id,
        agentId: selectedAgent.id,
      })
    )
  }

  const headers = [columns.map(item => item.text)]

  const exportPDF = download => {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = "Assessment Tax Inquiries"
    const formatters = columns.map(item => item.formatter)
    const data = customerAgentAudits.map(inquiry => [
      formatters[0](inquiry["agentSessioneStartTime"], inquiry),
      formatters[1](inquiry["user"]["name"], inquiry),
      formatters[2](inquiry["user"]["phoneNumber"], inquiry),
      formatters[2](inquiry["agent"]["name"], inquiry),
      formatters[2](inquiry["agent"]["email"], inquiry),
      formatters[3](inquiry["agentSessioneEndTime"], inquiry),
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        fontSize: 7,
      },
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(`Live Agent Audit (${dateArray[0]}-${dateArray[1]}).pdf`)
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const formatters = columns.map(item => item.formatter)
  const detailsArrayOfArrays = customerAgentAudits.map(inquiry => {
    return [
      formatters[0](inquiry["agentSessioneStartTime"], inquiry),
      formatters[1](inquiry["user"]["name"], inquiry),
      formatters[2](inquiry["user"]["phoneNumber"], inquiry),
      formatters[2](inquiry["agent"]["name"], inquiry),
      formatters[2](inquiry["agent"]["email"], inquiry),
      formatters[3](inquiry["agentSessioneEndTime"], inquiry),
    ]
  })
  const cvsData = headers.concat(detailsArrayOfArrays)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Live Agent Audit</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>Live Agent Audit</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={customerAgentAudits}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={customerAgentAudits}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="3">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      console.log(dates)
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col md="1">
                                  <Dropdown
                                    isOpen={agentDropDown}
                                    toggle={() =>
                                      setAgentDropDown(!agentDropDown)
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedAgent && selectedAgent.name}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      style={{
                                        height: "280px",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {allAgents.map(agent => {
                                        return (
                                          <DropdownItem
                                            key={agent.id}
                                            onClick={() => {
                                              onChangeAgents(agent)
                                            }}
                                          >
                                            {agent.name}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </Col>
                                <Col md="1">
                                  <Dropdown
                                    isOpen={usersDropDown}
                                    toggle={() =>
                                      setUsersDropDown(!usersDropDown)
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedUser && selectedUser.name}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      style={{
                                        height: "280px",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {allUsers.map(user => {
                                        return (
                                          <DropdownItem
                                            key={user.id}
                                            onClick={() => {
                                              onChangeUsers(user)
                                            }}
                                          >
                                            {user.name}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-end">
                                    <CSVLink
                                      data={cvsData}
                                      filename={`Complaint Inquiries(${dateArray[0]}-${dateArray[1]}).csv`}
                                      className="btn btn-primary m-2"
                                      target="_blank"
                                    >
                                      CSV
                                    </CSVLink>
                                    <Button
                                      className="m-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(true)
                                      }}
                                    >
                                      <i className="mdi mdi-download" />
                                    </Button>
                                    <Button
                                      className="m-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(false)
                                      }}
                                    >
                                      <i className="mdi mdi-printer" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Audits available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                  <Modal
                                    isOpen={showChatModal}
                                    toggle={toggleChatModal}
                                    size="xl"
                                  >
                                    <ModalHeader
                                      toggle={toggleChatModal}
                                      tag="h4"
                                    ></ModalHeader>
                                    <ModalBody>
                                      <AuditTable messsages={selectedChat} />
                                    </ModalBody>
                                  </Modal>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CustomerAgentAudit)
