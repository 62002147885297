import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link, withRouter } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"
import "react-image-lightbox/style.css"
import {
  deletePrescriptModule,
  getPrescriptModules,
} from "helpers/backend_helper"
import PrescriptModuleCrupdate from "./PrescriptModuleCrupdate"

const PrescriptModuleMaster = props => {
  const [preModules, setPreModules] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false)
  const [selectedModule, setSelectedModule] = useState(null)
  const [languageDropdown, setLanguageDropdown] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState("ALL")

  const getModules = () => {
    getPrescriptModules()
      .then(res => {
        if (res && res.data.data) {
          setPreModules(res.data.data)
          props.onFetch(res.data.data)
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  if (props.forceFetch) {
    getModules()
  }

  useEffect(() => {
    getModules()
  }, [])

  const deleteModule = module => {
    setSelectedModule(module)
    setDeleteConfirmAlert(true)
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "description",
      text: "Name",
      sort: true,
    },
    {
      dataField: "language",
      text: "Language",
      sort: true,
    },
    {
      dataField: "agent.name",
      text: "Added By",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "button",
      text: "Actions",
      formatter: (rowContent, row) => {
        return (
          <div className="d-flex gap-3">
            <button
              className="text-success"
              style={{
                color: "#34c38f",
                border: "none",
                backgroundColor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedModule(row)
                setIsEdit(true)
                setModal(true)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
            </button>
            <button
              className={row.agent_prescript_title_master.length === 0 ? 'text-danger' : 'text-secondary'}
              style={{
                color: "#f46a6a",
                border: "none",
                backgroundColor: "white",
                cursor:
                  row.agent_prescript_title_master.length > 0
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={() => {
                if (row.agent_prescript_title_master.length === 0) {
                  deleteModule(row)
                } else {
                  toastr.error("Delete dependent data first")
                }
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
            </button>
          </div>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: preModules.length,
    custom: true,
  }

  const toggle = () => {
    setModal(!modal)
    getModules()
    if (!modal) {
      setSelectedModule(null)
    }
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <h3>List Prescript Modules</h3>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={preModules.filter(m => {
                  if (selectedLanguage === "ALL") {
                    return true
                  } else {
                    return m.language === selectedLanguage
                  }
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={preModules.filter(m => {
                      if (selectedLanguage === "ALL") {
                        return true
                      } else {
                        return m.language === selectedLanguage
                      }
                    })}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col md="2" className="text-center">
                            <Dropdown
                              isOpen={languageDropdown}
                              toggle={() =>
                                setLanguageDropdown(!languageDropdown)
                              }
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-primary"
                              >
                                {selectedLanguage && selectedLanguage}
                                <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {["ALL", "BM", "EN"].map(lang => {
                                  return (
                                    <DropdownItem
                                      key={lang}
                                      onClick={() => setSelectedLanguage(lang)}
                                    >
                                      {lang}
                                    </DropdownItem>
                                  )
                                })}
                              </DropdownMenu>
                            </Dropdown>{" "}
                          </Col>
                          <Col sm="6">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => {
                                  setSelectedModule(null)
                                  setIsEdit(false)
                                  toggle()
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add New Module
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                noDataIndication={() => (
                                  <div className="text-center">
                                    No modules available
                                  </div>
                                )}
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit ? "Edit Module" : "Add Module"}
                                </ModalHeader>
                                <ModalBody>
                                  <PrescriptModuleCrupdate
                                    module={selectedModule}
                                    hideModal={toggle}
                                  />
                                </ModalBody>
                              </Modal>
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {deleteConfirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setDeleteConfirmAlert(false)
            if (selectedModule && selectedModule.id > 0) {
              deletePrescriptModule({ id: selectedModule.id })
                .then(res => {
                  setSelectedModule(null)
                  getModules()
                  if (res.status === 200) {
                    toastr.success("Delete successful.")
                  } else {
                    toastr.error("Delete error.")
                  }
                })
                .catch(e => {
                  toastr.error(
                    e,
                    "Error."
                  )
                })
            }
          }}
          onCancel={() => setDeleteConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default withRouter(PrescriptModuleMaster)
