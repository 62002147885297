import React, { useState } from "react"
import { withRouter } from "react-router"
import { nameOf } from "helpers/common_functions"
import { Button, Col, FormGroup, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { addPrescriptText, updatePrescriptText } from "helpers/backend_helper"

class PrescriptText {
  constructor(obj) {
    if (obj != null) {
      Object.assign(this, obj)
    } else {
      this.id = 0
      this.code = ""
      this.description = ""
      this.language = "BM"
      this.moduleMasterId = 0
      this.titleMasterId = 0
    }
  }
}

const PrescriptTextCrupdate = props => {
  const [text, setText] = useState(props.text ?? new PrescriptText())
  const [loading, setLoading] = useState(false)

  const valueOnChange = event => {
    if (event.target.name === "language") {
      setText({
        ...text,
        titleMasterId: "",
        moduleMasterId: "",
        [event.target.name]: event.target.value,
      })
      return
    }
    if (event.target.name === "moduleMasterId") {
      setText({
        ...text,
        titleMasterId: "",
        [event.target.name]: event.target.value,
      })
      return
    }
    setText({
      ...text,
      [event.target.name]: event.target.value,
    })
  }

  const saveTitle = () => {
    setLoading(true)
    addPrescriptText(text)
      .then(res => {
        setLoading(false)
        if (res.status === 200) {
          toastr.success("Successfuly saved.")
          props.hideModal()
        } else {
          toastr.error("Save error.")
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  const updateTitle = () => {
    setLoading(true)
    updatePrescriptText(text)
      .then(res => {
        setLoading(false)
        if (res.status === 200) {
          toastr.success("Successfuly updated.")
        } else {
          toastr.error("Update error.")
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  const handleValidSubmit = (event, values) => {
    if (text.moduleMasterId == 0) {
      toastr.error("Please selecte module.")
      return
    }
    if (text.titleMasterId == 0) {
      toastr.error("Please selecte title.")
      return
    }
    if (text.id === 0) {
      saveTitle()
    } else {
      updateTitle()
    }
  }

  const handleInvalidSubmit = (event, errors, values) => {
    if (text.moduleMasterId == 0) {
      toastr.error("Please selecte module.")
      return
    }
    if (text.titleMasterId == 0) {
      toastr.error("Please selecte title.")
      return
    }
  }

  return (
    <React.Fragment>
      <AvForm
        model={new PrescriptText()}
        onValidSubmit={handleValidSubmit}
        onInvalidSubmit={handleInvalidSubmit}
        className="needs-validation"
      >
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-firstname-Input"
            className="col-sm-3 col-form-label"
          >
            Code
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-firstname-Input"
                name={nameOf(text).code}
                value={text.code}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Code" },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Name
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-ic-Input"
                name={nameOf(text).description}
                value={text.description}
                onChange={valueOnChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Enter Description (max 255)",
                  },
                }}
                maxLength={255}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Language
          </Label>
          <Col sm={9}>
            <select
              className="form-control"
              name={nameOf(text).language}
              value={text.language}
              onChange={valueOnChange}
            >
              <option key={"BM"} value={"BM"}>
                Bahasa Malaysia
              </option>
              <option key={"EN"} value={"EN"}>
                English
              </option>
            </select>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Module
          </Label>
          <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(text).moduleMasterId}
              value={text.moduleMasterId}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                SELECT MODULE
              </option>
              {props.modules
                .filter(module => module.language === text.language)
                .map(module => {
                  return (
                    <option key={module.id} value={module.id}>
                      {module.description}
                    </option>
                  )
                })}
            </AvField>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Title
          </Label>
          <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(text).titleMasterId}
              value={text.titleMasterId}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                SELECT TITLE
              </option>

              {props.titles
                .filter(
                  title =>
                    title.language === text.language &&
                    title.moduleId == text.moduleMasterId
                )
                .map(title => {
                  return (
                    <option key={title.id} value={title.id}>
                      {title.description}
                    </option>
                  )
                })}
            </AvField>
          </Col>
        </div>
        <div className="row justify-content-end">
          <Col sm={9}>
            <div className="text-sm-end">
              <Button
                type="submit"
                // onClick={submit}
                color="primary"
                className="w-md"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : null}
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default withRouter(PrescriptTextCrupdate)
