export const GET_AUDIT_USER_SESSIONS = "GET_AUDIT_USER_SESSIONS"
export const GET_AUDIT_USER_SESSIONS_SUCCESS = "GET_AUDIT_USER_SESSIONS_SUCCESS"

export const GET_CUSTOMER_AGENT_AUDITS = "GET_CUSTOMER_AGENT_AUDITS"
export const GET_CUSTOMER_AGENT_AUDITS_SUCCESS =
  "GET_CUSTOMER_AGENT_AUDITS_SUCCESS"

export const GET_AGENT_AUDITS = "GET_AGENT_AUDITS"
export const GET_AGENT_AUDITS_SUCCESS = "GET_AGENT_AUDITS_SUCCESS"

export const GET_CHATBOT_SESSION_COUNT = "GET_CHATBOT_SESSION_COUNT"
export const GET_CHATBOT_SESSION_COUNT_SUCCESS = "GET_CHATBOT_SESSION_COUNT_SUCCESS"

export const GET_LIVE_CHAT_SESSION_COUNT = "GET_LIVE_CHAT_SESSION_COUNT"
export const GET_LIVE_CHAT_SESSION_COUNT_SUCCESS = "GET_LIVE_CHAT_SESSION_COUNT_SUCCESS"
