import {
  SET_AVAILABILITIES,
  SET_DEPARTMENTS,
  SET_RATING_TYPES,
  SET_STATES,
  SET_USER_TYPES,
  SET_REPORT_MODULES,
  SET_REPORT_TRANSACTION_TYPES,
  SET_ALL_USERS,
  SET_ALL_AGENTS,
  SET_DEPARTMENT,
  SAVE_DEPARTMENT,
  SAVE_DEPARTMENT_SUCCESS,
  REMOVE_DEPARTMENT_FROM_REDUX,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT,
  SET_DEPARTMENT_SAVED,
  SET_DEPARTMENT_UPDATED,
  REMOVE_DEPARTMENT,
  GET_WELCOMES,
  GET_WELCOMES_SUCCESS,
  UPDATE_WELCOME,
  REMOVE_WELCOME,
  GET_FAQ,
  GET_FAQ_SUCCESS,
  REMOVE_FAQ_FROM_REDUX,
  SET_FAQ,
  DELETE_FAQ,
  SAVE_FAQ,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  SET_FAQ_UPDATED,
  SHOW_HIDE_LOADING,
  SET_AGENT_TRANSACTIONS,
  SET_AGENT_MODULES,
} from "./actionTypes"

export const setStatesToRedux = states => {
  return {
    type: SET_STATES,
    payload: states,
  }
}

export const setAvailabilitiesToRedux = availabilities => {
  return {
    type: SET_AVAILABILITIES,
    payload: availabilities,
  }
}

export const setUserTypesToRedux = types => {
  return {
    type: SET_USER_TYPES,
    payload: types,
  }
}

export const setRatingTypesToRedux = types => {
  return {
    type: SET_RATING_TYPES,
    payload: types,
  }
}

export const setDepartmentsToRedux = departments => {
  return {
    type: SET_DEPARTMENTS,
    payload: departments,
  }
}

export const SetReportModulesToRedux = departments => {
  return {
    type: SET_REPORT_MODULES,
    payload: departments,
  }
}

export const setReportTransactionTypesToRedux = departments => {
  return {
    type: SET_REPORT_TRANSACTION_TYPES,
    payload: departments,
  }
}

export const setAllUsers = users => {
  return {
    type: SET_ALL_USERS,
    payload: users,
  }
}

export const setAllAgents = departments => {
  return {
    type: SET_ALL_AGENTS,
    payload: departments,
  }
}

export const getDepartmentsSuccess = departments => {
  return {
    type: SET_DEPARTMENTS,
    payload: departments,
  }
}

export const setDeparment = department => {
  return {
    type: SET_DEPARTMENT,
    payload: department,
  }
}

export const saveDepartment = department => {
  return {
    type: SAVE_DEPARTMENT,
    payload: department,
  }
}

export const saveDepartmentSuccess = () => {
  return {
    type: SAVE_DEPARTMENT_SUCCESS,
  }
}

export const updateDepartment = department => {
  return {
    type: UPDATE_DEPARTMENT,
    payload: department,
  }
}

export const updateDepartmentSuccess = () => {
  return {
    type: UPDATE_DEPARTMENT_SUCCESS,
  }
}

export const removeDepartmentFromRedux = () => {
  return {
    type: REMOVE_DEPARTMENT_FROM_REDUX,
  }
}

export const setDepartmentSaved = bool => {
  return {
    type: SET_DEPARTMENT_SAVED,
    payload: bool,
  }
}

export const setDepartmentUpdated = bool => {
  return {
    type: SET_DEPARTMENT_UPDATED,
    payload: bool,
  }
}

export const removeDepartment = id => {
  return {
    type: REMOVE_DEPARTMENT,
    payload: id,
  }
}

export const getWelcomes = () => {
  return {
    type: GET_WELCOMES,
  }
}

export const getWelcomesSuccess = welcomes => {
  return {
    type: GET_WELCOMES_SUCCESS,
    payload: welcomes,
  }
}

export const updateWelcome = welcome => {
  return {
    type: UPDATE_WELCOME,
    payload: welcome,
  }
}

export const removeWelcome = welcome => {
  return {
    type: REMOVE_WELCOME,
    payload: welcome,
  }
}

export const getFAQs = () => {
  return {
    type: GET_FAQ,
  }
}

export const getFAQSuccess = faqs => {
  return {
    type: GET_FAQ_SUCCESS,
    payload: faqs,
  }
}

export const removeFQAFromRedux = () => {
  return {
    type: REMOVE_FAQ_FROM_REDUX,
  }
}

export const setFAQToRedux = faq => {
  return {
    type: SET_FAQ,
    payload: faq,
  }
}

export const deleteFAQ = id => {
  return {
    type: DELETE_FAQ,
    payload: id,
  }
}

export const updateFAQ = faq => {
  return {
    type: UPDATE_FAQ,
    payload: faq,
  }
}

export const updateFAQSuccess = () => {
  return {
    type: UPDATE_FAQ_SUCCESS,
  }
}

export const setFAQUpdated = bool => {
  return {
    type: SET_FAQ_UPDATED,
    payload: bool,
  }
}

export const loader = bool => {
  return {
    type: SHOW_HIDE_LOADING,
    payload: bool,
  }
}

export const setAgentModulesToRedux = modules => {
  return {
    type: SET_AGENT_MODULES,
    payload: modules,
  }
}

export const setAgentTransactionsToRedux = transsactions => {
  return {
    type: SET_AGENT_TRANSACTIONS,
    payload: transsactions,
  }
}
