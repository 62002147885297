import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  GET_CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_AVAILABILITY,
  UPDATE_CURRENT_USER_STATE,
} from "./actionTypes"
import { apiError, currentUserSuccess, loginSuccess } from "./actions"

import {
  postLogin,
  getProfile,
  patchProfile,
} from "../../../helpers/backend_helper"
import { getLocalStorage, setLocalStorage } from "helpers/common_functions"
import { useContext } from "react"
import SocketContext from "context/socket"
import {
  updateCurrentUserAvalabilitySuccess,
  updateCurrentUserStateSuccess,
} from "store/actions"

function* loginUser({ payload: { user, history, socket } }) {
  try {
    const loginResponse = yield call(postLogin, user)
    if (201 === loginResponse.status) {
      setLocalStorage("authUser", loginResponse.data)
      const userResponse = yield call(getProfile)
      if (200 === userResponse.status || 304 === userResponse.status) {
        const obj = { ...loginResponse.data, ...userResponse.data.data }
        setLocalStorage("authUser", obj)
        const authUser = getLocalStorage("authUser")
        if (authUser.permissionLevel === 1) {
          socket.emit("OPERATOR_JOINED", {
            agentId: authUser.id,
            permissionLevel: authUser.permissionLevel,
            availability: authUser.availability,
          })
        }

        yield put(currentUserSuccess(userResponse.data.data))
        yield put(loginSuccess())

        if (authUser.permissionLevel === 1) {
          history.push("/chat")
        } else if (authUser.permissionLevel === 2) {
          history.push("/")
        } else if (authUser.permissionLevel === 3) {
          history.push("/")
        } else if (authUser.permissionLevel === 5) {
          history.push("/master-data")
        } else if (authUser.permissionLevel === 4) {
          history.push("/archive")
        } else if (authUser.permissionLevel === 6) {
          history.push("/")
        }
      } else {
        yield put(apiError("No profile data found. Please re-login."))
      }
    } else if (204 === loginResponse.status) {
      yield put(apiError("No user found. Please check you credentials."))
    } else if (400 === loginResponse.status) {
      console.log("saga => 400 === loginResponse.status => ", loginResponse)
      yield put(apiError(loginResponse.data.errors.message))
    }
  } catch (error) {
    console.log("saga => loginUser => ", error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history, socket } }) {
  console.log('socket:: ', socket)
  try {
    const authUser = getLocalStorage("authUser")
    console.log('auth usre: ', authUser)
    if (authUser.permissionLevel === 1) {
      socket.emit("USER_LOGOUT");
    }

    localStorage.removeItem("authUser")
    localStorage.clear()
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* profile() {
  try {
    const userResponse = yield call(getProfile)
    if (200 === userResponse.status || 304 === userResponse.status) {
      yield put(currentUserSuccess(userResponse.data.data))
    } else {
      yield put(apiError("No profile data found. Please re-login."))
    }
  } catch (error) {
    yield put(apiError("No profile data found. Please re-login."))
  }
}

function* updateProfile({ payload, type }) {
  try {
    const userResponse = yield call(patchProfile, payload)
    if (200 === userResponse.status || 304 === userResponse.status) {
      const userResponse = yield call(getProfile)
      if (200 === userResponse.status || 304 === userResponse.status) {
        const authUser = getLocalStorage("authUser")
        const obj = { ...authUser, ...userResponse.data.data }
        setLocalStorage("authUser", obj)
        yield put(currentUserSuccess(userResponse.data.data))
        if (type === UPDATE_CURRENT_USER_STATE) {
          yield put(updateCurrentUserStateSuccess())
        } else if (type === UPDATE_CURRENT_USER_AVAILABILITY) {
          yield put(updateCurrentUserAvalabilitySuccess())
        }
      } else {
        yield put(apiError("No profile data found. Please re-login."))
      }
    } else {
      yield put(apiError("No profile data found. Please re-login."))
    }
  } catch (error) {
    yield put(apiError("No profile data found. Please re-login."))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(GET_CURRENT_USER, profile)
  yield takeEvery(UPDATE_CURRENT_USER, updateProfile)
  yield takeEvery(UPDATE_CURRENT_USER_STATE, updateProfile)
  yield takeEvery(UPDATE_CURRENT_USER_AVAILABILITY, updateProfile)
}

export default authSaga
