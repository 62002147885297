import MetaTags from "react-meta-tags"
import React, { useContext, useState, useEffect } from "react"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { loginUser, apiError, removeApiError } from "../../store/actions"
import profile from "assets/images/chatbot_1_latest.jpg"
import SocketContext from "context/socket"
import { getLocalStorage } from "helpers/common_functions"
import md5 from "md5"
import logo from "../../assets/images/chatbot_logo.png"

const Login = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)
  const [password, setPassword] = useState("")
  const handleValidSubmit = (event, credentials) => {
    if (!props.loading) {
      const values = { ...credentials, password: md5(credentials.password) }
      props.loginUser(values, history, socket)
    }
  }

  useEffect(() => {
    if (props.error && typeof props.error === "string") {
      setPassword("")
    }
    // return () => {
    //   setInterval(() => {
    //     dispatch(removeApiError())
    //   }, 10000)
    // }
  }, [props.error])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <marquee><h5 className="text-primary">Selamat Datang ke Chatbot DBKL</h5></marquee>
                        <img src={logo} width="200" />
                        {/* <p>Sign in to continue to Skote.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="staffId"
                          label="ID Staf"
                          value=""
                          className="form-control"
                          placeholder="Masukkan ID Staf"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Katalaluan"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          type="password"
                          placeholder="Masukkan katalaluan"
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={props.loading}
                        >
                          {props.loading ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          ) : null}
                          Log Masuk
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loginSuccess, loading } = state.Login
  return { error, loginSuccess, loading }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)
