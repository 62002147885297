import moment from "moment"
import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Badge, Card, CardBody, Col, Row } from "reactstrap"

const AuditTable = props => {
  const { messsages } = props

  const pageOptions = {
    sizePerPage: 6,
    totalSize: messsages.length,
    custom: true,
  }

  const columns = [
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "user.name",
      text: "User Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (row.transactionType !== 33) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "Phone Number",
      sort: true,
      formatter: (rowContent, row) => {
        if (row.transactionType !== 33) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "report_transaction_master.description",
      text: "Report Transaction",
      sort: true,
      formatter: (cellContent, row) => {
        if (row.transactionType !== 33) {
          return (
            <Badge
              className={"font-size-12 badge-soft-warning"}
              color={"warning"}
              pill
            >
              {cellContent}
            </Badge>
          )
        } else {
          return (
            <Badge
              className={"font-size-12 badge-soft-success"}
              color={"success"}
              pill
            >
              {cellContent}
            </Badge>
          )
        }
      },
    },
    {
      dataField: "user_session.agent.name",
      text: "Agent Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (row.transactionType === 33) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user_session.agent.email",
      text: "Agent Email",
      sort: true,
      formatter: (rowContent, row) => {
        if (row.transactionType === 33) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "remarks",
      text: "Message",
      sort: true,
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <div className="mb-4 h4 card-title">Latest Transaction</div> */}
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={messsages}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={messsages}
                columns={columns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row> */}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AuditTable
