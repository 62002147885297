import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import moment from "moment"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import {
  addMessage,
  getActiveChats,
  getInactiveChats,
  getMessages,
  updateChat,
  setCurrentChat,
  setMessages,
  filterActiveChats,
  filterInactiveChats,
  removeChatFromChatLists,
  setChatSessionStatus
} from "../../../store/actions"
import SocketContext from "../../../context/socket"
import { formattingParagraph, getLocalStorage } from "helpers/common_functions"
import classnames from "classnames"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import ReactTooltip from "react-tooltip"
import { preTexts } from "./data"
import { getAllPerscripts } from "helpers/backend_helper"
import emptyChat from "../../../assets/images/empty-chat.svg"
import noNewChats from "../../../assets/images/no-new-chats.svg"

const Chat = props => {
  const socket = React.useContext(SocketContext)
  const {
    activeChats,
    messages,
    filteredActiveChats,
    filteredInactiveChats,
  } = props
  const [messageBox, setMessageBox] = useState(null)
  const [Chat_Box_Username, setChat_Box_Username] = useState("")
  const [curMessage, setcurMessage] = useState("")
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()
  const [searchChatText, setSearchChatText] = useState("")
  const { onGetChats } = props
  const authUser = getLocalStorage("authUser")

  useEffect(() => {
    dispatch(getActiveChats())
    dispatch(getInactiveChats())
  }, [])

  useEffect(() => {
    const hasCurrentChat = props.activeChats.find(chat => {
      return props.currentChat && props.currentChat.id == chat.id ? true : false
    })
    if (hasCurrentChat === undefined || hasCurrentChat === null) {
      dispatch(setCurrentChat(null))
      dispatch(setMessages([]))
    }
  }, [props.activeChats])

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom()
  }, [messages])

  useEffect(() => {
    socket.on("CUSTOMER_TO_OPERATOR_MESSAGE", response => {
      console.log("CUSTOMER_TO_OPERATOR_MESSAGE ", response);
      dispatch(addMessage(response.message))
    })
    socket.on("CUSTOMER_DISCONNECTED", response => {
      console.log("CUSTOMER_DISCONNECTED ", response);
      dispatch(setChatSessionStatus(response))
      // dispatch(setCurrentChat(null))
      // dispatch(setMessages([]))
    })

    socket.on("OPERATOR_SOCKET_STORE", (socketId) => {
     console.log('agent socketId: ', socketId)
     localStorage.setItem("op:socket", socketId);
    });

    socket.on("reconnect", attempt => {
      console.log("reconnet from react", socket.id)
      socket.emit("OPERATOR_RECONNECT", localStorage.getItem("op:socket"));
      localStorage.setItem("op:socket", socket.id)
    })
  }, [])

  const didSelectChat = chat => {
    if (chat !== null && chat !== undefined) {
      setChat_Box_Username(chat.name)
      dispatch(setCurrentChat(chat))
      dispatch(getMessages(chat.id))
      if (chat.agentSessioneStartTime === null) {
        if (authUser) {
          setcurMessage("")
        }
      } else {
        setcurMessage("")
      }
    } else {
      dispatch(setCurrentChat(null))
      dispatch(setMessages([]))
    }
  }

  const addMessageToChat = () => {
    const currentChat = props.currentChat
    if (currentChat && curMessage && curMessage.length > 0) {
      var emitType =
        currentChat.agentSessioneStartTime === null
          ? "OPERATOR_CONVERSATION_START"
          : "OPERATOR_TO_CUSTOMER_MESSAGE"
      socket.emit(emitType, {
        queryInput: {
          event: {
            name: emitType,
            utterance: curMessage,
            languageCode: "EN",
          },
          socketId: currentChat.socketId,
          session: currentChat.id,
        },
      })

      if (emitType === "OPERATOR_CONVERSATION_START") {
        const updatedCurrentChat = {
          ...currentChat,
          agentSessioneStartTime: new Date(),
        }
        dispatch(setCurrentChat(updatedCurrentChat))
        const currentActiveChat = activeChats.find(
          chat => chat.id === currentChat.id
        )
        if (currentActiveChat) {
          currentActiveChat.agentSessioneStartTime = new Date()
          dispatch(updateChat(currentActiveChat))
        }
      }
      if (messages.length > 0) {
        const message = {
          id: messages[messages.length - 1].id + 1,
          remarks: curMessage,
          sessionId: currentChat.id,
          report_transaction_master: {
            id: 34,
            description: "Agent",
          },
          type: "AGENT",
          userId: 10,
          createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        }
        setcurMessage("")
        dispatch(addMessage(message))
      }
    }
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      addMessageToChat()
    }
  }

  const endChat = () => {
    const currentChat = props.currentChat
    if (currentChat) {
      socket.emit("OPERATOR_CONVERSATION_END", {
        queryInput: {
          event: {
            name: "OPERATOR_CONVERSATION_END",
            utterance: "Conversation Ended by operator",
            languageCode: "EN",
          },
          socketId: currentChat.socketId,
          session: currentChat.id,
        },
      })
    }
    dispatch(removeChatFromChatLists(currentChat))
    dispatch(setCurrentChat(null))
    dispatch(setMessages([]))
    setTimeout(() => {
      dispatch(getInactiveChats())
      toastr.success(
        "your chat move to offline chat section.",
        "Successfuly ended."
      )
    }, 2000)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      searchChat("")
    }
    if (tab === "1") {
      const chats = getSessionChats(filteredActiveChats)
      didSelectChat(chats[0])
    } else if (tab === "2") {
      const chats = getSessionChats(filteredInactiveChats)
      if (chats.length > 0) {
        didSelectChat(chats[0])
      }
    } else if (tab === "3") {
    }
  }

  const searchChat = searchText => {
    setSearchChatText(searchText)
    if (activeTab === "1") {
      dispatch(filterActiveChats(searchText))
    } else if (activeTab === "2") {
      dispatch(filterInactiveChats(searchText))
    } else if (activeTab === "2") {
      console.log("searchText =>", searchText)
    }
  }

  const getSessionChats = list => {
    if (list.length > 0) {
      const array = Array()
      list.forEach(session => {
        array.push({
          id: session.id,
          chatId: session.user.id,
          name: session.user.name,
          agentSessioneStartTime: session.agentSessioneStartTime,
          sessionActive: session.sessionActive,
          socketId: session.socketId,
        })
      })
      return array
    }
    return []
  }

  const [preTextLang, setPreTextLang] = useState("EN")
  const [englishModules, setEnglishModules] = useState([])
  const [bashaMalaysiaModules, setBashaMalaysiaModules] = useState([])
  const [selectedTitles, setSelectedTitles] = useState([])

  useEffect(() => {
    getAllPerscripts("EN")
      .then(res => {
        if (res && res.data.data && res.status === 200) {
          setEnglishModules(res.data.data)
          onChangeLanguage("EN")
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
    getAllPerscripts("BM")
      .then(res => {
        if (res && res.data.data && res.status === 200) {
          setBashaMalaysiaModules(res.data.data)
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [])

  const onChangeLanguage = lang => {
    setPreTextLang(lang)
    if (lang === "EN") {
      if (englishModules.length > 0) {
        setSelectedTitles(englishModules[0].agent_prescript_title_master)
      }
    } else if (lang === "BM") {
      if (bashaMalaysiaModules.length > 0) {
        setSelectedTitles(bashaMalaysiaModules[0].agent_prescript_title_master)
      }
    }
  }

  const onChangeModule = e => {
    if (e.target.value) {
      if (preTextLang === "EN") {
        setSelectedTitles(
          englishModules[e.target.value].agent_prescript_title_master
        )
      } else if (preTextLang === "BM") {
        setSelectedTitles(
          bashaMalaysiaModules[e.target.value].agent_prescript_title_master
        )
      }
    }
  }
  let status = props.profile
    ? props.availabilities.find(s => {
        if (s.id + "" == props.profile.availability) {
          return s.type_code
        }
      })?.type_code
    : ""
  let statusColor = () => {
    switch (props.profile?.availability) {
      case 1:
      case "1":
        return "text-success"
      case 2:
      case "2":
        return "text-warning"
      case 3:
      case "3":
        return "text-warning"
      case 4:
      case "4":
        return "text-danger"
      default:
        return "text-secondary"
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Chat</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="w-25 chat-leftsidebar me-lg-4">
                  <div className="">
                    <div className="py-4 border-bottom">
                      <Media>
                        <div className="align-self-center">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title bg-warning rounded-circle font-size-16">
                              {authUser &&
                                authUser.name.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        </div>
                        <Media body>
                          <h5 className="font-size-15 mt-0 mb-1">
                            {authUser && authUser.name}
                          </h5>

                          <p className="text-muted mb-0">
                            <i
                              className={
                                "mdi mdi-circle align-middle me-1 " +
                                statusColor()
                              }
                            ></i>
                            {status}
                          </p>
                        </Media>
                      </Media>
                    </div>

                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={e => searchChat(e.target.value)}
                          value={searchChatText}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Online</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">History</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                              onChangeLanguage("EN")
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span
                              className="d-none d-sm-block"
                              style={{ fontStyle: "italic" }}
                            >
                              Texts
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            {getSessionChats(filteredActiveChats).length > 0 ? (
                              <ul className="list-unstyled chat-list">
                                <PerfectScrollbar style={{ height: "400px" }}>
                                  {map(
                                    getSessionChats(filteredActiveChats),
                                    chat => (
                                      <li
                                        key={chat.id}
                                        className={
                                          props.currentChat &&
                                          props.currentChat.id === chat.id
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            didSelectChat(chat)
                                          }}
                                        >
                                          <Media>
                                            <div className="align-self-center me-3">
                                              <i
                                                className={`mdi mdi-circle ${
                                                  chat.sessionActive
                                                    ? "text-success"
                                                    : "text-secondary"
                                                } font-size-10`}
                                              />
                                            </div>
                                            <div className="align-self-center">
                                              <div className="avatar-xs me-3">
                                                <span
                                                  className={`avatar-title ${
                                                    chat.sessionActive
                                                      ? "bg-primary"
                                                      : "bg-secondary"
                                                  } rounded-circle font-size-16`}
                                                >
                                                  {chat.name
                                                    .charAt(0)
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="align-self-center">
                                              <h5 className="text-truncate font-size-14 mb-1">
                                                {chat.name + " - " + chat.id}
                                              </h5>
                                            </div>
                                          </Media>
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </PerfectScrollbar>
                              </ul>
                            ) : (
                              <div className="card center-block">
                                <img
                                  src={noNewChats}
                                  className="img-fluid d-block p-5 mx-auto"
                                />
                                <div className="text-center mb-5">
                                  <h4>No data found</h4>

                                </div>
                              </div>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <ul className="list-unstyled chat-list">
                              <PerfectScrollbar style={{ height: "400px" }}>
                                {map(
                                  getSessionChats(filteredInactiveChats),
                                  chat => (
                                    <li
                                      key={chat.id}
                                      className={
                                        props.currentChat &&
                                        props.currentChat.id === chat.id
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          didSelectChat(chat)
                                        }}
                                      >
                                        <Media>
                                          <div className="align-self-center">
                                            <div className="avatar-xs me-3">
                                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                {chat.name
                                                  .charAt(0)
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="align-self-center">
                                            <h5 className="text-truncate font-size-14 mb-1">
                                              {chat.name + " - " + chat.id}
                                            </h5>
                                          </div>
                                        </Media>
                                      </Link>
                                    </li>
                                  )
                                )}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div className="pb-3">
                            <Row>
                              <Col>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    onChange={() => onChangeLanguage("EN")}
                                    checked={preTextLang === "EN"}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    English
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    onChange={() => onChangeLanguage("BM")}
                                    checked={preTextLang === "BM"}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    Bahasa Malaysia
                                  </label>
                                </div>
                              </Col>
                              <Col
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div className="col-md-10">
                                  <select
                                    className="form-control"
                                    onChange={onChangeModule}
                                  >
                                    {preTextLang === "EN"
                                      ? map(englishModules, (module, index) => {
                                          return (
                                            <option
                                              key={module.id}
                                              value={index}
                                            >
                                              {module.description}
                                            </option>
                                          )
                                        })
                                      : map(
                                          bashaMalaysiaModules,
                                          (module, index) => {
                                            return (
                                              <option
                                                key={module.id}
                                                value={index}
                                              >
                                                {module.description}
                                              </option>
                                            )
                                          }
                                        )}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <ul className="list-unstyled chat-list">
                              <PerfectScrollbar style={{ height: "400px" }}>
                                {map(selectedTitles, title => {
                                  return (
                                    <div key={title.id}>
                                      <h5 className="font-size-14 mt-3 p-2">
                                        {title.description}
                                      </h5>

                                      <ul className="list-unstyled chat-list">
                                        {map(title.agent_prescript, text => {
                                          return (
                                            <li key={text.id}>
                                              <div
                                                className="instead-link"
                                                onClick={() =>
                                                  setcurMessage(
                                                    text.description
                                                  )
                                                }
                                              >
                                                <h5 className="font-size-12 mb-0">
                                                  <span dangerouslySetInnerHTML={{__html:formattingParagraph(text.description)}}></span>
                                                </h5>
                                              </div>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </div>
                                  )
                                })}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  {!props.currentChat && (
                    <div className="card chat-conversation mb-5">
                      <div className="w-75 container center-block">
                        <img
                          src={emptyChat}
                          className="img-fluid d-block p-5 mx-auto"
                        />
                        <div className="text-center mb-5">
                          <h2>No data found</h2>
                          {/* <p>
                            Fusce nec mauris quis libero accumsan dapibus ac
                            quis ligula. Donec in elit id quam convallis luctus.
                          </p> */}
                        </div>
                      </div>
                    </div>
                  )}

                  {props.currentChat && (
                    <Card>
                      <div className="p-4 border-bottom ">
                        <Row>
                          <Col md="4" xs="9">
                            <h5 className="font-size-15 mb-1">
                              {props.currentChat && props.currentChat.name}
                            </h5>
                            <div>
                              {props.currentChat && (
                                <p className="text-muted mb-0">
                                  <i
                                    className={`mdi mdi-circle ${
                                      activeTab === "1"
                                        ? props.currentChat.sessionActive ? "text-success" : "text-secondary"
                                        : "text-danger"
                                    } align-middle me-1`}
                                  />
                                  {activeTab === "1" ? props.currentChat.sessionActive ? "online": "away" : "offline"}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div>
                        <div className="chat-conversation p-3">
                          <ul className="list-unstyled">
                            <PerfectScrollbar
                              style={{ height: "500px" }}
                              containerRef={ref => setMessageBox(ref)}
                            >
                              {/* {messages && messages.length === 0 && (
                                <div>show loader</div>
                              )} */}
                              {messages &&
                                map(messages, message => (
                                  <li
                                    key={"test_k" + message.id}
                                    className={
                                      message.report_transaction_master
                                        ?.description === "Agent"
                                        ? "right"
                                        : ""
                                    }
                                  >
                                    <div className="conversation-list">
                                      <div className="ctext-wrap">
                                        <div className="conversation-name">
                                          {message.report_transaction_master
                                            ?.description !== "Agent"
                                            ? props.currentChat &&
                                              props.currentChat.name
                                            : "Agent"}
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html:formattingParagraph(message.remarks)}}></p>
                                        <p
                                          className="chat-time mb-0"
                                          data-for="main"
                                          data-tip={moment(
                                            new Date(`${message.createdAt}`)
                                          ).format("DD/MM/YYYY")}
                                        >
                                          <i className="bx bx-time-five align-middle me-1" />
                                          {moment(
                                            new Date(`${message.createdAt}`)
                                          ).format("HH:mm")}
                                        </p>
                                        <ReactTooltip
                                          id="main"
                                          place={"top"}
                                          type={"dark"}
                                          effect={"float"}
                                          multiline={true}
                                        />
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </div>
                        {props.currentChat &&
                        props.currentChat.sessionActive ? (
                          <div className="p-3 chat-input-section">
                            <Row>
                              <Col>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    value={curMessage}
                                    onKeyPress={onKeyPress}
                                    onChange={e =>
                                      setcurMessage(e.target.value)
                                    }
                                    className="form-control chat-input"
                                    placeholder="Enter Message..."
                                  />
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={() => addMessageToChat()}
                                  className="btn btn-primary btn-rounded chat-send w-md "
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    Send
                                  </span>{" "}
                                  <i className="mdi mdi-send" />
                                </Button>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={() => endChat()}
                                  className="btn btn-danger btn-rounded "
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    End
                                  </span>{" "}
                                  <i className="mdi mdi-close" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Chat.propTypes = {
  activeChats: PropTypes.array,
  inActiveChats: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onAddMessage: PropTypes.func,
  updateChat: PropTypes.func,
}

const mapStateToProps = ({ chat, Login, app }) => {
  return {
    activeChats: chat.activeChats,
    inActiveChats: chat.inActiveChats,
    messages: chat.messages,
    currentChat: chat.currentChat,
    filteredInactiveChats: chat.filteredInactiveChats,
    filteredActiveChats: chat.filteredActiveChats,
    profile: Login && Login.profile,
    availabilities: (app && app.availabilities) ?? [],
  }
}

const mapDispatchToProps = dispatch => ({
  updateChat: chat => dispatch(updateChat(chat)),
  getMessages: sessionId => dispatch(getMessages(sessionId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
