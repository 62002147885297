import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Container, Row } from "reactstrap"
import PrescriptModuleMaster from "./PrescriptModule"
import PrescriptTitleMaster from "./PrescriptTitle"
import PrescriptTextMaster from "./PrescriptText"

const index = () => {
  const [modules, setModules] = useState([])
  const [titles, setTitles] = useState([])
  const [forceFecthModule, setForceFecthModule] = useState(false)
  const [forceFecthTitles, setForceFecthTitles] = useState(false)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Prescript Data</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <PrescriptModuleMaster
              onFetch={modules => setModules(modules)}
              forceFetch={forceFecthModule}
            />
          </Row>
          <Row>
            <PrescriptTitleMaster
              onFetch={titles => {
                setTitles(titles)
                setForceFecthModule(true)
                setForceFecthModule(false)
              }}
              onDelete={() => {
                setForceFecthModule(true)
                setForceFecthModule(false)
              }}
              modules={modules}
              forceFetch={forceFecthTitles}
            />
          </Row>
          <Row>
            <PrescriptTextMaster
              onFetch={texts => {
                setForceFecthTitles(true)
                setForceFecthTitles(false)
              }}
              onDelete={() => {
                setForceFecthTitles(true)
                setForceFecthTitles(false)
              }}
              modules={modules}
              titles={titles}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
