export const SHOW_HIDE_LOADING = "SHOW_HIDE_LOADING"

export const SET_STATES = "SET_STATES"
export const SET_AVAILABILITIES = "SET_AVAILABILITIES"
export const GET_AVAILABILITIES = "GET_AVAILABILITIES"
export const SET_USER_TYPES = "SET_USER_TYPES"
export const SET_RATING_TYPES = "SET_RATING_TYPES"

export const SET_DEPARTMENTS = "SET_DEPARTMENTS"
export const SET_DEPARTMENT = "SET_DEPARTMENT"
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT"
export const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS"
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS"
export const REMOVE_DEPARTMENT_FROM_REDUX = "REMOVE_DEPARTMENT_FROM_REDUX"
export const SET_DEPARTMENT_SAVED = "SET_DEPARTMENT_SAVED"
export const SET_DEPARTMENT_UPDATED = "SET_DEPARTMENT_UPDATED"
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT"

export const GET_WELCOMES = "GET_WELCOMES"
export const GET_WELCOMES_SUCCESS = "GET_WELCOMES_SUCCESS"
export const UPDATE_WELCOME = "UPDATE_DEPARTMENT"
export const REMOVE_WELCOME = "REMOVE_WELCOME"

export const GET_FAQ = "GET_FAQ"
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS"
export const REMOVE_FAQ_FROM_REDUX = "REMOVE_FAQ_FROM_REDUX"
export const SET_FAQ = "SET_FAQ"
export const DELETE_FAQ = "DELETE_FAQ"
export const SAVE_FAQ = "SAVE_FAQ"
export const UPDATE_FAQ = "UPDATE_FAQ"
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS"
export const SET_FAQ_UPDATED = "SET_FAQ_UPDATED"

export const SET_REPORT_MODULES = "SET_REPORT_MODULES"
export const SET_REPORT_TRANSACTION_TYPES = "SET_REPORT_TRANSACTION_TYPES"
export const SET_ALL_USERS = "SET_ALL_USERS"
export const SET_ALL_AGENTS = "SET_ALL_AGENTS"

export const SET_AGENT_MODULES = "SET_AGENT_MODULES"
export const SET_AGENT_TRANSACTIONS = "SET_AGENT_TRANSACTIONS"
