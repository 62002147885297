import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import {
  GET_NOTIFICATIONS,
  MARK_ALL_AS_READ_NOTIFCATION,
  MARK_AS_READ_NOTIFCATION,
} from "./actionTypes"
import { getNotificationsSuccess } from "./actions"

import {
  getNotifications,
  patchMarkAllAsReadNotification,
  patchMarkAsReadNotification,
} from "../../helpers/backend_helper"

function* fetchNotifications() {
  try {
    const response = yield call(getNotifications)
    if (response.status === 200) {
      const readNotifications = response.data.data.filter(notification => !notification.hasRead);
      yield put(getNotificationsSuccess(readNotifications));
    }
  } catch (error) {
    // yield put(getChatsFail(error)) // TODO:
  }
}

function* markAsReadNotification({ id }) {
  try {
    const response = yield call(patchMarkAsReadNotification, id)
    if (response.status === 200) {
      const response = yield call(getNotifications)
      if (response.status === 200) {
        yield put(getNotificationsSuccess(response.data.data))
      }
    }
  } catch (error) {}
}

function* markAllAsReadNotification() {
  try {
    const response = yield call(patchMarkAllAsReadNotification)
    if (response.status === 200) {
      const response = yield call(getNotifications)
      if (response.status === 200) {
        yield put(getNotificationsSuccess(response.data.data))
      }
    }
  } catch (error) {}
}

function* notificaitonSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications)
  yield takeEvery(MARK_AS_READ_NOTIFCATION, markAsReadNotification)
  yield takeEvery(MARK_ALL_AS_READ_NOTIFCATION, markAllAsReadNotification)
}

export default notificaitonSaga
