import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  DELETE_FAQ,
  GET_FAQ,
  GET_WELCOMES,
  REMOVE_DEPARTMENT,
  REMOVE_WELCOME,
  SAVE_DEPARTMENT,
  SAVE_FAQ,
  UPDATE_DEPARTMENT,
  UPDATE_FAQ,
  UPDATE_WELCOME,
} from "./actionTypes"
import {
  getDepartmentsSuccess,
  getFAQSuccess,
  getWelcomesSuccess,
  saveDepartmentSuccess,
  updateDepartmentSuccess,
  updateFAQSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  saveDepartment,
  getDepartmentMaster,
  patchDepartment,
  deleteDepartment,
  getWelcomes,
  patchWelcome,
  deleteWelcome,
  getFAQs,
  deleteFQA,
  patchFAQ,
} from "../../helpers/backend_helper"

function* addDepartment({ payload }) {
  try {
    const response = yield call(saveDepartment, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(saveDepartmentSuccess())
      const response = yield call(getDepartmentMaster)
      if (200 === response.status || 304 === response.status) {
        yield put(getDepartmentsSuccess(response.data.data ?? []))
      }
    } else {
      yield put(saveDepartmentSuccess())
    }
  } catch (error) {
    yield put(saveDepartmentSuccess())
  }
}

function* updateDepartment({ payload }) {
  try {
    const response = yield call(patchDepartment, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(updateDepartmentSuccess())
      const response = yield call(getDepartmentMaster)
      if (200 === response.status || 304 === response.status) {
        yield put(getDepartmentsSuccess(response.data.data ?? []))
      }
    } else {
      yield put(updateDepartmentSuccess())
    }
  } catch (error) {
    yield put(updateDepartmentSuccess())
  }
}

function* removeDepartment({ payload }) {
  try {
    const response = yield call(deleteDepartment, payload)
    if (200 === response.status || 304 === response.status) {
      const response = yield call(getDepartmentMaster)
      if (200 === response.status || 304 === response.status) {
        yield put(getDepartmentsSuccess(response.data.data ?? []))
      }
    } else {
      // yield put(updateDepartmentSuccess())
    }
  } catch (error) {
    // yield put(updateDepartmentSuccess())
  }
}

function* fetchWelcomes() {
  try {
    const response = yield call(getWelcomes)
    if (200 === response.status || 304 === response.status) {
      yield put(getWelcomesSuccess(response.data.data ?? []))
    } else {
      yield put(getWelcomesSuccess())
    }
  } catch (error) {
    yield put(getWelcomesSuccess())
  }
}

function* updateWelcome({ payload }) {
  try {
    const response = yield call(patchWelcome, payload)
    if (200 === response.status || 304 === response.status) {
      const response = yield call(getWelcomes)
      if (200 === response.status || 304 === response.status) {
        yield put(getWelcomesSuccess(response.data.data ?? []))
      } else {
        yield put(getWelcomesSuccess())
      }
    } else {
    }
  } catch (error) {}
}

function* removeWelcome({ payload }) {
  try {
    const response = yield call(deleteWelcome, payload)
    if (200 === response.status || 304 === response.status) {
      const response = yield call(getWelcomes)
      if (200 === response.status || 304 === response.status) {
        yield put(getWelcomesSuccess(response.data.data ?? []))
      } else {
        yield put(getWelcomesSuccess())
      }
    } else {
    }
  } catch (error) {}
}

function* fetchFAQs() {
  try {
    const response = yield call(getFAQs)
    if (200 === response.status || 304 === response.status) {
      yield put(getFAQSuccess(response.data.data ?? []))
    } else {
      getFAQSuccess([])
    }
  } catch (error) {
    getFAQSuccess([])
  }
}

function* removeFAQ({ payload }) {
  try {
    const response = yield call(deleteFQA, payload)
    if (200 === response.status || 304 === response.status) {
      const response = yield call(getFAQs)
      if (200 === response.status || 304 === response.status) {
        yield put(getFAQSuccess(response.data.data ?? []))
      } else {
        getFAQSuccess([])
      }
    } else {
    }
  } catch (error) {}
}

function* updateFAQ({ payload }) {
  try {
    const response = yield call(patchFAQ, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(updateFAQSuccess())
      const response = yield call(getFAQs)
      if (200 === response.status || 304 === response.status) {
        yield put(getFAQSuccess(response.data.data ?? []))
      }
    } else {
      yield put(getFAQSuccess([]))
    }
  } catch (error) {}
}

function* appSaga() {
  yield takeEvery(SAVE_DEPARTMENT, addDepartment)
  yield takeEvery(UPDATE_DEPARTMENT, updateDepartment)
  yield takeEvery(REMOVE_DEPARTMENT, removeDepartment)

  yield takeEvery(GET_WELCOMES, fetchWelcomes)
  yield takeEvery(UPDATE_WELCOME, updateWelcome)
  yield takeEvery(REMOVE_WELCOME, removeWelcome)

  yield takeEvery(GET_FAQ, fetchFAQs)
  yield takeEvery(DELETE_FAQ, removeFAQ)
  yield takeEvery(UPDATE_FAQ, updateFAQ)
}

export default appSaga
