import "flatpickr/dist/themes/material_blue.css"
import { getRatingTypeMaster } from "helpers/backend_helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Flatpickr from "react-flatpickr"
import { MetaTags } from "react-meta-tags"
import RatingTooltip from "react-rating-tooltip"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  getRatings,
  getRatingsCount,
  setRatingTypesToRedux,
} from "../../../store/actions"
import features from "../../../assets/images/crypto/features-img/img-1.png"
import GridDownload from "components/GridDownLoad"

const Ratings = props => {
  const dispatch = useDispatch()
  const {
    ratings,
    ratingTypes,
    inquiriesCount,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const ratings = [...state.rating.ratings]
    const inquiriesCount = state.rating.ratingsCount
    const ratingTypes = [...state.app.ratingTypes]
    const loadingAvalability = state.rating.loadingAvalability
    const loading = state.rating.loading
    return { ratings, ratingTypes, inquiriesCount, loadingAvalability, loading }
  })
  const [ratingTypeDropDown, setRatingTypeDropDown] = useState(false)
  const [selectedRatingType, setSelectedRatingType] = useState({
    id: undefined,
    code: undefined,
    description: undefined,
  })
  const [selectedRating, setSelectedRating] = useState(1)
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "ratingValue",
      text: "Rating",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.name",
      text: "Customer Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "Mobile Number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "rating_type_master.description",
      text: "Rating Type",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "agent.name",
      text: "Agent Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: ratings.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    dispatch(
      getRatings(
        {
          type: 1,
          rating: 1,
        },
        {
          startDate: dateArray[0],
          endDate: dateArray[1],
        }
      )
    )
    dispatch(getRatingsCount())
  }, [])

  useEffect(() => {
    getRatingTypeMaster()
      .then(response => {
        if (response && response.status === 200) {
          dispatch(setRatingTypesToRedux(response.data.data))
          if (response.data.data && response.data.data.length > 0) {
            setSelectedRatingType(response.data.data[0])
          }
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [])

  const { SearchBar } = Search

  const onChangeRating = rate => {
    setSelectedRating(rate)
    dispatch(
      getRatings(
        {
          type: selectedRatingType.id,
          rating: rate,
        },
        {
          startDate: dateArray[0],
          endDate: dateArray[1],
        }
      )
    )
  }

  const onChangeType = type => {
    setSelectedRatingType(type)
    dispatch(
      getRatings(
        {
          type: type.id,
          rating: selectedRating,
        },
        {
          startDate: dateArray[0],
          endDate: dateArray[1],
        }
      )
    )
  }

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getRatings(
        {
          type: selectedRatingType.id,
          rating: selectedRating,
        },
        {
          startDate: selectedDates[0],
          endDate: selectedDates[1],
        }
      )
    )
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Ratings</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>Rating List</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <div>
                    <Row>
                      <Col lg="9" sm="8">
                        <Row className="mt-4">
                          <Col xl={12}>
                            <div className="text-center">
                              <h5 className="mb-4 text-primary">Summary</h5>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="text-lg-center mt-2">
                            <Row>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    Today
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.daily}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Week
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.weekly}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Month
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.monthly}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3" sm="4" className="align-self-center">
                        <div className="p-4">
                          <img
                            src={features}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={ratings}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={ratings}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="2">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="2">
                                  <Dropdown
                                    isOpen={ratingTypeDropDown}
                                    toggle={() =>
                                      setRatingTypeDropDown(!ratingTypeDropDown)
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedRatingType &&
                                        selectedRatingType.description}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {ratingTypes.map(type => {
                                        return (
                                          <DropdownItem
                                            key={type.id}
                                            onClick={() => {
                                              onChangeType(type)
                                            }}
                                          >
                                            {type.description}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </Col>
                                <Col md="2">
                                  <RatingTooltip
                                    defaultRating={1}
                                    max={5}
                                    onChange={rate => onChangeRating(rate)}
                                    ActiveComponent={
                                      <i
                                        key={"active_1"}
                                        className="mdi mdi-star text-primary"
                                        style={{}}
                                      />
                                    }
                                    InActiveComponent={
                                      <i
                                        key={"active_01"}
                                        className="mdi mdi-star-outline text-muted"
                                        style={{}}
                                      />
                                    }
                                  />
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col md="3">
                                  <div className="me-4 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <h5>
                                        Search Returned {ratings.length}{" "}
                                        Record(s)
                                      </h5>
                                    </div>
                                  </div>
                                  <GridDownload
                                    title="Rating List"
                                    dateArray={dateArray}
                                    columns={columns}
                                    data={ratings}
                                  />
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Ratings available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Ratings)
