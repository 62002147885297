import React, { useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { getLocalStorage } from "helpers/common_functions"
import { useDispatch, useSelector } from "react-redux"
import { clearChatState, clearNotificationState } from "../../../store/actions"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  // const { notifications } = useSelector(state => {
  //   return { ...state.notifications }
  // })
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const authUser = getLocalStorage("authUser")
    if (authUser) {
      setusername(authUser.name)
    }
  }, [username])

  const logout = e => {
    e.preventDefault()
    dispatch(clearChatState())
    dispatch(clearNotificationState())
    history.push("/logout")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span> */}
          <span className="d-xl-inline-block me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <Link to="/logout" onClick={e => logout(e)} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(connect()(withTranslation()(ProfileMenu)))
