import "flatpickr/dist/themes/material_blue.css"
import {
  getModuleMaster,
  getTransactionTypeMaster,
} from "helpers/backend_helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Flatpickr from "react-flatpickr"
import "react-image-lightbox/style.css"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import {
  getAuditUserSessions,
  SetReportModulesToRedux,
  setReportTransactionTypesToRedux,
  getChatbotSessionCount,
} from "../../../store/actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { CSVLink } from "react-csv"
import features from "../../../assets/images/crypto/features-img/img-1.png"

const ChatbotSessionReport = props => {
  const dispatch = useDispatch()
  const {
    auditUserSessions,
    inquiriesCount,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const auditUserSessions = [...state.audit.auditUserSessions]
    const inquiriesCount = state.audit.chatbotSessionCount
    const loadingAvalability = state.audit.auditUserSessionsLoading
    const loading = state.audit.loading
    return { auditUserSessions, inquiriesCount, loadingAvalability, loading }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])
  const [selectedModule, setSelectedModule] = useState({
    id: undefined,
    type_code: undefined,
    description: undefined,
  })
  const [selectedTransactionType, setSelectedTransactionType] = useState({
    id: undefined,
    type_code: undefined,
    description: undefined,
  })

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "user.id",
      text: "User ID",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.name",
      text: "User Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "Phone Number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "report_transaction_master.description",
      text: "Transaction Type",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "report_module_master.description",
      text: "Module",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "Chat",
    //   formatter: (rowContent, row) => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={() => {
    //         setSelectedChat(row)
    //         toggleChatModal()
    //       }}
    //     >
    //       View Chat
    //     </Button>
    //   ),
    // },
  ]

  const headers = [columns.map(item => item.text)]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: auditUserSessions.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    dispatch(
      getAuditUserSessions({
        startDate: dateArray[0],
        endDate: dateArray[1],
        moduleId: [10],
        transactionType: [35],
      })
    )
    dispatch(getChatbotSessionCount())
  }, [])

  useEffect(() => {
    Promise.all([getModuleMaster(), getTransactionTypeMaster()])
      .then(values => {
        let moduleResponse = values[0]
        let transactionTypeResponse = values[1]
        // moduel master
        if (moduleResponse && moduleResponse.status === 200) {
          dispatch(SetReportModulesToRedux(moduleResponse.data.data))
          if (moduleResponse.data.data && moduleResponse.data.data.length > 0) {
            setSelectedModule(moduleResponse.data.data[8])
          }
        }

        // Transaction type master
        if (transactionTypeResponse && transactionTypeResponse.status === 200) {
          dispatch(
            setReportTransactionTypesToRedux(transactionTypeResponse.data.data)
          )
          if (
            transactionTypeResponse.data.data &&
            transactionTypeResponse.data.data.length > 0
          ) {
            setSelectedTransactionType(transactionTypeResponse.data.data[32])
          }
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getAuditUserSessions({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
        moduleId: [selectedModule.id],
        transactionType: [selectedTransactionType.id],
      })
    )
  }

  const exportPDF = download => {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = "Chatbot Session Report"
    const formatters = columns.map(item => item.formatter)
    const data = auditUserSessions.map(session => [
      formatters[0](session["createdAt"], session),
      formatters[1](session["user"]["id"], session),
      formatters[1](session["user"]["name"], session),
      formatters[2](session["user"]["phoneNumber"], session),
      formatters[3](session["status"], session),
      formatters[4](session["ticketId"], session),
      formatters[5](session["sessionId"], session),
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        fontSize: 7,
      },
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(`Chatbot Session Report (${dateArray[0]}-${dateArray[1]}).pdf`)
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const formatters = columns.map(item => item.formatter)
  const detailsArrayOfArrays = auditUserSessions.map(session => {
    return [
      formatters[0](session["createdAt"], session),
      formatters[1](session["user"]["id"], session),
      formatters[1](session["user"]["name"], session),
      formatters[2](session["user"]["phoneNumber"], session),
      formatters[3](session["status"], session),
      formatters[4](session["ticketId"], session),
      formatters[5](session["sessionId"], session),
    ]
  })
  const cvsData = headers.concat(detailsArrayOfArrays)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Chatbot Session Report</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 className="mb-3">Chatbot Session Report</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <div>
                    <Row>
                      <Col lg="9" sm="8">
                        <Row className="mt-4">
                          <Col xl={12}>
                            <div className="text-center">
                              <h5 className="mb-4 text-primary">Summary</h5>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="text-lg-center mt-2">
                            <Row>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    Today
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.daily}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Week
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.weekly}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Month
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.monthly}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3" sm="4" className="align-self-center">
                        <div className="p-4">
                          <img
                            src={features}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={auditUserSessions}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={auditUserSessions}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col sm="5">
                                  <div className="text-sm-end">
                                    <div className="me-3 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <h5>
                                          Search Returned{" "}
                                          {auditUserSessions.length} Record(s)
                                        </h5>
                                      </div>
                                    </div>
                                    <CSVLink
                                      data={cvsData}
                                      filename={`Complaint Inquiries(${dateArray[0]}-${dateArray[1]}).csv`}
                                      className="btn btn-primary me-2"
                                      target="_blank"
                                    >
                                      CSV
                                    </CSVLink>
                                    <Button
                                      className="me-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(true)
                                      }}
                                    >
                                      <i className="mdi mdi-download" />
                                    </Button>
                                    <Button
                                      className="me-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(false)
                                      }}
                                    >
                                      <i className="mdi mdi-printer" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Audits available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ChatbotSessionReport)
