import {
  CLEAR_NOTIFICATION_STATE,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_ALL_AS_READ_NOTIFCATION,
  MARK_AS_READ_NOTIFCATION,
  PUSH_NOTIFICATION,
} from "./actionTypes"

export const clearNotificationState = () => ({
  type: CLEAR_NOTIFICATION_STATE,
})

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
})

export const pushNotifications = notification => ({
  type: PUSH_NOTIFICATION,
  payload: notification,
})

export const getNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const markAsReadNotification = id => ({
  type: MARK_AS_READ_NOTIFCATION,
  id,
})

export const markAllAsReadNotification = () => ({
  type: MARK_ALL_AS_READ_NOTIFCATION,
})
