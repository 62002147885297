import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ARCHIVES } from "./actionTypes"
import { getArchivesSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getArchives } from "../../helpers/backend_helper"

function* fetchArchives({ payload }) {
  try {
    const response = yield call(getArchives, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getArchivesSuccess(response.data.data))
    } else {
      yield put(getArchivesSuccess([]))
    }
  } catch (error) {
    yield put(getArchivesSuccess([]))
  }
}

function* archivesSaga() {
  yield takeEvery(GET_ARCHIVES, fetchArchives)
}

export default archivesSaga
