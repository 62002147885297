/* USERS */
export const SET_ERROR = "SET_ERROR"
export const GET_USERS = "GET_USERS"
export const GET_CC_AGENTS = "GET_CC_AGENTS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"
export const SET_USER = "SET_USER"
export const REMOVE_USER_FROM_REDUX = "REMOVE_USER_FROM_REDUX"
export const SAVE_USER = "SAVE_USER"
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS"
export const SAVE_USER_FAIL = "SAVE_USER_FAIL"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const SET_USER_SAVED = "SET_USER_SAVED"
export const SET_USER_UPDATED = "SET_USER_UPDATED"
