import React, { createContext } from "react"
import io from "socket.io-client"

export const socket = io(
  process.env.REACT_APP_IS_LOCAL === "true"
    ? `${process.env.REACT_APP_LOCAL_HOST}/operator`
    : `${process.env.REACT_APP_REMOTE_HOST}/operator`
)
const SocketContext = createContext(socket)

export default SocketContext
