import {
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  UPDATE_CHAT,
  SET_CURRENT_CHAT,
  CLEAR_CHAT_STATE,
  SET_MESSAGES,
  GET_ACTIVE_CHATS_SUCCESS,
  GET_ACTIVE_CHATS_FAIL,
  GET_INACTIVE_CHATS_SUCCESS,
  GET_INACTIVE_CHATS_FAIL,
  FILTER_ACTIVE_CHATS,
  FILTER_INACTIVE_CHATS,
  REMOVE_CHAT,
  SESSION_STATUS
} from "./actionTypes"

const INIT_STATE = {
  activeChats: [],
  inactiveChats: [],
  filteredActiveChats: [],
  filteredInactiveChats: [],
  currentChat: null,
  messages: [],
  error: {},
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_CHAT_STATE:
      return {
        ...state,
        ...INIT_STATE,
      }
    case GET_ACTIVE_CHATS_SUCCESS:
      return {
        ...state,
        activeChats: action.payload,
        filteredActiveChats: action.payload,
        currentChat: action.payload.length == 0 ? null : state.currentChat,
        messages: action.payload.length == 0 ? [] : state.messages,
      }
    case GET_ACTIVE_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_INACTIVE_CHATS_SUCCESS:
      return {
        ...state,
        inactiveChats: action.payload,
        filteredInactiveChats: action.payload,
      }
    case GET_INACTIVE_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CHAT:
      let chats = state.activeChats
      const index = chats.findIndex(item => item.id === action.payload.id)
      chats[index] = action.payload
      return {
        ...state,
        activeChats: chats,
        filteredActiveChats: chats,
      }
    case SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.payload,
      }
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      }
    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      }
    case FILTER_ACTIVE_CHATS:
      return {
        ...state,
        filteredActiveChats: state.activeChats.filter(val => {
          return val.user.name
            .toString()
            .toLowerCase()
            .includes(action.payload.toString().toLowerCase())
        }),
      }
    case FILTER_INACTIVE_CHATS:
      return {
        ...state,
        filteredInactiveChats: state.inactiveChats.filter(val => {
          return val.user.name
            .toString()
            .toLowerCase()
            .includes(action.payload.toString().toLowerCase())
        }),
      }
    case REMOVE_CHAT:
      let activeChats = state.activeChats
      activeChats = activeChats.filter(item => item.id !== action.payload.id)
      return {
        ...state,
        activeChats: activeChats,
        filteredActiveChats: activeChats,
      }
    case SESSION_STATUS:
      let currentChats = state.activeChats
      currentChats = currentChats.map(item => {
        if(parseInt(item.id) === action.payload) {
          item.sessionActive = false;
        }
        return item;
      })
      const currentChat = state.currentChat;
      if(state.currentChat?.id === action.payload) {
        currentChat.sessionActive = false;
      }

      return {
        ...state,
        currentChat,
        activeChats: currentChats,
        filteredActiveChats: currentChats,
      }
    default:
      return state
  }
}

export default Chat
