import React, { useState } from "react"
import { withRouter } from "react-router"
import { nameOf } from "helpers/common_functions"
import { Button, Col, FormGroup, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  addPrescriptModule,
  updatePrescriptModule,
} from "helpers/backend_helper"

class PrescriptModule {
  constructor(obj) {
    if (obj != null) {
      Object.assign(this, obj)
    } else {
      this.id = 0
      this.typeCode = ""
      this.description = ""
      this.language = "BM"
    }
  }
}

const PrescriptModuleCrupdate = props => {
  const [module, setModule] = useState(props.module ?? new PrescriptModule())
  const [loading, setLoading] = useState(false)

  const valueOnChange = event => {
    setModule({
      ...module,
      [event.target.name]: event.target.value,
    })
  }

  const saveModule = () => {
    setLoading(true)
    addPrescriptModule(module)
      .then(res => {
        setLoading(false)
        if (res.status === 200) {
          toastr.success("Successfuly saved.")
          props.hideModal()
        } else {
          toastr.error("Save error.")
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  const updateModule = () => {
    setLoading(true)
    updatePrescriptModule(module)
      .then(res => {
        setLoading(false)
        if (res.status === 200) {
          toastr.success("Successfuly updated.")
        } else {
          toastr.error("Update error.")
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  const handleValidSubmit = (event, values) => {
    if (module.id === 0) {
      saveModule()
    } else {
      updateModule()
    }
  }


  return (
    <React.Fragment>
      <AvForm
        model={new PrescriptModule()}
        onValidSubmit={handleValidSubmit}
        className="needs-validation"
      // onInvalidSubmit={handleInvalidSubmit}
      >
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-firstname-Input"
            className="col-sm-3 col-form-label"
          >
            Code
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-firstname-Input"
                name={nameOf(module).typeCode}
                value={module.typeCode}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Code" },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Name
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-ic-Input"
                name={nameOf(module).description}
                value={module.description}
                onChange={valueOnChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Enter Description (max 300)",
                  },
                }}
                maxLength={300}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Language
          </Label>
          <Col sm={9}>
            <select
              className="form-control"
              name={nameOf(module).language}
              value={module.language}
              onChange={valueOnChange}
            >
              <option key={"BM"} value={"BM"}>
                Bahasa Malaysia
              </option>
              <option key={"EN"} value={"EN"}>
                English
              </option>
            </select>
          </Col>
        </div>
        <div className="row justify-content-end">
          <Col sm={9}>
            <div className="text-sm-end">
              <Button
                type="submit"
                // onClick={submit}
                color="primary"
                className="w-md"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : null}
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default withRouter(PrescriptModuleCrupdate)
