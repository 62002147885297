import React, { Component } from "react"
import { Col, Card, CardBody, Button } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import moment from "moment"
import { getDashboardList } from "../../../../helpers/backend_helper"

class ComplaintTicketsCountChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [
        {
          name: "CTC",
          data: [],
        },
      ],
      options: {
        chart: { toolbar: "false" },
        dataLabels: { enabled: !1 },
        stroke: { curve: "smooth", width: 2 },
        markers: { size: 0, style: "hollow" },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: { x: { format: "dd MMM yyyy" } },
        colors: ["#f1b44c"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.05,
            stops: [42, 100, 100, 100],
          },
        },
      },
      activeM: true,
      active6M: false,
    }
    this.updateChartMonthly.bind(this)
    this.updateChartSixMonth.bind(this)
  }

  componentDidMount() {
    this.updateChartMonthly()
  }

  updateChartMonthly() {
    const start = moment().subtract(1, "months").format()
    const end = moment().format()
    getDashboardList({
      typeCode: "CTC",
      startDate: start,
      endDate: end,
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const array = response.data.data.map(item => {
            return [
              parseInt(moment(item.createdAt).format("x")),
              parseInt(item.count),
            ]
          })
          this.setState({
            series: [
              {
                name: "CTC",
                data: array,
              },
            ],
          })
          this.setState({
            activeM: true,
            active6M: false,
          })
        }
      })
      .catch(e => {})
  }

  updateChartSixMonth() {
    const start = moment().subtract(6, "months").format()
    const end = moment().format()
    getDashboardList({
      typeCode: "CTC",
      startDate: start,
      endDate: end,
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const array = response.data.data.map(item => {
            return [
              parseInt(moment(item.createdAt).format("x")),
              parseInt(item.count),
            ]
          })
          this.setState({
            series: [
              {
                name: "CTC",
                data: array,
              },
            ],
          })
        }
      })
      .catch(e => {})
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-3">Complaint Tickets</h4>
              <div>
                <div id="overview-chart" className="apex-charts" dir="ltr">
                  <div className="toolbar button-items text-center">
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={this.state.activeM ? "active" : ""}
                      onClick={() => this.updateChartMonthly()}
                      id="one_month"
                    >
                      1M
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={this.state.active6M ? "active" : ""}
                      onClick={() => this.updateChartSixMonth()}
                      id="six_months"
                    >
                      6M
                    </Button>
                    {/* <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={this.state.activeY ? "active" : ""}
                      onClick={() => this.updateChartYearly()}
                      id="one_year"
                    >
                      1Y
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={this.state.activeA ? "active" : ""}
                      onClick={() => this.updateChartAll()}
                      id="all"
                    >
                      ALL
                    </Button> */}
                  </div>
                  <div id="overview-chart-timeline">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="area"
                      height={240}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

export default ComplaintTicketsCountChart
