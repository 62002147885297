/* CHATS */
export const CLEAR_CHAT_STATE = "CLEAR_CHAT_STATE"
export const GET_ACTIVE_CHATS = "GET_ACTIVE_CHATS"
export const GET_ACTIVE_CHATS_SUCCESS = "GET_ACTIVE_CHATS_SUCCESS"
export const GET_ACTIVE_CHATS_FAIL = "GET_ACTIVE_CHATS_FAIL"
export const GET_INACTIVE_CHATS = "GET_INACTIVE_CHATS"
export const GET_INACTIVE_CHATS_SUCCESS = "GET_INACTIVE_CHATS_SUCCESS"
export const GET_INACTIVE_CHATS_FAIL = "GET_INACTIVE_CHATS_FAIL"
export const UPDATE_CHAT = "UPDATE_CHAT"
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT"
export const FILTER_ACTIVE_CHATS = "FILTER_ACTIVE_CHATS"
export const FILTER_INACTIVE_CHATS = "FILTER_INACTIVE_CHATS"
export const REMOVE_CHAT = "REMOVE_CHAT"
export const SESSION_STATUS = "SESSION_STATUS"
/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"
export const SET_MESSAGES = "SET_MESSAGES"

export const POST_ADD_MESSAGE = "POST_ADD_MESSAGE"
export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS"
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL"
