import {
  SET_AVAILABILITIES,
  SET_DEPARTMENTS,
  SET_RATING_TYPES,
  SET_STATES,
  SET_USER_TYPES,
  SET_REPORT_MODULES,
  SET_REPORT_TRANSACTION_TYPES,
  SET_ALL_USERS,
  SET_ALL_AGENTS,
  SET_DEPARTMENT,
  SAVE_DEPARTMENT,
  SAVE_DEPARTMENT_SUCCESS,
  REMOVE_DEPARTMENT_FROM_REDUX,
  SET_DEPARTMENT_SAVED,
  SET_DEPARTMENT_UPDATED,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  GET_WELCOMES_SUCCESS,
  GET_FAQ_SUCCESS,
  REMOVE_FAQ_FROM_REDUX,
  SET_FAQ,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  SET_FAQ_UPDATED,
  SHOW_HIDE_LOADING,
  SET_AGENT_MODULES,
  SET_AGENT_TRANSACTIONS,
} from "./actionTypes"

const INIT_STATE = {
  states: [],
  availabilities: [],
  userTypes: [],
  ratingTypes: [],
  departments: [],
  reportModules: [],
  reportTransactionTypes: [],
  allUsers: [],
  allAgents: [],
  department: {},
  departmentSaved: false,
  departmentUpdated: false,
  loading: false,
  welcomes: [],
  faqs: [],
  faq: {},
  faqUpdated: false,
  agentModules: [{ id: "ALL", name: "ALL" }],
  agentTransactions: [{ id: "ALL", name: "ALL" }],
}

const app = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_HIDE_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_STATES:
      return {
        ...state,
        states: action.payload ?? [],
      }
    case SET_AVAILABILITIES:
      return {
        ...state,
        availabilities: action.payload ?? [],
      }
    case SET_USER_TYPES:
      return {
        ...state,
        userTypes: action.payload ?? [],
      }
    case SET_RATING_TYPES:
      return {
        ...state,
        ratingTypes: action.payload ?? [],
      }
    case SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload ?? [],
      }
    case SET_DEPARTMENT:
      return {
        ...state,
        department: action.payload,
      }
    case SAVE_DEPARTMENT:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_DEPARTMENT:
      return {
        ...state,
        loading: true,
      }
    case SAVE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentSaved: true,
      }
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentUpdated: true,
      }
    case REMOVE_DEPARTMENT_FROM_REDUX:
      return {
        ...state,
        department: {},
      }
    case SET_DEPARTMENT_SAVED:
      return {
        ...state,
        departmentSaved: action.payload,
      }
    case SET_DEPARTMENT_UPDATED:
      return {
        ...state,
        departmentUpdated: action.payload,
      }
    case SET_REPORT_MODULES:
      return {
        ...state,
        reportModules: action.payload ?? [],
      }
    case SET_REPORT_TRANSACTION_TYPES:
      return {
        ...state,
        reportTransactionTypes: action.payload ?? [],
      }
    case SET_ALL_USERS:
      const users =
        action.payload && action.payload.length > 0
          ? [
              { id: "ALL", name: "ALL" },
              ...action.payload.map(item => ({
                id: item.id + "",
                name: item.name,
              })),
            ]
          : [{ id: "ALL", name: "ALL" }]
      return {
        ...state,
        allUsers: users,
      }
    case SET_ALL_AGENTS:
      const agents =
        action.payload && action.payload.length > 0
          ? [
              { id: "ALL", name: "ALL" },
              ...action.payload.map(item => ({
                id: item.id + "",
                name: item.name,
              })),
            ]
          : [{ id: "ALL", name: "ALL" }]

      return {
        ...state,
        allAgents: agents,
      }
    case GET_WELCOMES_SUCCESS:
      return {
        ...state,
        welcomes: action.payload,
      }
    case GET_FAQ_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
      }
    case REMOVE_FAQ_FROM_REDUX:
      return {
        ...state,
        faq: {},
      }
    case SET_FAQ:
      return {
        ...state,
        faq: action.payload,
      }
    case UPDATE_FAQ:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        faqUpdated: true,
      }
    case SET_FAQ_UPDATED:
      return {
        ...state,
        faqUpdated: false,
      }
    case SET_AGENT_MODULES:
      const agentModules =
        action.payload && action.payload.length > 0
          ? [
              { id: "ALL", name: "ALL" },
              ...action.payload.map(item => ({
                id: item.id + "",
                name: item.type_code,
              })),
            ]
          : [{ id: "ALL", name: "ALL" }]
      return {
        ...state,
        agentModules: agentModules,
      }
    case SET_AGENT_TRANSACTIONS:
      const agentTransactions =
        action.payload && action.payload.length > 0
          ? [
              { id: "ALL", name: "ALL" },
              ...action.payload.map(item => ({
                id: item.id + "",
                name: item.type_code,
              })),
            ]
          : [{ id: "ALL", name: "ALL" }]
      return {
        ...state,
        agentTransactions: agentTransactions,
      }
    default:
      return {
        ...state,
      }
  }
}

export default app
