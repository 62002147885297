import {
  CLEAR_NOTIFICATION_STATE,
  GET_NOTIFICATIONS_SUCCESS,
  PUSH_NOTIFICATION,
} from "./actionTypes"

const INIT_STATE = {
  notifications: [],
}

const notificaitons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_NOTIFICATION_STATE:
      return {
        ...state,
        ...INIT_STATE,
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload ?? [],
      }
    case PUSH_NOTIFICATION:
      let notis = state.notifications
      notis.unshift(action.payload)
      return {
        ...state,
        notificaitons: notis,
      }
    default:
      return state
  }
}

export default notificaitons
