export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"
export const UPDATE_CURRENT_USER_SUCCESS = "UPDATE_CURRENT_USER_SUCCESS"
export const UPDATE_CURRENT_USER_STATE = "UPDATE_CURRENT_USER_STATE"
export const UPDATE_CURRENT_USER_STATE_SUCCESS =
  "UPDATE_CURRENT_USER_STATE_SUCCESS"
export const UPDATE_CURRENT_USER_AVAILABILITY =
  "UPDATE_CURRENT_USER_AVAILABILITY"
export const UPDATE_CURRENT_USER_AVAILABILITY_SUCCESS =
  "UPDATE_CURRENT_USER_AVAILABILITY_SUCCESS"
export const SET_CURRENT_USER_STATE_SUCCESS = "SET_CURRENT_USER_STATE_SUCCESS"
export const SET_CURRENT_USER_AVAILABILITY_SUCCESS =
  "SET_CURRENT_USER_AVAILABILITY_SUCCESS"
export const REMOVE_API_ERROR = "REMOVE_API_ERROR"
