import React, { useEffect } from "react"
import { withRouter } from "react-router"
import { nameOf } from "helpers/common_functions"
import { Button, Col, FormGroup, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  setDeparment,
  saveDepartment,
  updateDepartment,
  setDepartmentSaved,
  setDepartmentUpdated,
} from "store/actions"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

class DepartmentModel {
  constructor(obj) {
    if (obj != null) {
      Object.assign(this, obj)
    } else {
      this.id = 0
      this.code = ""
      this.title = ""
      this.description = ""
    }
  }
}

const DepartmentCrupdate = props => {
  const {
    loading,
    department,
    departmentUpdated,
    departmentSaved,
  } = useSelector(state => {
    let { department, departmentUpdated, departmentSaved, loading } = state.app
    const hasKeys = !!Object.keys(department).length
    department = hasKeys
      ? new DepartmentModel(department)
      : new DepartmentModel()
    return {
      loading,
      department,
      departmentUpdated,
      departmentSaved,
    }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (departmentUpdated) {
      dispatch(setDepartmentUpdated(false))
      toastr.success("", "Successfuly updated.")
    }
  }, [departmentUpdated])

  useEffect(() => {
    if (departmentSaved) {
      props.onSaveSuccess()
    }
    return () => {
      if (departmentSaved) {
        dispatch(setDepartmentSaved(false))
      }
    }
  }, [departmentSaved])

  const valueOnChange = event => {
    dispatch(
      setDeparment({ ...department, [event.target.name]: event.target.value })
    )
  }

  const handleValidSubmit = (event, values) => {
    if (department.id === 0) {
      dispatch(saveDepartment(department))
    } else {
      dispatch(updateDepartment(department))
    }
  }

  return (
    <React.Fragment>
      <AvForm
        model={new DepartmentModel()}
        onValidSubmit={handleValidSubmit}
        className="needs-validation"
      >
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-firstname-Input"
            className="col-sm-3 col-form-label"
          >
            Code
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-firstname-Input"
                name={nameOf(department).code}
                value={department.code}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Code" },
                }}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-email-Input"
            className="col-sm-3 col-form-label"
          >
            Title
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-email-Input"
                name={nameOf(department).title}
                value={department.title}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Title" },
                }}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Description
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-ic-Input"
                name={nameOf(department).description}
                value={department.description}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Description" },
                }}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row justify-content-end">
          <Col sm={9}>
            <div className="text-sm-end">
              <Button
                type="submit"
                // onClick={submit}
                color="primary"
                className="w-md"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : null}
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default withRouter(DepartmentCrupdate)
