import axios from "axios"

export const API_URL =
  process.env.REACT_APP_IS_LOCAL === "true"
    ? process.env.REACT_APP_LOCAL_HOST
    : process.env.REACT_APP_REMOTE_HOST
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)
axiosApi.defaults.headers.common["Accept"] = "application/json;"
axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axiosApi.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET,PUT,POST,DELETE,PATCH,OPTIONS"
axiosApi.defaults.headers.common["Access-Control-Allow-Headers"] = "*"
axiosApi.defaults.headers.common["Access-Control-Allow-Headers"] =
  "x-requested-with"

function authToken() {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  if (obj && obj.accessToken) {
    return "Bearer " + obj.accessToken
  } else {
    return null
  }
}

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = authToken()
  console.log(" url ", url)
  return axiosApi
    .get(url, { ...config })
    .then(response => {
      if (response.status === 204 && response.hasOwnProperty("data")) {
        response.data = { data: [] }
      }
      return response
    })
    .catch(e => {
      return e.response
    })
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = authToken()
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      if (response.status === 204 && response.hasOwnProperty("data")) {
        response.data = { data: [] }
      }
      return response
    })
    .catch(e => e.response)
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = authToken()
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      if (response.status === 204 && response.hasOwnProperty("data")) {
        response.data = { data: [] }
      }
      return response
    })
    .catch(e => e.response)
}

export async function patch(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = authToken()
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => {
      if (response.status === 204 && response.hasOwnProperty("data")) {
        response.data = { data: [] }
      }
      return response
    })
    .catch(e => e.response)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = authToken()
  return await axiosApi.delete(url, { ...config }).then(response => response)
}

export async function upload(url, data) {
  axiosApi.defaults.headers.common["Authorization"] = authToken()
  return axiosApi
    .post(`${API_URL}${url}`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then(response => response)
    .catch(e => e.response)
}

export function getFileURL(url) {
  return API_URL + url
}
