import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import DepartmentCrupdate from "./DepartmentCupdate"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { getDepartmentMaster } from "helpers/backend_helper"
import {
  setDepartmentsToRedux,
  removeDepartmentFromRedux,
  setDeparment,
  removeDepartment,
} from "store/actions"
import GridDownload from 'components/GridDownLoad';
import moment from "moment"

const DepartmentMaster = props => {
  const dispatch = useDispatch()
  const departments = useSelector(state => {
    return [...state.app.departments]
  })
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false)
  const [deleteDepartmentId, setDeleteDepartmentId] = useState(0)

  useEffect(() => {
    getDepartmentMaster().then(response => {
      if (response && response.status === 200) {
        dispatch(setDepartmentsToRedux(response.data.data))
      }
    })
  }, [])

  const [dateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "button",
      text: "Actions",
      formatter: (rowContent, row) => {
        return (
          <div className="d-flex gap-3">
            <Link
              className="text-success"
              to="#"
              onClick={() => {
                dispatch(removeDepartmentFromRedux())
                dispatch(setDeparment(row))
                setIsEdit(true)
                setModal(true)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
            </Link>
            <Link
              className="text-danger"
              to="#"
              onClick={() => {
                onDeleteDepartment(row.id)
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
            </Link>
          </div>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: departments.length,
    custom: true,
  }

  const onDeleteDepartment = id => {
    setDeleteDepartmentId(id)
    setDeleteConfirmAlert(true)
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <h3>List Department</h3>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={departments}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={departments}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="6">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => {
                                  dispatch(removeDepartmentFromRedux())
                                  setIsEdit(false)
                                  setModal(true)
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New Department
                              </Button>
                            </div>
                          </Col>
                          <Col md="2">
                            <GridDownload title="List Department" dateArray={dateArray} columns={columns} data={departments} />

                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={() => {
                                  setModal(!modal)
                                }}
                              >
                                <ModalHeader
                                  toggle={() => {
                                    setModal(!modal)
                                  }}
                                  tag="h4"
                                >
                                  {!!isEdit
                                    ? "Edit Department"
                                    : "Add Department"}
                                </ModalHeader>
                                <ModalBody>
                                  <DepartmentCrupdate
                                    onSaveSuccess={() => {
                                      setModal(false)
                                      toastr.success("", "Successfuly saved.")
                                    }}
                                  />
                                </ModalBody>
                              </Modal>
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {deleteConfirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            if (deleteDepartmentId && deleteDepartmentId > 0) {
              setDeleteConfirmAlert(false)
              dispatch(removeDepartment(deleteDepartmentId))
            }
          }}
          onCancel={() => setDeleteConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default withRouter(DepartmentMaster)
