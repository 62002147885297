import { GET_RATINGS, GET_RATINGS_COUNT, GET_RATINGS_COUNT_SUCCESS, GET_RATINGS_SUCCESS } from "./actionTypes"

export const getRatings = (pathParams, bodyParams) => {
  return {
    type: GET_RATINGS,
    payload: { pathParams: pathParams, bodyParams: bodyParams },
  }
}

export const getRatingsSuccess = ratings => ({
  type: GET_RATINGS_SUCCESS,
  payload: ratings,
})

export const getRatingsCount = ratings => {
  return {
    type: GET_RATINGS_COUNT,
    payload: ratings
  }
}

export const getRatingsCountSuccess = ratings => {
  return {
    type: GET_RATINGS_COUNT_SUCCESS,
    payload: ratings
  }
}