import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
import { useModal, ModalProvider } from "use-modal-hook"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { withRouter } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import moment from "moment"
import "flatpickr/dist/themes/material_blue.css"
import "react-image-lightbox/style.css"
import Flatpickr from "react-flatpickr"
import useTables from "../../../hooks/useTables"
import ConfirmModal from "../../../components/ConfirmModal"
import useArchives from "hooks/useArchive"
import { archiveChats, archiveSessions } from "../../../helpers/backend_helper"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import GridDownload from "components/GridDownLoad"

function textFormatter(rowContent, row) {
  if (!rowContent) {
    return "-"
  }
  return rowContent
}

function dateFormatter(rowContent, row) {
  if (!rowContent) {
    return "-"
  }
  return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY")
}

function timeFormatter(rowContent, row) {
  if (!rowContent) {
    return "-"
  }
  return moment(new Date(`${rowContent}`)).format("HH:mm:ss")
}
function dropTime(date) {
  const time = moment(date).hours(0).minutes(0).seconds(0).milliseconds(0)
  return time.utcOffset(0, true).format()
}
const Achive = props => {
  const [dateArray, setDateArray] = useState([
    dropTime(moment().subtract(1, "months")),
    dropTime(moment()),
  ])

  const [tableNameDropDown, setTableNameDropDown] = useState(false)
  const [selectedTable, setSelectedTable] = useState("User Session")

  const { data: archives = [], revalidate, loading } = useArchives(
    selectedTable,
    dateArray[0],
    dateArray[1]
  )

  const { tables = [] } = useTables()

  const onChangeTable = table => {
    setSelectedTable(table.code)
  }

  useEffect(() => {
    revalidate()
  }, [selectedTable, dateArray])

  const [showModal, hideModal] = useModal(ConfirmModal, {
    title: "Confirm Archive!",
    description:
      "Are you sure want to archive these data?, this change cannot be undone. Dependent tables will also be affected.",
    closeBtnLabel: "No",
    confirmBtnLabel: "Yes",
    onConfirm: () => {
      if (selectedTable === "User Session") {
        archiveSessions({
          startDate: dateArray[0],
          endDate: dateArray[1],
        })
          .then(() => {
            revalidate()
            toastr.success("", "Successfuly archived.")
          })
          .catch(error => {
            toastr.error("", "Something went wrong.")
          })
      } else if (selectedTable === "User Chat History") {
        archiveChats({
          startDate: dateArray[0],
          endDate: dateArray[1],
        })
          .then(() => {
            revalidate()
            toastr.success("", "Successfuly archived.")
          })
          .catch(error => {
            toastr.error("", "Something went wrong.")
          })
      }
      hideModal()
    },
  })

  let columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "user.name",
      text: "User Name",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "language",
      text: "Language",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "socketId",
      text: "Socket Id",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "agentRequested",
      text: "Agent Requested",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "sessionActive",
      text: "Session Active",
      sort: true,
      formatter: (rowContent, row) => { if (!rowContent) {
        return "-"
      }
       return "Yes"
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "agentSessioneStartTime",
      text: "Agent Sessione StartTime ",
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "agentSessioneEndTime",
      text: "Agent Session End Time",
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "agentSessionId",
      text: "Agent Session Id",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "agentId",
      text: "Agent Id",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "agentQueueActive",
      text: "Agent Queue Active",
      sort: true,
      formatter: textFormatter,
    },
    {
      dataField: "agentQueueStartTime",
      text: "Agent Queue Start Time",
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "agentQueueEndTime",
      text: "Agent Queue End Time",
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      sort: true,
      formatter: dateFormatter,
    },
  ]

  if (selectedTable === "User Chat History") {
    columns = [
      {
        dataField: "createdAt",
        text: "Created At",
        sort: true,
        formatter: (rowContent, row) => {
          return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY")
        },
      },
      {
        dataField: "updatedAt",
        text: "Updated At",
        sort: true,
        formatter: (rowContent, row) => {
          return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY")
        },
      },
      {
        dataField: "remarks",
        text: "Text",
        sort: true,
        formatter: textFormatter,
      },
      {
        dataField: "moduleId",
        text: "Module",
        sort: true,
        formatter: textFormatter,
      },
      {
        dataField: "transactionType",
        text: "Transaction Type",
        sort: true,
        formatter: textFormatter,
      },
      {
        dataField: "userId",
        text: "User Id",
        sort: true,
        formatter: textFormatter,
      },
      {
        dataField: "sessionId",
        text: "Session Id",
        sort: true,
        formatter: textFormatter,
      },
    ]
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: archives.length, // replace later with size(customers),
    custom: true,
  }

  const { SearchBar } = Search

  const onRangeChange = dates => {
    setDateArray([dropTime(dates[0]), dropTime(dates[1])])
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Archive</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>Archive</h3>
          <Row className={loading ? "loading-parent" : ""}>
            {!loading && (
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={archives}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={archives}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="3">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [dateArray[0], dateArray[1]],
                                    }}
                                  />
                                </Col>
                                <Col md="2">
                                  <Dropdown
                                    isOpen={tableNameDropDown}
                                    toggle={() =>
                                      setTableNameDropDown(!tableNameDropDown)
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      <i className="mdi mdi-chevron-down" />
                                      {selectedTable === "User Chat History"
                                        ? "Archive user and live chat history"
                                        : selectedTable}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {tables.map(table => {
                                        return (
                                          <DropdownItem
                                            key={table.id}
                                            onClick={() => {
                                              onChangeTable(table)
                                            }}
                                          >
                                            {table.code === "User Chat History"
                                              ? "Archive user and live chat history"
                                              : table.code}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                                <Col md="1">
                                  <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={showModal}
                                  >
                                    Archive
                                  </Button>
                                  {/* <div className="me-4 mb-2 d-inline-block">
                                  <div className="position-relative">

                                  </div>
                                </div> */}
                                </Col>
                                <Col md="3">
                                  <h5>
                                    Search Returned {archives.length} Record(s)
                                  </h5>

                                  <GridDownload
                                    title="Archive List"
                                    dateArray={dateArray}
                                    columns={columns}
                                    data={archives}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      noDataIndication={() => (
                                        <div className="text-center">
                                          No Archives available
                                        </div>
                                      )}
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loading && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Achive)
