import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link, withRouter } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { getComplaints, getComplaintCount } from "../../../store/actions"
import moment from "moment"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import features from "../../../assets/images/crypto/features-img/img-1.png"
import GridDownload from "components/GridDownLoad"
import * as url from "./../../../helpers/url_helper"
import { getFileURL } from "helpers/api_helper"

const Complaints = props => {
  const dispatch = useDispatch()
  const {
    complaints,
    loadingAvalability,
    inquiriesCount,
    loading,
  } = useSelector(state => {
    const complaints = [...state.complaint.complaints]
    const inquiriesCount = state.complaint.complaintsCount
    const loadingAvalability = state.complaint.loadingAvalability
    const loading = state.complaint.loading
    return { complaints, loadingAvalability, inquiriesCount, loading }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "months"),
    moment(),
  ])

  const [imageBase64, setImageBase64] = useState("")
  const [showImageModal, setShowImageModal] = useState(false)
  const [showDescriptionTab, setShowDescriptionTab] = useState({
    show: false,
    description: "",
  })

  const toggleImageModal = () => {
    setShowImageModal(!showImageModal)
  }

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "ticketno",
      text: "Ticket Id",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "desc",
      text: "Description",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          // return `${rowContent?.length > 15 ? rowContent.substr(0, 15) +  '...'  : ''}`
          return (
            <>
              <Col className="center">
                {`${
                  rowContent?.length > 15
                    ? rowContent.substr(0, 15) + "... "
                    : rowContent
                }`}
                {rowContent?.length > 15 && (
                  <Link
                    // className="text-success"
                    to="#"
                    onClick={() => {
                      setShowDescriptionTab({
                        show: true,
                        description: rowContent,
                      })
                    }}
                  >
                    See more
                  </Link>
                )}
              </Col>
            </>
          )
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "docno",
      text: "Document No",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "mobileno",
      text: "Mobile No",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "file1",
      text: "File 1",
      formatter: (rowContent, row) => {
        if (rowContent !== null && rowContent === "File Available") {
          return (
            <>
              <Col className="center">
                <Link
                  // className="text-success"
                  to="#"
                  onClick={() => {
                    setImageBase64(
                      getFileURL(url.GET_COMPLAINTS_FILE(row.id, 1))
                    )
                    toggleImageModal()
                  }}
                >
                  <img
                    className="rounded ms-2"
                    alt=""
                    width="20"
                    src={getFileURL(url.GET_COMPLAINTS_FILE(row.id, 1))}
                  />
                </Link>
              </Col>
            </>
          )
        } else {
          return (
            <Col className="text-muted">
              <i className="di dripicons-wrong font-size-18" />
            </Col>
          )
        }
      },
    },
    {
      dataField: "file2",
      text: "File 2",
      formatter: (rowContent, row) => {
        if (rowContent !== null && rowContent !== "") {
          return (
            <>
              <Col md={6}>
                <Link
                  // className="text-success"
                  to="#"
                  onClick={() => {
                    setImageBase64(
                      getFileURL(url.GET_COMPLAINTS_FILE(row.id, 2))
                    )
                    toggleImageModal()
                  }}
                >
                  <img
                    className="rounded ms-2"
                    alt=""
                    width="20"
                    src={getFileURL(url.GET_COMPLAINTS_FILE(row.id, 2))}
                  />
                </Link>
              </Col>
            </>
          )
        }
        return (
          <Col className="text-muted">
            <i className="di dripicons-wrong font-size-18" />
          </Col>
        )
      },
    },
    {
      dataField: "file3",
      text: "File 3",
      formatter: (rowContent, row) => {
        if (rowContent !== null && rowContent !== "") {
          return (
            <>
              <Col md={6}>
                <Link
                  // className="text-success"
                  to="#"
                  onClick={() => {
                    setImageBase64(
                      getFileURL(url.GET_COMPLAINTS_FILE(row.id, 3))
                    )
                    toggleImageModal()
                  }}
                >
                  <img
                    className="rounded ms-2"
                    alt=""
                    width="20"
                    src={getFileURL(url.GET_COMPLAINTS_FILE(row.id, 3))}
                  />
                </Link>
              </Col>
            </>
          )
        }
        return (
          <Col className="text-muted">
            <i className="di dripicons-wrong font-size-18" />
          </Col>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: complaints.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    dispatch(
      getComplaints({
        startDate: dateArray[0],
        endDate: dateArray[1],
      })
    )

    dispatch(getComplaintCount())
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getComplaints({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      })
    )
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Complaints</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>Complaint List</h3>

          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <div>
                    <Row>
                      <Col lg="9" sm="8">
                        <Row className="mt-4">
                          <Col xl={12}>
                            <div className="text-center">
                              <h5 className="mb-4 text-primary">Summary</h5>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="text-lg-center mt-2">
                            <Row>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    Today
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.daily}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Week
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.weekly}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Month
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.monthly}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3" sm="4" className="align-self-center">
                        <div className="p-4">
                          <img
                            src={features}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>

                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={complaints}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={complaints}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "months")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col md={5}>
                                  <div className="text-sm-end">
                                    <div className="me-4 m-2 d-inline-block">
                                      <div className="position-relative">
                                        <h5>
                                          Search Returned {complaints.length}{" "}
                                          Record(s)
                                        </h5>
                                      </div>
                                    </div>

                                    <GridDownload
                                      title="Complaint List"
                                      dateArray={dateArray}
                                      columns={columns}
                                      data={complaints}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Complaints available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                  {showImageModal && (
                                    <Lightbox
                                      mainSrc={imageBase64}
                                      onCloseRequest={() => {
                                        setShowImageModal(!showImageModal)
                                      }}
                                    />
                                  )}
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>

        <Modal
          isOpen={showDescriptionTab.show}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          size={"l"}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                setShowDescriptionTab({ show: false, description: "" })
              }}
            >
              Description
            </ModalHeader>
            <ModalBody>{showDescriptionTab.description}</ModalBody>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Complaints)
