import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"

import { connect } from "react-redux"
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
// import AgentState from "../CommonForBoth/TopbarDropdown/AgentState"
import AgentAvailability from "../CommonForBoth/TopbarDropdown/AgentAvailability"
import { withTranslation } from "react-i18next"
import logo from "../../assets/images/dbkl-logo.png"

const Header = props => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <img src={logo} width="60" className="d-inline-block align-top"/>
            <div><br/>
            <b>&nbsp;&nbsp;Unit Aduan Awan dan Pusat Panggilan Setempat</b><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jabatan Perancangan Korporat
          </div>
          </div>
          <div className="d-flex">
            {/* <AgentState /> */}
            <AgentAvailability />
            <LanguageDropdown />
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}
const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))

