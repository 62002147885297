import React, { useContext, useEffect, useState } from "react"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import SocketContext from "context/socket"
import { getLocalStorage } from "helpers/common_functions"
import { currentUser } from "store/actions"
import Login from "pages/Authentication/Login"
import Dashboard from "pages/Dashboard"
import Users from "pages/Dashboard/Users"
import Logout from "pages/Authentication/Logout"
import Chat from "pages/Dashboard/Chat"
import Ratings from "pages/Dashboard/Ratings"
import Complaints from "pages/Dashboard/Complaints"
import { ModalProvider } from "use-modal-hook"
import CustomerChatbotAudit from "pages/Dashboard/CustomerChatbotAudit"
import CustomerAgentAudit from "pages/Dashboard/CustomerAgentAudit"
import UserAudit from "pages/Dashboard/UserAudit"
import Achive from "pages/Dashboard/Achive"
import ComplaintInquiry from "pages/Dashboard/ComplaintInquiry"
import BusinessInquiry from "pages/Dashboard/BusinessInquiry"
import CompoundInquiry from "pages/Dashboard/CompoundInquiry"
import AssessmentTaxInquiry from "pages/Dashboard/AssessmentTaxInquiry"
import MasterData from "pages/Dashboard/MasterData"
import Page404 from "pages/Page404"
import Prescripts from "pages/Dashboard/Prescripts"
import ChatbotSessionReport from 'pages/Dashboard/ChatbotSessionReport';
import LiveChatSessionReport from 'pages/Dashboard/LiveChatSessionReport';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

const App = props => {
  const authUser = getLocalStorage("authUser")
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)
  const [invalidTabModal, setInvalidTabModal] = useState(false)

  useEffect(() => {
    if (authUser) {
      dispatch(currentUser())
      if (authUser.permissionLevel === 1) {
        socket.emit("OPERATOR_JOINED", {
          agentId: authUser.id,
          permissionLevel: authUser.permissionLevel,
          availability: authUser.availability
        });
      }

      socket.on("OPERATOR_CLOSE_TAB", (socketId) => {
        if(socketId === socket.id) {
          setInvalidTabModal(true)
        }
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Modal
        isOpen={invalidTabModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        size={"l"}
      >
        <div className="modal-content">
          <ModalHeader>Another tab / device found!</ModalHeader>
          <ModalBody>
            We detect another tab / device for this session, please close this
            tab.
          </ModalBody>
        </div>
      </Modal>
      <ModalProvider>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/page-not-found" component={Page404} />
            <Route
              path="/"
              render={({ match: { path } }) => {
                const authUser = getLocalStorage("authUser")
                if (!authUser) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location },
                      }}
                    />
                  )
                } else {
                  return (
                    <HorizontalLayout>
                      <Switch>
                        <Route
                          exact
                          path={path}
                          render={() => {
                            if (
                              authUser &&
                              [2, 3, 6].includes(authUser.permissionLevel)
                            ) {
                              return <Dashboard />
                            } else {
                              if (authUser.permissionLevel === 1) {
                                return (
                                  <Redirect
                                    to={{
                                      pathname: "/chat",
                                      state: { from: props.location },
                                    }}
                                  />
                                )
                              } else if (authUser.permissionLevel === 5) {
                                return (
                                  <Redirect
                                    to={{
                                      pathname: "/master-data",
                                      state: { from: props.location },
                                    }}
                                  />
                                )
                              } else if (authUser.permissionLevel === 4) {
                                return (
                                  <Redirect
                                    to={{
                                      pathname: "/archive",
                                      state: { from: props.location },
                                    }}
                                  />
                                )
                              } else {
                                return (
                                  <Redirect
                                    to={{
                                      pathname: "/page-not-found",
                                      state: { from: props.location },
                                    }}
                                  />
                                )
                              }
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "users"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 6].includes(authUser.permissionLevel)
                            ) {
                              return <Users />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "ratings"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 6].includes(authUser.permissionLevel)
                            ) {
                              return <Ratings />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "complaints"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 6].includes(authUser.permissionLevel)
                            ) {
                              return <Complaints />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "chat"}
                          render={() => {
                            if (
                              authUser &&
                              [1].includes(authUser.permissionLevel)
                            ) {
                              return <Chat />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "user-audit"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <UserAudit />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "customer-chatbot-audit"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <CustomerChatbotAudit />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "chatbot-session-report"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <ChatbotSessionReport />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "live-chat-session-report"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <LiveChatSessionReport />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "customer-agent-audit"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <CustomerAgentAudit />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "complaint-inquiry"}
                          render={() => {
                            if (
                              authUser &&
                              [3, 6].includes(authUser.permissionLevel)
                            ) {
                              return <ComplaintInquiry />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "business-inquiries"}
                          render={() => {
                            if (
                              authUser &&
                              [3, 6].includes(authUser.permissionLevel)
                            ) {
                              return <BusinessInquiry />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "compound-inquiries"}
                          render={() => {
                            if (
                              authUser &&
                              [3, 6].includes(authUser.permissionLevel)
                            ) {
                              return <CompoundInquiry />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "assessment-tax-inquiries"}
                          render={() => {
                            if (
                              authUser &&
                              [3, 6].includes(authUser.permissionLevel)
                            ) {
                              return <AssessmentTaxInquiry />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "archive"}
                          render={() => {
                            if (
                              authUser &&
                              [4, 6].includes(authUser.permissionLevel)
                            ) {
                              return <Achive />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "master-data"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <MasterData />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                        <Route
                          exact
                          path={path + "prescripts"}
                          render={() => {
                            if (
                              authUser &&
                              [2, 5, 6].includes(authUser.permissionLevel)
                            ) {
                              return <Prescripts />
                            } else {
                              return (
                                <Redirect
                                  to={{
                                    pathname: "/page-not-found",
                                    state: { from: props.location },
                                  }}
                                />
                              )
                            }
                          }}
                        />
                      </Switch>
                    </HorizontalLayout>
                  )
                }
              }}
            />
          </Switch>
        </Router>
      </ModalProvider>
    </React.Fragment>
  )
}

export default App
