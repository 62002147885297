import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import {
  GET_ACTIVE_CHATS,
  GET_INACTIVE_CHATS,
  GET_MESSAGES,
  POST_ADD_MESSAGE,
} from "./actionTypes"
import {
  getActiveChatsSuccess,
  getActiveChatsFail,
  getMessagesSuccess,
  getMessagesFail,
  addMessageSuccess,
  addMessageFail,
  getInactiveChatsSuccess,
  getInactiveChatsFail,
} from "./actions"

import {
  getMessages,
  addMessage,
  getActiveChats,
  getInactiveChats,
} from "../../helpers/backend_helper"

function* onGetActiveChats() {
  try {
    const response = yield call(getActiveChats)
    if (response.status === 200 || response.status === 304) {
      yield put(getActiveChatsSuccess(response.data.data))
    }
  } catch (error) {
    yield put(getActiveChatsFail(error))
  }
}

function* onGetInactiveChats() {
  try {
    const response = yield call(getInactiveChats)
    if (response.status === 200 || response.status === 304) {
      yield put(getInactiveChatsSuccess(response.data.data))
    }
  } catch (error) {
    yield put(getInactiveChatsFail(error))
  }
}

function* onGetMessages({ sessionId }) {
  try {
    const response = yield call(getMessages, sessionId)
    if (response.status === 200 || response.status === 304) {
      yield put(getMessagesSuccess(response.data.data))
    }
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* onAddMessage({ message }) {
  try {
    yield put(addMessageSuccess(message))
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

function* chatSaga() {
  yield takeEvery(GET_ACTIVE_CHATS, onGetActiveChats)
  yield takeEvery(GET_INACTIVE_CHATS, onGetInactiveChats)
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)
}

export default chatSaga
