import "flatpickr/dist/themes/material_blue.css"
import {
  getModuleMaster,
  getTransactionTypeMaster,
} from "helpers/backend_helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Flatpickr from "react-flatpickr"
import "react-image-lightbox/style.css"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import {
  getAuditUserSessions,
  SetReportModulesToRedux,
  setReportTransactionTypesToRedux,
} from "../../../store/actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import GridDownload from "components/GridDownLoad"

const CustomerChatbotAudit = props => {
  const dispatch = useDispatch()
  const {
    auditUserSessions,
    reportModules,
    reportTransactionTypes,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const auditUserSessions = [...state.audit.auditUserSessions]
    const reportModules = [...state.app.reportModules]
    const reportTransactionTypes = [...state.app.reportTransactionTypes]
    const loadingAvalability = state.audit.auditUserSessionsLoading
    const loading = state.audit.loading
    return {
      auditUserSessions,
      reportModules,
      reportTransactionTypes,
      loadingAvalability,
      loading,
    }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])
  const [selectedChat, setSelectedChat] = useState({})
  const [showChatModal, setShowChatModal] = useState(false)

  const toggleChatModal = () => {
    setShowChatModal(!showChatModal)
  }

  const [selectedModule, setSelectedModule] = useState({
    id: undefined,
    type_code: undefined,
    description: undefined,
  })
  const [selectedTransactionType, setSelectedTransactionType] = useState({
    id: undefined,
    type_code: undefined,
    description: undefined,
  })

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "user.id",
      text: "User ID",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.name",
      text: "User Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "Phone Number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "report_transaction_master.description",
      text: "Transaction Type",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "report_module_master.description",
      text: "Module",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "Chat",
    //   formatter: (rowContent, row) => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={() => {
    //         setSelectedChat(row)
    //         toggleChatModal()
    //       }}
    //     >
    //       View Chat
    //     </Button>
    //   ),
    // },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: auditUserSessions.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    dispatch(
      getAuditUserSessions({
        startDate: dateArray[0],
        endDate: dateArray[1],
        moduleId: [10],
        transactionType: [35],
      })
    )
  }, [])

  useEffect(() => {
    Promise.all([getModuleMaster(), getTransactionTypeMaster()])
      .then(values => {
        let moduleResponse = values[0]
        let transactionTypeResponse = values[1]
        // moduel master
        if (moduleResponse && moduleResponse.status === 200) {
          dispatch(SetReportModulesToRedux(moduleResponse.data.data))
          if (moduleResponse.data.data && moduleResponse.data.data.length > 0) {
            setSelectedModule(moduleResponse.data.data[8])
          }
        }

        // Transaction type master
        if (transactionTypeResponse && transactionTypeResponse.status === 200) {
          dispatch(
            setReportTransactionTypesToRedux(transactionTypeResponse.data.data)
          )
          if (
            transactionTypeResponse.data.data &&
            transactionTypeResponse.data.data.length > 0
          ) {
            setSelectedTransactionType(transactionTypeResponse.data.data[32])
          }
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getAuditUserSessions({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
        moduleId: [selectedModule.id],
        transactionType: [selectedTransactionType.id],
      })
    )
  }

  const onChangeModule = type => {
    setSelectedModule(type)
    dispatch(
      getAuditUserSessions({
        startDate: dateArray[0],
        endDate: dateArray[1],
        moduleId: [type.id],
        transactionType: [selectedTransactionType.id],
      })
    )
  }

  const onChangeTransactionType = type => {
    setSelectedTransactionType(type)
    dispatch(
      getAuditUserSessions({
        startDate: dateArray[0],
        endDate: dateArray[1],
        moduleId: [selectedModule.id],
        transactionType: [type.id],
      })
    )
  }

  const [reportModuleDropDown, setReportModuleDropDown] = useState(false)
  const [
    reporTransactionTypeDropDown,
    setReportTransactionTypeDropDown,
  ] = useState(false)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Chatbot User Audit</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>Chatbot User Audit</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={auditUserSessions}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={auditUserSessions}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col md="1">
                                  <Dropdown
                                    isOpen={reportModuleDropDown}
                                    toggle={() =>
                                      setReportModuleDropDown(
                                        !reportModuleDropDown
                                      )
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedModule &&
                                        selectedModule.description}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {reportModules.map(type => {
                                        return (
                                          <DropdownItem
                                            key={type.id}
                                            onClick={() => {
                                              onChangeModule(type)
                                            }}
                                          >
                                            {type.description}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </Col>
                                <Col md="1">
                                  <Dropdown
                                    isOpen={reporTransactionTypeDropDown}
                                    toggle={() =>
                                      setReportTransactionTypeDropDown(
                                        !reporTransactionTypeDropDown
                                      )
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedTransactionType &&
                                        selectedTransactionType.description}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      style={{
                                        height: "280px",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {reportTransactionTypes.map(type => {
                                        return (
                                          <DropdownItem
                                            key={type.id}
                                            onClick={() => {
                                              onChangeTransactionType(type)
                                            }}
                                          >
                                            {type.description}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                                <Col md="3">
                                  <GridDownload
                                    title="Chatbot User Audit"
                                    dateArray={dateArray}
                                    columns={columns}
                                    data={auditUserSessions}
                                  />
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Audits available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CustomerChatbotAudit)
