import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getLocalStorage } from "helpers/common_functions"

const Navbar = props => {
  const [userType, setUserType] = useState(0)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  useEffect(() => {
    const authUser = getLocalStorage("authUser")
    if (authUser) {
      setUserType(authUser.permissionLevel)
    }
  }, [userType])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {[2, 3, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {}}
                      to="/"
                    >
                      <i className="bx bx-home-circle me-2"></i>
                      {props.t("Dashboard")} {props.menuOpen}
                    </Link>
                  </li>
                ) : null}

                {[1].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {}}
                      to="/chat"
                    >
                      <i className="bx bx-chat me-2"></i>
                      {props.t("Live Chat DBKL")}
                    </Link>
                  </li>
                ) : null}
                {[2, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/users"
                      onClick={e => {}}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-tone me-2"></i>
                      {props.t("User Updates")}
                    </Link>
                  </li>
                ) : null}
                {[2, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/ratings"
                      onClick={e => {}}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Ratings")}{" "}
                    </Link>
                  </li>
                ) : null}
                {[2, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/complaints"
                      onClick={e => {}}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Complaints")}{" "}
                    </Link>
                  </li>
                ) : null}
                {[4, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/archive"
                      onClick={e => {}}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Archive")}{" "}
                    </Link>
                  </li>
                ) : null}
                {[2, 5, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to=""
                      onClick={e => e.preventDefault()}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Audits")}{" "}
                    </Link>
                    <div className="dropdown-menu">
                      <Link
                        to="/customer-chatbot-audit"
                        className="dropdown-item"
                      >
                        {props.t("Chatbot User Audit")}
                      </Link>
                      <Link
                        to="/customer-agent-audit"
                        className="dropdown-item"
                      >
                        {props.t("Live Agent Audit")}
                      </Link>
                      {[6].includes(userType) ? (
                        <Link to="/user-audit" className="dropdown-item">
                          {props.t("Dashboard User Audit")}
                        </Link>
                      ) : null}
                    </div>
                  </li>
                ) : null}
                {[3, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to=""
                      onClick={e => e.preventDefault()}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Reports")}{" "}
                    </Link>
                    <div className="dropdown-menu">
                      <Link to="/complaint-inquiry" className="dropdown-item">
                        {props.t("Complaint Inquiry")}{" "}
                      </Link>
                      <Link to="/business-inquiries" className="dropdown-item">
                        {props.t("Business License Inquiry")}
                      </Link>
                      <Link to="/compound-inquiries" className="dropdown-item">
                        {props.t("Compound Inquiry")}
                      </Link>
                      <Link
                        to="/assessment-tax-inquiries"
                        className="dropdown-item"
                      >
                        {props.t("Assessment Tax Inquiry")}
                      </Link>
                      <Link to="/chatbot-session-report" className="dropdown-item">
                        {props.t("Chatbot Session Report")}
                      </Link>
                      <Link to="/live-chat-session-report" className="dropdown-item">
                        {props.t("Live Chat Session Report")}
                      </Link>

                    </div>
                  </li>
                ) : null}
                {[2, 5, 6].includes(userType) ? (
                  <li className="nav-item dropdown">
                    <Link
                      to=""
                      onClick={e => e.preventDefault()}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Settings")}{" "}
                    </Link>
                    <div className="dropdown-menu">
                      <Link to="/master-data" className="dropdown-item">
                        {props.t("Admin System")}{" "}
                      </Link>
                      <Link to="/prescripts" className="dropdown-item">
                        {props.t("Pre-script Texts")}
                      </Link>
                    </div>
                  </li>
                ) : null}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
