import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"
import AuthSaga from "./auth/login/saga"
import chatSaga from "./chat/saga"
import usersSaga from "./users/saga"
import notificationSaga from "./notifications/saga"
import ratingSaga from "./rating/saga"
import complaintSaga from "./complaint/saga"
import auditSaga from "./audit/saga"
import archivesSaga from "./archive/saga"
import inquirySaga from "./inquiry/saga"
import appSaga from "./app/saga"

export default function* rootSaga() {
  yield all([
    LayoutSaga(),
    fork(AuthSaga),
    fork(chatSaga),
    fork(usersSaga),
    fork(notificationSaga),
    fork(ratingSaga),
    fork(ratingSaga),
    fork(complaintSaga),
    fork(auditSaga),
    fork(archivesSaga),
    fork(inquirySaga),
    fork(appSaga),
  ])
}
