import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import WelcomeImageUpload from "./WelcomeImageUpload"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getWelcomes,
  removeDepartmentFromRedux,
  setDeparment,
  removeDepartment,
  updateWelcome,
  removeWelcome,
} from "store/actions"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { API_URL } from "helpers/api_helper"

const WelcomeMaster = props => {
  const dispatch = useDispatch()
  const welcomes = useSelector(state => {
    return [...state.app.welcomes]
  })
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false)
  const [deleteWelcomeImage, setDeleteWelcomeImage] = useState({})
  const [imageId, setImageId] = useState(0)
  const [showImageModal, setShowImageModal] = useState(false)

  const toggleImageModal = () => {
    setShowImageModal(!showImageModal)
  }

  useEffect(() => {
    dispatch(getWelcomes())
  }, [])

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "path",
      text: "Image",
      formatter: (rowContent, row) => {
        if (rowContent !== null && rowContent !== "") {
          return (
            <>
              <Col className="center">
                <Link
                  // className="text-success"
                  to="#"
                  onClick={() => {
                    setImageId(row.id)
                    toggleImageModal()
                  }}
                >
                  <img
                    className="rounded ms-2"
                    alt=""
                    width="20"
                    src={`${API_URL}/masterdata/welcome/file/${row.id}`}
                  />
                </Link>
              </Col>
            </>
          )
        } else {
          return (
            <Col className="text-muted">
              <i className="di dripicons-wrong font-size-18" />
            </Col>
          )
        }
      },
    },
    {
      dataField: "button",
      text: "Actions",
      formatter: (rowContent, row) => {
        return (
          <div className="d-flex gap-3">
            <div className="form-check form-switch form-switch-lg mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizelg"
                checked={row.active}
                onChange={event => {
                  dispatch(
                    updateWelcome({ id: row.id, active: event.target.checked })
                  )
                }}
                // onClick={event => {
                //   dispatch(
                //     updateWelcome({ id: row.id, active: event.target.checked })
                //   )
                // }}
              />
            </div>
            <Link
              className="text-danger"
              to="#"
              onClick={() => {
                onDeleteWelcome({
                  id: row.id,
                  name: row.path,
                })
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
            </Link>
          </div>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: welcomes.length,
    custom: true,
  }

  const onDeleteWelcome = image => {
    setDeleteWelcomeImage(image)
    setDeleteConfirmAlert(true)
  }

  const toggle = () => {
    setModal(!modal)
    dispatch(getWelcomes())
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <h3>Welcome Image Configuration</h3>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={welcomes}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={welcomes}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => {
                                  dispatch(removeDepartmentFromRedux())
                                  setIsEdit(false)
                                  toggle()
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add New Welcome Image
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Edit Welcome"
                                    : "Add Welcome Image"}
                                </ModalHeader>
                                <ModalBody>
                                  <WelcomeImageUpload
                                    onSaveSuccess={() => {
                                      toggle()
                                      toastr.success("", "Successfuly saved.")
                                    }}
                                  />
                                </ModalBody>
                              </Modal>
                            </div>
                          </Col>
                          {showImageModal && (
                            <Lightbox
                              mainSrc={`${API_URL}/masterdata/welcome/file/${imageId}`}
                              onCloseRequest={() => {
                                setShowImageModal(!showImageModal)
                              }}
                            />
                          )}
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {deleteConfirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            if (deleteWelcomeImage && deleteWelcomeImage.id > 0) {
              setDeleteConfirmAlert(false)
              dispatch(removeWelcome(deleteWelcomeImage))
            }
          }}
          onCancel={() => setDeleteConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default withRouter(WelcomeMaster)
