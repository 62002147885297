import React from "react"
import PropTypes, { bool, func } from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { CSVLink } from "react-csv"
import moment from "moment"

const CompoundDetaileModal = props => {
  const { isOpen, close, inquiry, details } = props

  const currencyFormatter = content => {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "MYR",
    })

    return formatter.format(content)
  }

  const headers = [
    "#",
    "Offence Date",
    "Notice No",
    "Owner Name",
    "Vehicle Reg. Id",
    "Notice Status",
    "Blacklisted Status",
    "Amount",
    "Offence Act",
    "Offence Location",
    "Offence Description",
  ]

  const dateFormatter = string => {
    return moment(new Date(`${string}`)).format("DD/MM/YYYY - HH:mm")
  }

  const exportPDF = download => {
    const unit = "pt"
    const size = "legal" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = `Traffic Compound Inquiry - ${(inquiry && inquiry.id) || ""}`
    const tableHeaders = [headers]
    const data = details.map((detail, index) => [
      index + 1,
      dateFormatter(detail.offenceDate),
      detail.noticeNumber,
      inquiry && inquiry.user && inquiry.user.name,
      inquiry && inquiry.vehicleNumber,
      detail.cii_notice_status_master.code,
      detail.cii_notice_status_master.code === "W" ? "Y" : "N",
      currencyFormatter(detail.amount),
      detail.offenceAct,
      detail.offenceLocation,
      detail.offenceDescription,
    ])

    let content = {
      startY: 50,
      head: tableHeaders,
      body: data,
      styles: {
        fontSize: 5,
      },
      showHead: "everyPage",
      showFoot: "lastPage",
      foot: [
        [
          "",
          "",
          "Total:",
          "",
          (details && details.length) || 0,
          "",
          "",
          (inquiry && currencyFormatter(inquiry.totalAmount)) || 0,
        ],
      ],
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(`Traffic Compound Inquiry - ${(inquiry && inquiry.id) || ""}`)
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const headerArrayOfArray = [headers]
  const detailsArrayOfArrays = details.map((detail, index) => [
    index + 1,
    dateFormatter(detail.offenceDate),
    detail.noticeNumber,
    inquiry && inquiry.user && inquiry.user.name,
    inquiry && inquiry.vehicleNumber,
    detail.cii_notice_status_master.code,
    detail.cii_notice_status_master.code === "W" ? "Y" : "N",
    currencyFormatter(detail.amount),
    detail.offenceAct,
    detail.offenceLocation,
    detail.offenceDescription,
  ])
  const cvsData = headerArrayOfArray.concat(detailsArrayOfArrays)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={close}
      size={"xl"}
    >
      <div className="modal-content">
        <ModalHeader toggle={close}>Inquiry Details</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Offence Date</th>
                  <th scope="col">Notice No</th>
                  <th scope="col">Owner Name</th>
                  <th scope="col">Vehicle Reg. Id</th>
                  <th scope="col">Notice Status</th>
                  <th scope="col">Blacklisted Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Offence Act</th>
                  <th scope="col">Offence Location</th>
                  <th scope="col">Offence Description</th>
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th>
                          <p className="text-muted mb-0">{index + 1}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.offenceDate}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.noticeNumber}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {inquiry && inquiry.user && inquiry.user.name}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {inquiry && inquiry.vehicleNumber}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.cii_notice_status_master.code}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.cii_notice_status_master.code === "W"
                              ? "Y"
                              : "N"}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {currencyFormatter(item.amount)}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.offenceAct}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.offenceLocation}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.offenceDescription}
                          </p>
                        </th>
                      </tr>
                    )
                  })}
                <tr>
                  <td colSpan="4">
                    <h3 className="m-0 text-end">Total:</h3>
                  </td>
                  <td>
                    <h3 className="m-0 text-end">
                      {details && details.length}
                    </h3>
                  </td>
                  <td colSpan="2">
                    <h3 className="m-0 text-end">
                      {inquiry && currencyFormatter(inquiry.totalAmount)}
                    </h3>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <CSVLink
            data={cvsData}
            filename={`Traffic Compound Inquiry - ${
              (inquiry && inquiry.id) || ""
            }.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            CSV
          </CSVLink>
          <Button
            color="primary"
            onClick={() => {
              exportPDF(true)
            }}
          >
            <i className="mdi mdi-download" />
          </Button>
          <Button
            color="primary"
            onClick={() => {
              exportPDF(false)
            }}
          >
            <i className="mdi mdi-printer" />
          </Button>
          <Button type="button" color="secondary" onClick={close}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

CompoundDetaileModal.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  inquiry: PropTypes.object,
  details: PropTypes.array,
}

export default CompoundDetaileModal
