import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
  useContext,
} from "react"
import moment from "moment"
import { getLocalStorage } from "helpers/common_functions"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router"
import SocketContext from "context/socket"

const SessionTimeout = () => {
  const [events, setEvents] = useState(["click", "load", "scroll"])
  const [second, setSecond] = useState(0)
  const [isOpen, setOpen] = useState(false)
  let timeStamp
  let warningInactiveInterval = useRef()
  let startTimerInterval = useRef()
  const history = useHistory()
  const socket = useContext(SocketContext)

  const logoutUserAfterInactive = () => {
    history.push("/logout")
    window.location.reload(false)
  }

  // start inactive check
  let timeChecker = () => {
    const inactiveTimeCheckAfter = parseInt(process.env.REACT_APP_AUTOMATIC_LOGOUT, 10)
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp")
      warningInactive(storedTimeStamp)
    }, inactiveTimeCheckAfter * 1000)
  }

  // warning timer
  let warningInactive = timeString => {
    clearTimeout(startTimerInterval.current)

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 2
      const popTime = 1

      const diff = moment.duration(moment().diff(moment(timeString)))
      const minPast = diff.minutes()
      const leftSecond = 60 - diff.seconds()

      if (minPast === popTime) {
        setSecond(leftSecond)
        setOpen(true)
        // showInactiveModal()
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current)
        setOpen(false)
        sessionStorage.removeItem("lastTimeStamp")
        logoutUserAfterInactive()
      }
    }, 1000)
  }

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current)
    clearInterval(warningInactiveInterval.current)
    const isAuthenticated = getLocalStorage("authUser")
    if (isAuthenticated) {
      timeStamp = moment()
      sessionStorage.setItem("lastTimeStamp", timeStamp)
    } else {
      clearInterval(warningInactiveInterval.current)
      sessionStorage.removeItem("lastTimeStamp")
    }
    timeChecker()
    setOpen(false)
  })

  // handle close popup
  const handleClose = () => {
    setOpen(false)
    resetTimer()
  }

  useEffect(() => {
    events.forEach(event => {
      window.addEventListener(event, resetTimer)
    })

    timeChecker()

    return () => {
      clearTimeout(startTimerInterval.current)
      //   resetTimer();
    }
  }, [resetTimer, events, timeChecker])

  console.log(second)

  if (!isOpen) {
    return null
  } else {
    return (
      <SweetAlert
        title="User inactive check"
        warning
        showCancel
        confirmBtnText="Logout"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        onConfirm={() => {
          handleClose()
          logoutUserAfterInactive()
        }}
        onCancel={handleClose}
        cancelBtnText="No, I'm still here"
      >
        Seems like you are forgot to logout from browser, let us know you still
        there or not. We are going to automatically log out from you after 60
        seconds of inactivity after this modal appear
      </SweetAlert>
    )
  }

  // change fragment to modal and handleclose func to close
  return <Fragment />
}

export default SessionTimeout
