import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { CSVLink } from "react-csv"
import { Button } from "reactstrap"

const GridDownload = props => {
  function getDescendantProp(obj, desc) {
    var arr = desc.split(".")
    while (arr.length && (obj = obj[arr.shift()]));
    return obj
  }

  function generateFileName() {
    return `${props.title}(${props.dateArray[0]}-${props.dateArray[1]})`
  }

  function generateDataForDocumentations(data, columns) {
    const detailsArrayOfArrays = []
    data.forEach(d => {
      const colArray = []
      columns.forEach(col => {
        if (col.dataField !== "button" && col.dataField.indexOf('file') < 0) {
          let fotmatedVal = col.formatter(getDescendantProp(d, col.dataField));
          if(typeof fotmatedVal !== 'string') {
            fotmatedVal = getDescendantProp(d, col.dataField)
          }
          colArray.push(fotmatedVal)
        }
      })
      detailsArrayOfArrays.push(colArray)
    })
    return detailsArrayOfArrays
  }

  const headers = [
    props.columns
      .filter(item => item.dataField !== "button" && item.dataField.indexOf('file') < 0)
      .map(item => item.text),
  ]
  const detailsArrayOfArrays = generateDataForDocumentations(
    props.data,
    props.columns
  )

  function exportPDF(download) {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    let content = {
      startY: 50,
      head: headers,
      body: detailsArrayOfArrays,
      styles: {
        fontSize: 7,
      },
    }

    doc.text(props.title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(`${generateFileName()}.pdf`)
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const cvsData = headers.concat(detailsArrayOfArrays)

  return (
    <React.Fragment>
      <CSVLink
        data={cvsData}
        filename={`${generateFileName()}.csv`}
        className="btn btn-primary me-2"
        target="_blank"
      >
        CSV
      </CSVLink>
      <Button
        className="me-2"
        color="primary"
        onClick={() => {
          exportPDF(true)
        }}
      >
        <i className="mdi mdi-download" />
      </Button>
      <Button
        className="me-2"
        color="primary"
        onClick={() => {
          exportPDF(false)
        }}
      >
        <i className="mdi mdi-printer" />
      </Button>
    </React.Fragment>
  )
}

export default GridDownload
