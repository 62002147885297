import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ASSESSMENT_TAX_INQUIRIES,
  GET_ASSESSMENT_TAX_INQUIRIES_COUNT,
  GET_BUSINESS_INQUIRIES,
  GET_BUSINESS_INQUIRIES_COUNT,
  GET_COMPOUND_INQUIRIES,
  GET_COMPOUND_INQUIRIES_COUNT,
  GET_INQUIRIES,
  GET_INQUIRIES_COUNT,
} from "./actionTypes"
import {
  getBusinessInquiriesSuccess,
  getCompoundInquiriesSuccess,
  getInquiriesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAssessmentTaxInquiries,
  getBusinessInquiries,
  getCompoundInquiries,
  getInquiries,
  getInquiriesCount,
  getBusinessInquiriesCount,
  getCompoundInquiriesCount,
  getAssessmentTaxInquiriesCount,

} from "../../helpers/backend_helper"
import { getAssessmentTaxInquiriesCountSuccess, getAssessmentTaxInquiriesSuccess, getBusinessInquiriesCountSuccess, getCompoundInquiriesCountSuccess, getInquiriesCountSuccess } from "store/actions"

function* fetchInquiries({ payload }) {
  try {
    const response = yield call(getInquiries, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getInquiriesSuccess(response.data.data))
    } else {
      yield put(getInquiriesSuccess([]))
    }
  } catch (error) {
    yield put(getInquiriesSuccess([]))
  }
}

function* fetchInquiriesCount({ payload }) {
  try {
    const response = yield call(getInquiriesCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getInquiriesCountSuccess(response.data.data))
    } else {
      yield put(getInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}

function* fetchBusinessInquires({ payload }) {
  try {
    const response = yield call(getBusinessInquiries, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getBusinessInquiriesSuccess(response.data.data))
    } else {
      yield put(getBusinessInquiriesSuccess([]))
    }
  } catch (error) {
    yield put(getBusinessInquiriesSuccess([]))
  }
}

function* fetchBusinessInquiresCount({ payload }) {
  try {
    const response = yield call(getBusinessInquiriesCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getBusinessInquiriesCountSuccess(response.data.data))
    } else {
      yield put(getBusinessInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getBusinessInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}

function* fetchCompoundInquires({ payload }) {
  try {
    const response = yield call(getCompoundInquiries, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getCompoundInquiriesSuccess(response.data.data))
    } else {
      yield put(getCompoundInquiriesSuccess([]))
    }
  } catch (error) {
    yield put(getCompoundInquiriesSuccess([]))
  }
}

function* fetchCompoundInquiresCount({ payload }) {
  try {
    const response = yield call(getCompoundInquiriesCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getCompoundInquiriesCountSuccess(response.data.data))
    } else {
      yield put(getCompoundInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getCompoundInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}

function* fetchAssessmentTaxInquiries({ payload }) {
  try {
    const response = yield call(getAssessmentTaxInquiries, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getAssessmentTaxInquiriesSuccess(response.data.data))
    } else {
      yield put(getAssessmentTaxInquiriesSuccess([]))
    }
  } catch (error) {
    yield put(getAssessmentTaxInquiriesSuccess([]))
  }
}

function* fetchAssessmentTaxInquiriesCount({ payload }) {
  try {
    const response = yield call(getAssessmentTaxInquiriesCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getAssessmentTaxInquiriesCountSuccess(response.data.data))
    } else {
      yield put(getAssessmentTaxInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getAssessmentTaxInquiriesCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}



function* complaintsSaga() {
  yield takeEvery(GET_INQUIRIES, fetchInquiries)
  yield takeEvery(GET_INQUIRIES_COUNT, fetchInquiriesCount)

  yield takeEvery(GET_BUSINESS_INQUIRIES, fetchBusinessInquires)
  yield takeEvery(GET_BUSINESS_INQUIRIES_COUNT, fetchBusinessInquiresCount)

  yield takeEvery(GET_COMPOUND_INQUIRIES, fetchCompoundInquires)
  yield takeEvery(GET_COMPOUND_INQUIRIES_COUNT, fetchCompoundInquiresCount)

  yield takeEvery(GET_ASSESSMENT_TAX_INQUIRIES, fetchAssessmentTaxInquiries)
  yield takeEvery(GET_ASSESSMENT_TAX_INQUIRIES_COUNT, fetchAssessmentTaxInquiriesCount)

}

export default complaintsSaga
