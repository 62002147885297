import useSWR from "swr"

import { getTables } from "helpers/backend_helper"

export default function useTables() {
    const { data, error } = useSWR("/api/user", getTables)
    let tables = [];
    if (data && data.data) {
        tables = data.data.data;
    }
    return {
        loading: !data && !error,
        error: error,
        tables,
    }
}