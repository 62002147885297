import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import {
  getNotifications,
  getActiveChats,
  markAsReadNotification,
  markAllAsReadNotification,
} from "../../../store/actions"
import moment from "moment"

//i18n
import { withTranslation } from "react-i18next"
import SocketContext from "context/socket"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const socket = React.useContext(SocketContext)
  const { notifications } = useSelector(state => {
    return { ...state.notifications }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    socket.on("NEW_NOTIFICATION", message => {
      console.log("NEW_NOTIFICATION ", message);
      if(message.type === "CHAT") {
        toastr.success(message.message, "New user request")
      }
      dispatch(getNotifications())
      dispatch(getActiveChats())
    })
  }, [])

  useEffect(() => {
    dispatch(getNotifications())
  }, [getNotifications])

  const onClickNotification = notification => {
    if (notification.sessionActive) {
      if (!notification.hasRead) {
        dispatch(markAsReadNotification(notification.id))
      }
    }
  }

  const onClickDropDown = () => {
    notifications.forEach(notification => {
      if (!notification.hasRead) {
        dispatch(markAllAsReadNotification())
        return
      }
    })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          setMenu(!menu)
          if (!menu) {
            onClickDropDown()
          }
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
          {notifications.length > 0 ? (
            <span className="badge bg-danger rounded-pill">
              {
                notifications.filter(noti => {
                  return !noti.hasRead
                }).length
              }
            </span>
          ) : null}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          {notifications.length === 0 ? (
            <SimpleBar
              style={{
                height: "100px",
                padding: 30,
              }}
            >
              <h5 className="text-muted text-center">Notifications Empty</h5>
            </SimpleBar>
          ) : (
            <SimpleBar style={{ height: "230px" }}>
              {notifications.map(noti => {
                return (
                  <div key={noti.id}>
                    {noti.sessionActive ? (
                      <Link
                        key={noti.id}
                        to="/chat"
                        className="text-reset notification-item"
                        onClick={() => onClickNotification(noti)}
                      >
                        <div className="media" style={{ cursor: "default" }}>
                          <div className="avatar-xs me-3">
                            {noti.hasRead ? (
                              <span className="avatar-title bg-success rounded-circle font-size-16">
                                <i className="bx bx-comment-check" />
                              </span>
                            ) : (
                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className="bx bx-user" />
                              </span>
                            )}
                          </div>
                          <div className="media-body">
                            <h6 className="mt-0 mb-1">
                              {props.t(`${noti.message}`)}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline" />{" "}
                                {moment(
                                  new Date(`${noti.createdAt}`)
                                ).fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div className="text-reset notification-item">
                        <div
                          className="media"
                          style={{ cursor: "not-allowed" }}
                        >
                          <div className="avatar-xs me-3">
                            <span className="avatar-title bg-secondary rounded-circle font-size-16">
                              <i className="bx bx-comment-check" />
                            </span>
                          </div>
                          <div className="media-body">
                            <h6 className="mt-0 mb-1 text-muted">
                              {props.t(`${noti.message}`)}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline" />{" "}
                                {moment(
                                  new Date(`${noti.createdAt}`)
                                ).fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </SimpleBar>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
