import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_STATE,
  UPDATE_CURRENT_USER_AVAILABILITY,
  UPDATE_CURRENT_USER_STATE_SUCCESS,
  UPDATE_CURRENT_USER_AVAILABILITY_SUCCESS,
  SET_CURRENT_USER_STATE_SUCCESS,
  REMOVE_API_ERROR,
} from "./actionTypes"

export const loginUser = (user, history, socket) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, socket },
  }
}

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  }
}

export const logoutUser = (history, socket) => {
  return {
    type: LOGOUT_USER,
    payload: { history, socket },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const removeApiError = () => {
  return {
    type: REMOVE_API_ERROR,
  }
}

export const currentUser = () => {
  return { type: GET_CURRENT_USER }
}

export const currentUserSuccess = user => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: user,
  }
}

export const updateCurrentUser = obj => {
  return {
    type: UPDATE_CURRENT_USER,
    payload: obj,
  }
}

export const updateCurrentUserState = obj => {
  return {
    type: UPDATE_CURRENT_USER_STATE,
    payload: obj,
  }
}

export const updateCurrentUserAvalability = obj => {
  return {
    type: UPDATE_CURRENT_USER_AVAILABILITY,
    payload: obj,
  }
}

export const updateCurrentUserStateSuccess = obj => {
  return {
    type: UPDATE_CURRENT_USER_STATE_SUCCESS,
  }
}

export const updateCurrentUserAvalabilitySuccess = obj => {
  return {
    type: UPDATE_CURRENT_USER_AVAILABILITY_SUCCESS,
  }
}

export const setCurrentUserStateSuccess = bool => {
  return {
    type: SET_CURRENT_USER_STATE_SUCCESS,
    payload: bool,
  }
}

export const setCurrentUserAvalabilitySuccess = obj => {
  return {
    type: SET_CURRENT_USER_STATE_SUCCESS,
  }
}
