import {
  GET_ASSESSMENT_TAX_INQUIRIES,
  GET_ASSESSMENT_TAX_INQUIRIES_SUCCESS,
  GET_BUSINESS_INQUIRIES,
  GET_BUSINESS_INQUIRIES_SUCCESS,
  GET_COMPOUND_INQUIRIES,
  GET_COMPOUND_INQUIRIES_SUCCESS,
  GET_INQUIRIES,
  GET_INQUIRIES_SUCCESS,
  GET_INQUIRIES_COUNT,
  GET_INQUIRIES_COUNT_SUCCESS,
  GET_BUSINESS_INQUIRIES_COUNT,
  GET_BUSINESS_INQUIRIES_COUNT_SUCCESS,
  GET_COMPOUND_INQUIRIES_COUNT,
  GET_COMPOUND_INQUIRIES_COUNT_SUCCESS,
  GET_ASSESSMENT_TAX_INQUIRIES_COUNT,
  GET_ASSESSMENT_TAX_INQUIRIES_COUNT_SUCCESS,

} from "./actionTypes"

export const getInquiries = filter => ({
  type: GET_INQUIRIES,
  payload: filter,
})

export const getInquiriesCount = filter => ({
  type: GET_INQUIRIES_COUNT,
  payload: filter,
})

export const getInquiriesCountSuccess = filter => ({
  type: GET_INQUIRIES_COUNT_SUCCESS,
  payload: filter,
})


export const getInquiriesSuccess = list => ({
  type: GET_INQUIRIES_SUCCESS,
  payload: list,
})

export const getBusinessInquiries = filter => ({
  type: GET_BUSINESS_INQUIRIES,
  payload: filter,
})

export const getBusinessInquiriesCount = filter => ({
  type: GET_BUSINESS_INQUIRIES_COUNT,
  payload: filter,
})

export const getBusinessInquiriesCountSuccess = filter => ({
  type: GET_BUSINESS_INQUIRIES_COUNT_SUCCESS,
  payload: filter,
})

export const getBusinessInquiriesSuccess = list => ({
  type: GET_BUSINESS_INQUIRIES_SUCCESS,
  payload: list,
})

export const getCompoundInquiries = filter => ({
  type: GET_COMPOUND_INQUIRIES,
  payload: filter,
})

export const getCompoundInquiriesSuccess = list => ({
  type: GET_COMPOUND_INQUIRIES_SUCCESS,
  payload: list,
})

export const getCompoundInquiriesCount = filter => ({
  type: GET_COMPOUND_INQUIRIES_COUNT,
  payload: filter,
})

export const getCompoundInquiriesCountSuccess = filter => ({
  type: GET_COMPOUND_INQUIRIES_COUNT_SUCCESS,
  payload: filter,
})

export const getAssessmentTaxInquiries = filter => ({
  type: GET_ASSESSMENT_TAX_INQUIRIES,
  payload: filter,
})

export const getAssessmentTaxInquiriesSuccess = list => ({
  type: GET_ASSESSMENT_TAX_INQUIRIES_SUCCESS,
  payload: list,
})

export const getAssessmentTaxInquiriesCount = filter => ({
  type: GET_ASSESSMENT_TAX_INQUIRIES_COUNT,
  payload: filter,
})

export const getAssessmentTaxInquiriesCountSuccess = list => ({
  type: GET_ASSESSMENT_TAX_INQUIRIES_COUNT_SUCCESS,
  payload: list,
})

