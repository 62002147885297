import {
  GET_AGENT_AUDITS_SUCCESS,
  GET_AUDIT_USER_SESSIONS_SUCCESS,
  GET_CUSTOMER_AGENT_AUDITS_SUCCESS,
  GET_CHATBOT_SESSION_COUNT_SUCCESS,
  GET_LIVE_CHAT_SESSION_COUNT_SUCCESS,
  GET_AUDIT_USER_SESSIONS,
  GET_CUSTOMER_AGENT_AUDITS,
  GET_AGENT_AUDITS,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  auditUserSessions: [],
  customerAgentAudits: [],
  customerAgentAuditsLoading: true,
  agentAudits: [],
  agentAuditsLoading: true,
  chatbotSessionCount: {},
  auditUserSessionsLoading: true,
  liveChatSessionCount: {},
}

const audit = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIT_USER_SESSIONS:
    case GET_CUSTOMER_AGENT_AUDITS:
    case GET_AGENT_AUDITS:
      return {
        ...state,
        loading: true,
      }
    case GET_AUDIT_USER_SESSIONS_SUCCESS:
      return {
        ...state,
        auditUserSessions: action.payload ?? [],
        auditUserSessionsLoading: false,
        loading: false,
      }
    case GET_CUSTOMER_AGENT_AUDITS_SUCCESS:
      return {
        ...state,
        customerAgentAudits: action.payload ?? [],
        customerAgentAuditsLoading: false,
        loading: false,
      }
    case GET_AGENT_AUDITS_SUCCESS:
      return {
        ...state,
        agentAudits: action.payload ?? [],
        agentAuditsLoading: false,
        loading: false,
      }
    case GET_CHATBOT_SESSION_COUNT_SUCCESS:
      return {
        ...state,
        chatbotSessionCount: action.payload ?? [],
      }
    case GET_LIVE_CHAT_SESSION_COUNT_SUCCESS:
      return {
        ...state,
        liveChatSessionCount: action.payload ?? [],
      }
    default:
      return state
  }
}

export default audit
