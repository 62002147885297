import { GET_ARCHIVES, GET_ARCHIVES_SUCCESS } from "./actionTypes"

export const getArchives = filter => ({
  type: GET_ARCHIVES,
  payload: filter,
})

export const getArchivesSuccess = list => ({
  type: GET_ARCHIVES_SUCCESS,
  payload: list,
})
