import { getDashboardList } from "helpers/backend_helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Row, Col, Card } from "reactstrap"

//Import Image
import features from "../../../../assets/images/crypto/features-img/img-1.png"

const DailyStatisticsCard = () => {
  const [USC, setUSC] = useState(0)
  const [CTC, setCTC] = useState(0)
  const [ORC, setORC] = useState(0)
  const [UC, setUC] = useState(0)
  const [CIC, setCIC] = useState(0)

  useEffect(() => {
    getDashboardList({
      typeCode: "USC",
      startDate: moment().startOf("day").format(),
      endDate: moment().endOf("day").format(),
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const item = response.data.data[0]
          setUSC(parseInt(item && item.count))
        }
      })
      .catch(e => {})
  }, [USC])

  useEffect(() => {
    getDashboardList({
      typeCode: "CTC",
      startDate: moment().startOf("day").format(),
      endDate: moment().endOf("day").format(),
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const item = response.data.data[0]
          setCTC(parseInt(item && item.count))
        }
      })
      .catch(e => {})
  }, [CTC])

  useEffect(() => {
    getDashboardList({
      typeCode: "ORC",
      startDate: moment().startOf("day").format(),
      endDate: moment().endOf("day").format(),
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const item = response.data.data[0]
          setORC(parseInt(item && item.count))
        }
      })
      .catch(e => {})
  }, [ORC])

  useEffect(() => {
    getDashboardList({
      typeCode: "UC",
      startDate: moment().startOf("day").format(),
      endDate: moment().endOf("day").format(),
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const item = response.data.data[0]
          setUC(parseInt(item && item.count))
        }
      })
      .catch(e => {})
  }, [UC])

  useEffect(() => {
    getDashboardList({
      typeCode: "CIC",
      startDate: moment().startOf("day").format(),
      endDate: moment().endOf("day").format(),
    })
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          const item = response.data.data[0]
          setCIC(parseInt(item && item.count))
        }
      })
      .catch(e => {})
  }, [CIC])

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <div>
            <Row className="mb-4">
              <Col lg="9" sm="8">
                <Row className="mt-4">
                  <Col xl={12}>
                    <div className="text-center">
                      <h5 className="mb-4 text-primary">Today's Statistic</h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="text-lg-center mt-2">
                    <Row>
                      <Col>
                        <div>
                          <p className="text-muted text-truncate mb-2">USC</p>
                          <h5 className="mb-0">{USC}</h5>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <p className="text-muted text-truncate mb-2">CTC</p>
                          <h5 className="mb-0">{CTC}</h5>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <p className="text-muted text-truncate mb-2">ORC</p>
                          <h5 className="mb-0">{ORC}</h5>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <p className="text-muted text-truncate mb-2">UC</p>
                          <h5 className="mb-0">{UC}</h5>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <p className="text-muted text-truncate mb-2">CIC</p>
                          <h5 className="mb-0">{CIC}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col lg="3" sm="4" className="align-self-center">
                <div className="p-4">
                  <img src={features} alt="" className="img-fluid d-block" />
                </div>
              </Col>
              <Row className="mt-3">
              <Col xl={12}>
               <div className="text-center"><b>USC</b>-User Session Count&nbsp;&nbsp;<b>CTC</b>-Complaint Ticket Count&nbsp;&nbsp;<b>ORC</b>-Overall Rate Count&nbsp;&nbsp;
               <b>UC</b>-User Count&nbsp;&nbsp;<b>CIC</b>-Complaint Inquiry Count</div>
              </Col>
            </Row>
            </Row>
            
          </div>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default DailyStatisticsCard
