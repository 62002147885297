import React, { useEffect, useState } from "react"
import { withRouter } from "react-router"
import { nameOf } from "helpers/common_functions"
import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  setDeparment,
  saveDepartment,
  updateDepartment,
  setDepartmentSaved,
  setDepartmentUpdated,
  setFAQToRedux,
  updateFAQ,
  loader,
  setFAQUpdated,
} from "store/actions"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Dropzone from "react-dropzone"
import { saveFAQ } from "helpers/backend_helper"

class FAQModel {
  constructor(obj) {
    if (obj != null) {
      Object.assign(this, obj)
    } else {
      this.id = 0
      this.title = ""
      this.departmentId = ""
      this.language = "BM"
    }
  }
}

const FAQCrupdate = props => {
  const { loading, faq, faqUpdated, departments } = useSelector(state => {
    let { faq, loading, departments, faqUpdated } = state.app
    const hasKeys = !!Object.keys(faq).length
    faq = hasKeys ? new FAQModel(faq) : new FAQModel()
    return {
      loading,
      faq,
      departments,
      faqUpdated,
    }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (faqUpdated) {
      dispatch(setFAQUpdated(false))
      toastr.success("", "Successfuly updated.")
    }
  }, [faqUpdated])

  const valueOnChange = event => {
    dispatch(setFAQToRedux({ ...faq, [event.target.name]: event.target.value }))
  }

  const handleValidSubmit = (event, values) => {
    if (faq.id === 0) {
      if (selectedFiles.length > 0) {
        const formData = new FormData()
        formData.append("FAQ", selectedFiles[0])
        formData.append("title", faq.title)
        formData.append("description", faq.description)
        formData.append("departmentId", faq.departmentId)
        formData.append("language", faq.language)
        dispatch(loader(true))
        saveFAQ(formData)
          .then(response => {
            dispatch(loader(false))
            props.onSaveSuccess()
          })
          .catch(e => {
            dispatch(loader(false))
          })
      }
    } else {
      dispatch(updateFAQ(faq))
    }
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    if (departments.length === 0) {
      getDepartmentMaster().then(response => {
        if (response && response.status === 200) {
          dispatch(setDepartmentsToRedux(response.data.data))
        }
      })
    }
    if (faq.id === 0 && departments.length > 0) {
      dispatch(setFAQToRedux({ ...faq, departmentId: departments[0].id }))
    }
  }, [])

  const [selectedFiles, setselectedFiles] = useState([])

  return (
    <React.Fragment>
      <AvForm
        model={new FAQModel()}
        onValidSubmit={handleValidSubmit}
        className="needs-validation"
      >
        {faq.id === 0 ? (
          <div>
            <Dropzone
              maxSize={10 * 1024 * 1024}
              maxFiles={1}
              accept={["application/pdf"]}
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
            <br />
            <br />
          </div>
        ) : null}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-email-Input"
            className="col-sm-3 col-form-label"
          >
            Title
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-email-Input"
                name={nameOf(faq).title}
                value={faq.title}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Title" },
                }}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Description
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-ic-Input"
                name={nameOf(faq).description}
                value={faq.description}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Description" },
                }}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Department
          </Label>
          <Col sm={9}>
            <select
              className="form-control"
              name={nameOf(faq).departmentId}
              value={faq.departmentId}
              onChange={valueOnChange}
            >
              {departments.map(a => {
                return (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                )
              })}
            </select>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Language
          </Label>
          <Col sm={9}>
            <select
              className="form-control"
              name={nameOf(faq).language}
              value={faq.language}
              onChange={valueOnChange}
            >
              <option key={"BM"} value={"BM"}>
                Bahasa Malaysia
              </option>
              <option key={"EN"} value={"EN"}>
                English
              </option>
            </select>
          </Col>
        </div>
        <div className="row justify-content-end">
          <Col sm={9}>
            <div className="text-sm-end">
              <Button
                type="submit"
                // onClick={submit}
                color="primary"
                className="w-md"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : null}
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default withRouter(FAQCrupdate)
