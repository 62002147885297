import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const UserCard = () => {
  const { profile } = useSelector(state => {
    const profile = state.Login.profile
    return { profile }
  })

  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <div>
              <div className="mb-5">
                <i className="mdi mdi-account-circle text-primary h1"></i>
              </div>

              {profile && (
                <div>
                  <h5>{profile.name}</h5>
                  <p className="text-muted mb-1">{profile.email}</p>
                  <p className="text-muted mb-0">Staff Id: {profile.staffId}</p>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default UserCard
