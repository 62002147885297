import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  DELETE_USER,
  GET_CC_AGENTS,
  GET_USER,
  GET_USERS,
  SAVE_USER,
  UPDATE_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserSuccess,
  saveUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  saveUserFail,
  updateUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getCCAgents,
  getUser,
  postUser,
  patchUser,
  delUser,
} from "../../helpers/backend_helper"

function* fetchUsers(param) {
  try {
    const response = yield call( param && param.type === "GET_CC_AGENTS" ? getCCAgents : getUsers)
    if (200 === response.status || 304 === response.status) {
      yield put(getUsersSuccess(response.data.data))
    } else {
      // yield apiError("agents fetching error")
    }
  } catch (error) {
    // yield apiError(error)
  }
}

function* fetchUser({ payload }) {
  try {
    const response = yield call(getUser, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getUserSuccess(response.data.data))
    } else {
      // yield apiError("agent fetching error")
    }
  } catch (error) {
    console.log("saga => fetchUser => error", error)
    // yield apiError(error)
  }
}

function* saveUser({ payload }) {
  console.log("saga => saveUser =>")
  try {
    const response = yield call(postUser, payload)
    if (201 === response.status || 304 === response.status) {
      console.log("saga => saveUser => response => success", response)
      yield put(saveUserSuccess(response.data.data))
    } else if (400 === response.status) {
      console.log("saga => saveUser => response => error", response)
      yield put(saveUserFail(response.data.error.message))
    }
  } catch (error) {
    console.log("saga => saveUser => error", error)
    // yield apiError(error)
  }
}

function* updateUser({ payload }) {
  try {
    const response = yield call(patchUser, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(updateUserSuccess(response.data.data))
    } else if (400 === response.status) {
      yield put(updateUserFail(response.data.error.message))
    }
  } catch (error) {
    console.log("saga => updateUser => error", error)
    // yield apiError(error)
  }
}

function* deleteUser({ payload }) {
  try {
    const response = yield call(delUser, payload)
    if (200 === response.status || 304 === response.status) {
      const response = yield call(getUsers)
      if (200 === response.status || 304 === response.status) {
        yield put(getUsersSuccess(response.data.data))
      } else {
        // yield put(apiError(error))
      }
      yield put(deleteUserSuccess(response.data.data))
    } else {
      // yield put(apiError("agent deletion error"))
    }
  } catch (error) {
    console.log("saga => deleteUser => error", error)
    // yield apiError(error)
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_CC_AGENTS, fetchUsers)
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(SAVE_USER, saveUser)
  yield takeEvery(UPDATE_USER, updateUser)
  yield takeEvery(DELETE_USER, deleteUser)
}

export default usersSaga
