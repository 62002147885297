import useSWR from "swr"
import { post } from "../helpers/api_helper"

export default function useArchives(type, startDate, endDate) {
  let path = "session"
  if (type === "User Chat History") {
    path = "chat"
  }
  const GET_ARCHIVES = `/archive/${path}?limit=10000&page=0`

  const filter = {
    startDate,
    endDate,
  }
  const { data, error, mutate} = useSWR([GET_ARCHIVES, type, startDate, endDate], () => post(GET_ARCHIVES, filter).then(res =>  res.data.data))
  return {
    loading:!data && !error ,
    error: error,
    data,
    revalidate: mutate
  }
}
