import { saveWelcomeImage } from "helpers/backend_helper"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { Card, CardBody, Col, Form, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const FormUpload = props => {
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const saveImage = () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData()
      formData.append("welcome", selectedFiles[0])
      formData.append("active", "true")
      saveWelcomeImage(formData)
        .then(response => {
          props.onSaveSuccess()
        })
        .catch(e => {
          toastr.error(
            e,
            "Error."
          )
        })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {/* <CardTitle>Dropzone</CardTitle> */}
              {/* <CardSubtitle className="mb-3">
                {" "}
                DropzoneJS is an open source library that provides drag’n’drop
                file uploads with image previews.
              </CardSubtitle> */}
              <Form>
                <Dropzone
                  maxSize={10 * 1024 * 1024}
                  maxFiles={1}
                  accept={["image/jpeg", "image/png", "image/gif"]}
                  onDrop={acceptedFiles => {
                    console.log(acceptedFiles)
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
              </Form>

              <div className="text-center mt-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveImage}
                >
                  Save File
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FormUpload
