import React from "react"
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import DepartmentMaster from "./DepartmentMaster"
import WelcomeMaster from "./WelcomeMaster"
import FAQMaster from "./FAQMaster"

const MasterData = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Master Data</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <DepartmentMaster />
            </Col>
            <Col>
              <WelcomeMaster />
            </Col>
          </Row>
          {/* <Row>
            <FAQMaster />
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MasterData
