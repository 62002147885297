const nameOf = obj => {
  return new Proxy(obj, {
    get(_, key) {
      return key
    },
  })
}

const getLocalStorage = key => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key))
  }
  return null
}

const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const formattingParagraph = inputText => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&#\/%?=~_|!:,.;]*[-A-Z0-9+&#\/%=~_|])/gim
  if (replacePattern1.test(inputText))
    inputText = inputText.replace(
      replacePattern1,
      '<a href="$1" target="_blank" style="color:blue">$1</a>'
    )

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  if (replacePattern2.test(inputText))
    inputText = inputText.replace(
      replacePattern2,
      '$1<a href="http://$2" target="_blank" style="color:blue">$2</a>'
    )

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
  if (replacePattern3.test(inputText))
    replacedText = inputText.replace(
      replacePattern3,
      '<a href="mailto:$1">$1</a>'
    )

  return inputText
}


export { nameOf, setLocalStorage, getLocalStorage, formattingParagraph }
