import "flatpickr/dist/themes/material_blue.css"
import { getCompoundInquiryDetails } from "helpers/backend_helper"
import jsPDF from "jspdf"
import "jspdf-autotable"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { CSVLink } from "react-csv"
import Flatpickr from "react-flatpickr"
import "react-image-lightbox/style.css"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import {
  getCompoundInquiries,
  getCompoundInquiriesCount,
} from "../../../store/actions"
import CompoundDetaileModal from "./CompoundDetaileModal"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import features from "../../../assets/images/crypto/features-img/img-1.png"

const CompoundInquiries = props => {
  const dispatch = useDispatch()
  const {
    compoundInquiries,
    inquiriesCount,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const compoundInquiries = [...state.inquiry.compoundInquiries]
    const inquiriesCount = state.inquiry.compoundInquiriesCount
    const loadingAvalability = state.inquiry.compoundInquiriesLoading
    const loading = state.inquiry.loading
    return { compoundInquiries, inquiriesCount, loadingAvalability, loading }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])
  const [modal, setModal] = useState(false)
  const [inquiry, setInquiry] = useState({})
  const [inquiryDetails, setInquiryDetails] = useState([])

  const columns = [
    {
      dataField: "createdAt",
      text: "Tansaction Date/Time",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "idNumber",
      text: "Id number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "vehicleNumber",
      text: "Vehicle number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.name",
      text: "User name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "Phone number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "numberOfRecords",
      text: "No of records",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "courtCount",
      text: "Court",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "overdueCount",
      text: "Arrears",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "warningCount",
      text: "Reminder",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "arrestWarrantCount",
      text: "Arrest/Blacklist",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "totalAmount",
      text: "Total amount",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "MYR",
          })

          return formatter.format(rowContent)
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      formatter: (rowContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            onClickViewButton(row)
          }}
        >
          View
        </Button>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: compoundInquiries.length, // replace later with size(customers),
    custom: true,
  }

  const headers = [columns.map(item => item.text)]
  headers[0].pop()

  const onClickViewButton = row => {
    getCompoundInquiryDetails(row.id)
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          setInquiryDetails(response.data.data)
        } else {
          setInquiryDetails([])
        }
        setInquiry(row)
        setModal(true)
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }

  useEffect(() => {
    dispatch(
      getCompoundInquiries({
        startDate: dateArray[0],
        endDate: dateArray[1],
      })
    )
    dispatch(getCompoundInquiriesCount())
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getCompoundInquiries({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      })
    )
  }

  const exportPDF = download => {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = "Compound Inquiries"
    const formatters = columns.map(item => item.formatter)
    const data = compoundInquiries.map(inquiry => [
      formatters[0](inquiry["createdAt"], inquiry),
      formatters[1](inquiry["idNumber"], inquiry),
      formatters[2](inquiry["vehicleNumber"], inquiry),
      formatters[3](inquiry["user"]["name"], inquiry),
      formatters[4](inquiry["user"]["phoneNumber"], inquiry),
      formatters[5](inquiry["numberOfRecords"], inquiry),
      formatters[6](inquiry["courtCount"], inquiry),
      formatters[7](inquiry["overdueCount"], inquiry),
      formatters[8](inquiry["warningCount"], inquiry),
      formatters[9](inquiry["arrestWarrantCount"], inquiry),
      formatters[10](inquiry["totalAmount"], inquiry),
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        fontSize: 7,
      },
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(`Compound Inquiries(${dateArray[0]}-${dateArray[1]}).pdf`)
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const formatters = columns.map(item => item.formatter)
  const detailsArrayOfArrays = compoundInquiries.map(inquiry => [
    formatters[0](inquiry["createdAt"], inquiry),
    formatters[1](inquiry["idNumber"], inquiry),
    formatters[2](inquiry["vehicleNumber"], inquiry),
    formatters[3](inquiry["user"]["name"], inquiry),
    formatters[4](inquiry["user"]["phoneNumber"], inquiry),
    formatters[5](inquiry["numberOfRecords"], inquiry),
    formatters[6](inquiry["courtCount"], inquiry),
    formatters[7](inquiry["overdueCount"], inquiry),
    formatters[8](inquiry["warningCount"], inquiry),
    formatters[9](inquiry["arrestWarrantCount"], inquiry),
    formatters[10](inquiry["totalAmount"], inquiry),
  ])
  const cvsData = headers.concat(detailsArrayOfArrays)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Compound Information Inquiry</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 className="mb-3">Compound Information Inquiry</h3>
          <CompoundDetaileModal
            isOpen={modal}
            close={() => setModal(false)}
            inquiry={inquiry}
            details={inquiryDetails}
          />
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <div>
                    <Row className={loadingAvalability ? "loading-parent" : ""}>
                      <Col lg="9" sm="8">
                        <Row className="mt-4">
                          <Col xl={12}>
                            <div className="text-center">
                              <h5 className="mb-4 text-primary">Summary</h5>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="text-lg-center mt-2">
                            <Row>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    Today
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.daily}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Week
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.weekly}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Month
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.monthly}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3" sm="4" className="align-self-center">
                        <div className="p-4">
                          <img
                            src={features}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={compoundInquiries}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={compoundInquiries}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col sm="5">
                                  <div className="text-sm-end">
                                    <div className="me-4 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <h5>
                                          Search Returned{" "}
                                          {compoundInquiries.length} Record(s)
                                        </h5>
                                      </div>
                                    </div>
                                    <CSVLink
                                      data={cvsData}
                                      filename={`Compound Inquiries(${dateArray[0]}-${dateArray[1]}).csv`}
                                      className="btn btn-primary me-2"
                                      target="_blank"
                                    >
                                      CSV
                                    </CSVLink>
                                    <Button
                                      className="me-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(true)
                                      }}
                                    >
                                      <i className="mdi mdi-download" />
                                    </Button>
                                    <Button
                                      className="me-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(false)
                                      }}
                                    >
                                      <i className="mdi mdi-printer" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Inquiries available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CompoundInquiries)
