import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_RATINGS, GET_RATINGS_COUNT } from "./actionTypes"
import { getRatingsSuccess, getRatingsCountSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getRatings, getRatingsCount } from "../../helpers/backend_helper"

function* fetchRatings({ payload }) {
  try {
    const response = yield call(getRatings, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getRatingsSuccess(response.data.data))
    } else {
      yield put(getRatingsSuccess([]))
    }
  } catch (error) {
    yield put(getRatingsSuccess([]))
  }
}

function* fetchRatingsCount({ payload }) {
  try {
    const response = yield call(getRatingsCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getRatingsCountSuccess(response.data.data))
    } else {
      yield put(getRatingsCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getRatingsCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}

function* ratingsSaga() {
  yield takeEvery(GET_RATINGS, fetchRatings)
  yield takeEvery(GET_RATINGS_COUNT, fetchRatingsCount)
}

export default ratingsSaga
