//LOGIN
export const POST_AGENT_LOGIN = "/agent/login"
export const GET_PROFILE = "/agent/profile/get"
export const UPDATE_PROFILE = "/agent/profile/update"

//USERS
export const GET_AGENTS = "/agent/all"
export const GET_CC_AGENTS = GET_AGENTS + "/callCenterAgents"
export const GET_AGENT = "/agent/"
export const ADD_AGENT = "/agent/add"
export const UPDATE_AGENT = "/agent/"
export const DELETE_AGENT = "/agent/remove/"

//CHATS
export const GET_ACTIVE_CHATS = "/customer/activeChat/byagent"
export const GET_INACTIVE_CHATS = "/customer/offlineChat/byagent"
export const GET_MESSAGES = "/customer/chat/foragent/"
export const ADD_MESSAGE = "/add/messages"

//NOTIFICATIONS
export const GET_NOTIFCATIONS = "/notification/byAgent"
export const PATCH_MARK_AS_READ_NOTIFCATION = "/notification/markAsRead/"
export const PATCH_MARK_ALL_AS_READ_NOTIFCATION =
  "/notification/self/markAllAsRead"

// MASTER DETAILS
export const GET_STATES = "/masterData/agent_status"
export const GET_AVAILABILITIES = "/masterData/agent_availability"
export const GET_USER_TYPES = "/masterData/user_type"
export const GET_RATING_TYPES = "/masterData/rating_type_master"
export const GET_DEPARTMENTS = "/masterData/department_master"
export const ADD_DEPARTMENT = "/masterData/department/add"
export const PATCH_OR_DELETE_DEPARTMENT = id => {
  return "/masterData/department/" + id
}

export const GET_REPORT_MODULE_TYPES = "/masterData/report_module_master"
export const GET_REPORT_TRANSACTION_TYPES =
  "/masterData/report_transaction_master"

// RATINGS
export const GET_RATINGS = (type, rating) => {
  return "/rating/all/" + type + "/" + rating + "?limit=10000&page=0"
}
export const GET_RATINGS_COUNT = "/rating/count"

export const GET_COMPLAINTS = "/complaint/all?limit=10000&page=0"
export const GET_COMPLAINTS_COUNT = "/complaint/count"
export const GET_COMPLAINTS_FILE = (id, type) => {
  return "/complaint/file/" + id + "/" + type
}

export const GET_ARCHIVES = "/archive/session?limit=10000&page=0"
export const ARCHIVE_CHATS = "/archive/chat/archive"
export const ARCHIVE_SESSIONS = "/archive/session/archive"

export const GET_TABLES = "/masterData/archive_table_list_master"

export const GET_ALL_CUSTOMER_AGENT_AUDITS = "/customer/agent/audit/all"
export const GET_ALL_AUDIT_USER_CHATBOT_SESSIONS = "/customer/chatbot/audit/all"

export const DASHBOARD_LIST = "/dashboard/list"

export const GET_INQUIRIES = "/inquiry/all?limit=10000&page=0"
export const GET_INQUIRIES_COUNT = "/inquiry/all/count"
export const GET_BUSINESS_INQUIRIES = "/inquiry/business/all?limit=10000&page=0"
export const GET_BUSINESS_INQUIRIES_COUNT = "/inquiry/business/all/count"
export const GET_COMPOUND_INQUIRIES = "/inquiry/compound/all?limit=10000&page=0"
export const GET_COMPOUND_INQUIRIES_COUNT = "/inquiry/compound/all/count"
export const GET_CHATBOT_SESSION_COUNT = "/customer/chatbot/chat/count"
export const GET_LIVE_CHAT_SESSION_COUNT = "/customer/agent/chat/count"
export const GET_COMPOUND_INQUIRY_DETAILS = id => {
  return "/inquiry/compound/detail/" + id
}
export const GET_ASSESSMENT_TAX_INQUIRIES =
  "/inquiry/assessmenttax/all?limit=10000&page=0"
export const GET_ASSESSMENT_TAX_INQUIRIES_COUNT =
  "/inquiry/assessmenttax/all/count"

export const GET_ASSESSMENT_TAX_INQUIRY_DETAILS = id => {
  return "/inquiry/assessmenttax/detail/" + id
}

export const GET_CUSTOMER_AGENT_AUDIT_DETAIL = id => {
  return "/customer/agent/audit/detail/" + id
}

export const GET_ALL_USERS = "/masterData/user"
export const GET_ALL_AGENTS = "/masterData/agent"

export const GET_WELCOMES = "/masterData/welcome/all"
export const PATCH_WELCOME = "/masterdata/welcome/status"
export const DELETE_WELCOME = "/masterdata/welcome/remove"
export const SAVE_WELCOME = "/masterData/welcome/fileupload"

export const GET_FAQS = "/masterdata/FAQ/all?limit=10000&page=0"
export const DELETE_FAQ = "/masterdata/FAQ/remove"
export const SAVE_FAQ = "/masterData/FAQ/add"
export const PATCH_FAQ = id => {
  return "/masterData/FAQ/update/" + id
}

export const MODULE_MASTER = "/masterData/agent_module_master"
export const TRANSACTION_MASTER = "/masterData/agent_transaction_master"
export const AGENT_AUDITS = "/agentaudit/all?limit=10000&page=0"

export const GET_PRESCRIPT_MODULES = "/masterData/prescript/module/all"
export const POST_PRESCRIPT_MODULE = "/masterData/prescript/module/add"
export const PATCH_PRESCRIPT_MODULE = id =>
  "/masterData/prescript/module/update/" + id
export const DELETE_PRESCRIPT_MODULE = "/masterData/prescript/module/remove"

export const GET_PRESCRIPT_TITLES = "/masterData/prescript/title/all"
export const POST_PRESCRIPT_TITLE = "/masterData/prescript/title/add"
export const PATCH_PRESCRIPT_TITLE = id =>
  "/masterData/prescript/title/update/" + id
export const DELETE_PRESCRIPT_TITLE = "/masterData/prescript/title/remove"

export const GET_PRESCRIPT_TEXTS = "/masterData/prescript/all"
export const POST_PRESCRIPT_TEXT = "/masterData/prescript/add"
export const PATCH_PRESCRIPT_TEXT = id => "/masterData/prescript/update/" + id
export const DELETE_PRESCRIPT_TEXT = "/masterData/prescript/remove"

export const GET_ALL_PRESCRIPTS = lang =>
  "/masterData/prescript/agent/all/" + lang
