import { getDepartmentMaster, getUserTypeMaster } from "helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  deleteUser,
  getUser,
  getUsers,
  removeUserFromRedux, setDepartmentsToRedux, setUserTypesToRedux
} from "../../../store/actions"
import UserCrupdate from "./UserCrupdate"
import { getLocalStorage } from "helpers/common_functions"
import moment from "moment"
import GridDownload from 'components/GridDownLoad';

const Users = props => {
  const dispatch = useDispatch()
  const {users, loadingAvalability } = useSelector(state => {
    const loadingAvalability = state.users.loadingAvalability
    const users = [...state.users.users]
    return {users, loadingAvalability}
  })
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState(0)
  const [userPermissionType, setUserPermissionType] = useState(0)

  useEffect(() => {
    getUserTypeMaster()
      .then(response => {
        if (response && response.status === 200) {
          dispatch(setUserTypesToRedux(response.data.data))
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }, [])

  useEffect(() => {
    getDepartmentMaster().then(response => {
      if (response && response.status === 200) {
        dispatch(setDepartmentsToRedux(response.data.data))
      }
    })
  }, [])

  const columns = [
    {
      dataField: "ic",
      text: "IC",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    // {
    //   dataField: "phoneNumber",
    //   text: "Phone Number",
    //   sort: true,
    // },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    // },
    {
      dataField: "department_master.title",
      text: "Department",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "button",
      text: "Actions",
      formatter: (rowContent, row) => {
        return (
          <div className="d-flex gap-3">
            <Link
              className="text-success"
              to="#"
              onClick={() => {
                dispatch(removeUserFromRedux())
                dispatch(getUser(row.id))
                setIsEdit(true)
                toggle()
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
            </Link>
            <Link
              className="text-danger"
              to="#"
              onClick={() => onDeleteUser(row.id)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
            </Link>
          </div>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: users.length, // replace later with size(customers),
    custom: true,
  }

  const onDeleteUser = id => {
    setDeleteUserId(id)
    setDeleteConfirmAlert(true)
  }

  useEffect(() => {
    const authUser = getLocalStorage("authUser")
    setUserPermissionType(authUser?.permissionLevel)
    // if user's permissionLevel === 2, show only callCenterAgents, not the all users list
    dispatch(getUsers(authUser?.permissionLevel))
  }, [])

  const toggle = () => {
    setModal(!modal)
    if (modal) dispatch(getUsers(userPermissionType))
  }

  const { SearchBar } = Search;

  const [dateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>User Updates</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>List User</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={users}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">

                                  <div className="me-4 mb-2 d-inline-block">
                                  <div className="position-relative">
                                  <h5>Search Returned {users.length} Record(s)</h5>
                                  </div>
                                </div>
                                  <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary me-2"
                                    onClick={() => {
                                      dispatch(removeUserFromRedux())
                                      setIsEdit(false)
                                      toggle()
                                    }}
                                  >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Create New User
                                  </Button>

                                  <GridDownload title="List Users" dateArray={dateArray} columns={columns} data={users} />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  <Modal isOpen={modal} toggle={toggle}>
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit User" : "Add User"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <UserCrupdate
                                        isEdit={isEdit}
                                        onSaveSuccess={() => {
                                          toggle()
                                          toastr.success(
                                            "",
                                            "Successfuly saved."
                                          )
                                        }}
                                      />
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>}
            { loadingAvalability && <div id="preloader">
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                </div>
              </div>
            </div>}
          </Row>
          {deleteConfirmAlert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                if (deleteUserId && deleteUserId > 0) {
                  setDeleteConfirmAlert(false)
                  dispatch(deleteUser(deleteUserId))
                }
              }}
              onCancel={() => setDeleteConfirmAlert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Users)

Users.propTypes = {
  users: PropTypes.array,
  error: PropTypes.any,
  history: PropTypes.object,
}
