import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link, withRouter } from "react-router-dom"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  getAuditUserSessions,
  getBusinessInquiries,
  getInquiries,
  getBusinessInquiriesCount,
} from "../../../store/actions"
import moment from "moment"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import "react-image-lightbox/style.css"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { CSVLink } from "react-csv"
import features from "../../../assets/images/crypto/features-img/img-1.png"

const BusinessInquiries = props => {
  const dispatch = useDispatch()
  const {
    businessInquiries,
    inquiriesCount,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const businessInquiries = [...state.inquiry.businessInquiries]
    const inquiriesCount = state.inquiry.businessInquiriesCount
    const loadingAvalability = state.inquiry.businessInquiriesLoading
    const loading = state.inquiry.loading

    return { businessInquiries, inquiriesCount, loadingAvalability, loading }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "user.name",
      text: "User Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "user.phoneNumber",
      text: "Phone Number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "statusMessage",
      text: "Status Message",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "fileNumber",
      text: "File number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "idNumber",
      text: "Id number",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "business_license_id_type_master.code",
      text: "Id type",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "licenseCategory",
      text: "License Type",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "startDate",
      text: "Start Date",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "endDate",
      text: "End Date",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "statusLicense",
      text: "Status License",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
  ]

  const headers = [columns.map(item => item.text)]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: businessInquiries.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    dispatch(
      getBusinessInquiries({
        startDate: dateArray[0],
        endDate: dateArray[1],
      })
    )
    dispatch(getBusinessInquiriesCount())
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getBusinessInquiries({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      })
    )
  }

  const exportPDF = download => {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = "Business Inquiries"
    const formatters = columns.map(item => item.formatter)
    const data = businessInquiries.map(inquiry => [
      formatters[0](inquiry["createdAt"], inquiry),
      formatters[1](inquiry["user"]["name"], inquiry),
      formatters[2](inquiry["user"]["phoneNumber"], inquiry),
      formatters[3](inquiry["statusMessage"], inquiry),
      formatters[4](inquiry["fileNumber"], inquiry),
      formatters[5](inquiry["idNumber"], inquiry),
      formatters[6](
        inquiry["business_license_id_type_master"]["code"],
        inquiry
      ),
      formatters[7](inquiry["accountNumber"], inquiry),
      formatters[8](inquiry["statusLicense"], inquiry),
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        fontSize: 7,
      },
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(`Business Inquiries(${dateArray[0]}-${dateArray[1]}).pdf`)
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const formatters = columns.map(item => item.formatter)
  const detailsArrayOfArrays = businessInquiries.map(inquiry => {
    return [
      formatters[0](inquiry["createdAt"], inquiry),
      formatters[1](inquiry["user"]["name"], inquiry),
      formatters[2](inquiry["user"]["phoneNumber"], inquiry),
      formatters[3](inquiry["statusMessage"], inquiry),
      formatters[4](inquiry["fileNumber"], inquiry),
      formatters[5](inquiry["idNumber"], inquiry),
      formatters[6](
        inquiry["business_license_id_type_master"]["code"],
        inquiry
      ),
      formatters[7](inquiry["accountNumber"], inquiry),
      formatters[8](inquiry["statusLicense"], inquiry),
    ]
  })
  const cvsData = headers.concat(detailsArrayOfArrays)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Business License Inquiry</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3 className="mb-3">Business License Inquiry</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <div>
                    <Row>
                      <Col lg="9" sm="8">
                        <Row className="mt-4">
                          <Col xl={12}>
                            <div className="text-center">
                              <h5 className="mb-4 text-primary">Summary</h5>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="text-lg-center mt-2">
                            <Row>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    Today
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.daily}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Week
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.weekly}
                                  </h5>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <p className="text-muted text-truncate mb-2">
                                    This Month
                                  </p>
                                  <h5 className="mb-0">
                                    {inquiriesCount.monthly}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3" sm="4" className="align-self-center">
                        <div className="p-4">
                          <img
                            src={features}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={businessInquiries}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={businessInquiries}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>
                                <Col sm="5">
                                  <div className="text-sm-end">
                                    <div className="me-4 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <h5>
                                          Search Returned{" "}
                                          {businessInquiries.length} Record(s)
                                        </h5>
                                      </div>
                                    </div>
                                    <CSVLink
                                      data={cvsData}
                                      filename={`Business Inquiries(${dateArray[0]}-${dateArray[1]}).csv`}
                                      className="btn btn-primary me-2"
                                      target="_blank"
                                    >
                                      CSV
                                    </CSVLink>
                                    <Button
                                      className="me-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(true)
                                      }}
                                    >
                                      <i className="mdi mdi-download" />
                                    </Button>
                                    <Button
                                      className="me-2"
                                      color="primary"
                                      onClick={() => {
                                        exportPDF(false)
                                      }}
                                    >
                                      <i className="mdi mdi-printer" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Inquiries available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(BusinessInquiries)
