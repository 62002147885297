import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_STATE,
  UPDATE_CURRENT_USER_AVAILABILITY,
  UPDATE_CURRENT_USER_STATE_SUCCESS,
  UPDATE_CURRENT_USER_AVAILABILITY_SUCCESS,
  SET_CURRENT_USER_STATE_SUCCESS,
  SET_CURRENT_USER_AVAILABILITY_SUCCESS,
  REMOVE_API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  loginSuccess: false,
  profile: null,
  loadingState: false,
  loadingAvalability: false,
  stateUpdateSuccess: false,
  availabilityUpdateSuccess: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        loginSuccess: true,
      }
      break
    case LOGOUT_USER:
      state = {
        ...state,
        loginSuccess: false,
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        loginSuccess: false,
      }
      break
    case REMOVE_API_ERROR:
      state = {
        ...state,
        error: null,
      }
      break
    case GET_CURRENT_USER_SUCCESS:
      state = {
        ...state,
        profile: action.payload,
        loading: false,
        loadingState: false,
        loadingAvalability: false,
      }
      break
    case UPDATE_CURRENT_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_CURRENT_USER_STATE:
      state = {
        ...state,
        loadingState: true,
      }
      break
    case UPDATE_CURRENT_USER_AVAILABILITY:
      state = {
        ...state,
        loadingAvalability: true,
      }
      break
    case UPDATE_CURRENT_USER_STATE_SUCCESS:
      state = {
        ...state,
        stateUpdateSuccess: true,
      }
      break
    case UPDATE_CURRENT_USER_AVAILABILITY_SUCCESS:
      state = {
        ...state,
        availabilityUpdateSuccess: true,
      }
      break
    case SET_CURRENT_USER_STATE_SUCCESS:
      state = {
        ...state,
        stateUpdateSuccess: action.payload,
      }
      break
    case SET_CURRENT_USER_AVAILABILITY_SUCCESS:
      state = {
        ...state,
        availabilityUpdateSuccess: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
