import {
  GET_USER,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  SET_USER,
  REMOVE_USER,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  SAVE_USER_FAIL,
  REMOVE_USER_FROM_REDUX,
  SET_USER_UPDATED,
  SET_USER_SAVED,
  UPDATE_USER_FAIL,
  SET_ERROR,
  GET_CC_AGENTS,
} from "./actionTypes"

export const setError = () => ({
  type: SET_ERROR,
})

export const getUsers = (param) => ({
  type: param && param === 2 ? GET_CC_AGENTS : GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUser = id => ({
  type: GET_USER,
  payload: id,
})

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const setUser = user => ({
  type: SET_USER,
  payload: user,
})

export const removeUserFromRedux = () => ({
  type: REMOVE_USER_FROM_REDUX,
})

export const saveUser = user => ({
  type: SAVE_USER,
  payload: user,
})

export const saveUserSuccess = response => ({
  type: SAVE_USER_SUCCESS,
  payload: response,
})

export const saveUserFail = response => ({
  type: SAVE_USER_FAIL,
  payload: response,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = response => ({
  type: UPDATE_USER_SUCCESS,
  payload: response,
})

export const updateUserFail = response => ({
  type: UPDATE_USER_FAIL,
  payload: response,
})

export const deleteUser = id => ({
  type: DELETE_USER,
  payload: id,
})

export const deleteUserSuccess = response => ({
  type: DELETE_USER_SUCCESS,
  payload: response,
})

export const setUserSaved = bool => ({
  type: SET_USER_SAVED,
  payload: bool,
})

export const setUserUpdated = bool => ({
  type: SET_USER_UPDATED,
  payload: bool,
})
