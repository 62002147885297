import {
  GET_ASSESSMENT_TAX_INQUIRIES_SUCCESS,
  GET_BUSINESS_INQUIRIES_SUCCESS,
  GET_COMPOUND_INQUIRIES_SUCCESS,
  GET_INQUIRIES_SUCCESS,
  GET_INQUIRIES_COUNT_SUCCESS,
  GET_BUSINESS_INQUIRIES_COUNT_SUCCESS,
  GET_COMPOUND_INQUIRIES_COUNT_SUCCESS,
  GET_ASSESSMENT_TAX_INQUIRIES_COUNT_SUCCESS,
  GET_INQUIRIES,
  GET_BUSINESS_INQUIRIES,
  GET_COMPOUND_INQUIRIES,
  GET_ASSESSMENT_TAX_INQUIRIES,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  inquiries: [],
  inquiriesCount: {},
  businessInquiries: [],
  businessInquiriesCount: {},
  compoundInquiries: [],
  compoundInquiriesLoading: true,
  compoundInquiriesCount: {},
  assessmentTaxInquiries: [],
  assessmentTaxInquiriesCount: {},
  assessmentTaxInquiriesLoading: true,
  inquiriesLoading: true,
  businessInquiriesLoading: true,
}

const inquiry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INQUIRIES:
    case GET_BUSINESS_INQUIRIES:
    case GET_COMPOUND_INQUIRIES:
    case GET_ASSESSMENT_TAX_INQUIRIES:
      return {
        ...state,
        loading: true,
      }
    case GET_INQUIRIES_SUCCESS:
      return {
        ...state,
        inquiries: action.payload ?? [],
        loading: false,
      }
    case GET_INQUIRIES_COUNT_SUCCESS:
      return {
        ...state,
        inquiriesCount: action.payload ?? [],
        inquiriesLoading: false,
      }
    case GET_BUSINESS_INQUIRIES_SUCCESS:
      return {
        ...state,
        businessInquiries: action.payload ?? [],
        businessInquiriesLoading: false,
        loading: false,
      }
    case GET_BUSINESS_INQUIRIES_COUNT_SUCCESS:
      return {
        ...state,
        businessInquiriesCount: action.payload ?? [],
      }
    case GET_COMPOUND_INQUIRIES_SUCCESS:
      return {
        ...state,
        compoundInquiries: action.payload ?? [],
        compoundInquiriesLoading: false,
        loading: false,
      }
    case GET_COMPOUND_INQUIRIES_COUNT_SUCCESS:
      return {
        ...state,
        compoundInquiriesCount: action.payload ?? [],
      }
    case GET_ASSESSMENT_TAX_INQUIRIES_SUCCESS:
      return {
        ...state,
        assessmentTaxInquiries: action.payload ?? [],
        assessmentTaxInquiriesLoading: false,
        loading: false,
      }
    case GET_ASSESSMENT_TAX_INQUIRIES_COUNT_SUCCESS:
      return {
        ...state,
        assessmentTaxInquiriesCount: action.payload ?? [],
      }

    default:
      return state
  }
}

export default inquiry
