import { del, get, patch, post, upload } from "./api_helper"
import * as url from "./url_helper"

const postLogin = data => post(url.POST_AGENT_LOGIN, data)

const getProfile = data => get(url.GET_PROFILE)

const patchProfile = data => patch(url.UPDATE_PROFILE, data)

const getUsers = () => get(url.GET_AGENTS)

const getCCAgents = () => get(url.GET_CC_AGENTS)

const getUser = id => get(url.GET_AGENT + id)

const postUser = data => post(url.ADD_AGENT, data)

const patchUser = data => patch(url.UPDATE_AGENT + data.id, data)

const delUser = id => del(url.DELETE_AGENT + id)

const getActiveChats = () => get(url.GET_ACTIVE_CHATS)

const getInactiveChats = () => get(url.GET_INACTIVE_CHATS)

const getMessages = sessionId => get(url.GET_MESSAGES + sessionId)

const addMessage = message => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    })
  })
}

const getNotifications = () => get(url.GET_NOTIFCATIONS)

const patchMarkAsReadNotification = id =>
  patch(url.PATCH_MARK_AS_READ_NOTIFCATION + id)

const patchMarkAllAsReadNotification = id =>
  patch(url.PATCH_MARK_ALL_AS_READ_NOTIFCATION)

const getStateMaster = () => get(url.GET_STATES)

const getAvailabilityMaster = () => get(url.GET_AVAILABILITIES)

const getUserTypeMaster = () => get(url.GET_USER_TYPES)

const getRatingTypeMaster = () => get(url.GET_RATING_TYPES)

const getModuleMaster = () => get(url.GET_REPORT_MODULE_TYPES)

const getTransactionTypeMaster = () => get(url.GET_REPORT_TRANSACTION_TYPES)

const getDepartmentMaster = () => get(url.GET_DEPARTMENTS)

const saveDepartment = department => post(url.ADD_DEPARTMENT, department)

const patchDepartment = department =>
  patch(url.PATCH_OR_DELETE_DEPARTMENT(department.id), department)

const deleteDepartment = id => del(url.PATCH_OR_DELETE_DEPARTMENT(id))

const getRatings = ({ pathParams, bodyParams }) =>
  post(url.GET_RATINGS(pathParams.type, pathParams.rating), bodyParams)
const getRatingsCount = () => get(url.GET_RATINGS_COUNT)

const getComplaints = filter => post(url.GET_COMPLAINTS, filter)
const getComplaintsCount = filter => get(url.GET_COMPLAINTS_COUNT, filter)

const getArchives = filter => post(url.GET_ARCHIVES, filter)

const archiveChats = filter => post(url.ARCHIVE_CHATS, filter)
const archiveSessions = filter => post(url.ARCHIVE_SESSIONS, filter)

const getTables = () => get(url.GET_TABLES)

const getAllAuditUserSessions = params =>
  post(url.GET_ALL_AUDIT_USER_CHATBOT_SESSIONS, params)

const getCustomerAgentAudits = params =>
  post(url.GET_ALL_CUSTOMER_AGENT_AUDITS, params)

const getDashboardList = bodyParams => post(url.DASHBOARD_LIST, bodyParams)

const getInquiries = filter => post(url.GET_INQUIRIES, filter)
const getInquiriesCount = filter => get(url.GET_INQUIRIES_COUNT)

const getBusinessInquiries = filter => post(url.GET_BUSINESS_INQUIRIES, filter)
const getBusinessInquiriesCount = filter =>
  get(url.GET_BUSINESS_INQUIRIES_COUNT, filter)

const getCompoundInquiries = filter => post(url.GET_COMPOUND_INQUIRIES, filter)
const getCompoundInquiriesCount = filter =>
  get(url.GET_COMPOUND_INQUIRIES_COUNT, filter)

const getChatbotSessionCount = filter =>
  get(url.GET_CHATBOT_SESSION_COUNT, filter)
const getLiveChatSessionCount = filter =>
  get(url.GET_LIVE_CHAT_SESSION_COUNT, filter)

const getCompoundInquiryDetails = id =>
  get(url.GET_COMPOUND_INQUIRY_DETAILS(id))

const getAssessmentTaxInquiries = filter =>
  post(url.GET_ASSESSMENT_TAX_INQUIRIES, filter)
const getAssessmentTaxInquiriesCount = filter =>
  get(url.GET_ASSESSMENT_TAX_INQUIRIES_COUNT, filter)

const getAssessmentTaxDetails = id =>
  get(url.GET_ASSESSMENT_TAX_INQUIRY_DETAILS(id))

const getCustomerAgentAuditDetail = id =>
  get(url.GET_CUSTOMER_AGENT_AUDIT_DETAIL(id))

const getAllUsers = () => get(url.GET_ALL_USERS)

const getAllAgents = () => get(url.GET_ALL_AGENTS)

const getWelcomes = () => get(url.GET_WELCOMES)

const patchWelcome = welcome => patch(url.PATCH_WELCOME, welcome)

const deleteWelcome = image => del(url.DELETE_WELCOME, { data: image })

const saveWelcomeImage = data => upload(url.SAVE_WELCOME, data)

const getFAQs = () => get(url.GET_FAQS)

const deleteFQA = faq => del(url.DELETE_FAQ, { data: faq })

const saveFAQ = data => upload(url.SAVE_FAQ, data)

const patchFAQ = faq => patch(url.PATCH_FAQ(faq.id), faq)

const getAgentModuleMaster = () => get(url.MODULE_MASTER)

const getAgentTransactionMaster = () => get(url.TRANSACTION_MASTER)

const getAgentAudits = data => post(url.AGENT_AUDITS, data)

const getPrescriptModules = () => get(url.GET_PRESCRIPT_MODULES)

const addPrescriptModule = module => post(url.POST_PRESCRIPT_MODULE, module)

const updatePrescriptModule = module =>
  patch(url.PATCH_PRESCRIPT_MODULE(module.id), module)

const deletePrescriptModule = data =>
  del(url.DELETE_PRESCRIPT_MODULE, { data: data })

const getPrescriptTitles = () => get(url.GET_PRESCRIPT_TITLES)

const addPrescriptTitle = module => post(url.POST_PRESCRIPT_TITLE, module)

const updatePrescriptTitle = module =>
  patch(url.PATCH_PRESCRIPT_TITLE(module.id), module)

const deletePrescriptTitle = data =>
  del(url.DELETE_PRESCRIPT_TITLE, { data: data })

const getPrescriptTexts = () => get(url.GET_PRESCRIPT_TEXTS)

const addPrescriptText = module => post(url.POST_PRESCRIPT_TEXT, module)

const updatePrescriptText = module =>
  patch(url.PATCH_PRESCRIPT_TEXT(module.id), module)

const deletePrescriptText = data =>
  del(url.DELETE_PRESCRIPT_TEXT, { data: data })

const getAllPerscripts = lang => get(url.GET_ALL_PRESCRIPTS(lang))

export {
  postLogin,
  getUsers,
  getUser,
  postUser,
  patchUser,
  delUser,
  getActiveChats,
  getInactiveChats,
  getMessages,
  addMessage,
  getProfile,
  getNotifications,
  patchMarkAsReadNotification,
  patchMarkAllAsReadNotification,
  getStateMaster,
  getAvailabilityMaster,
  getUserTypeMaster,
  getRatingTypeMaster,
  getDepartmentMaster,
  saveDepartment,
  patchDepartment,
  deleteDepartment,
  patchProfile,
  getRatings,
  getComplaints,
  getArchives,
  archiveChats,
  archiveSessions,
  getTables,
  getAllAuditUserSessions,
  getCustomerAgentAudits,
  getDashboardList,
  getInquiries,
  getBusinessInquiries,
  getCompoundInquiries,
  getCompoundInquiryDetails,
  getAssessmentTaxInquiries,
  getAssessmentTaxDetails,
  getModuleMaster,
  getTransactionTypeMaster,
  getCustomerAgentAuditDetail,
  getAllUsers,
  getAllAgents,
  getWelcomes,
  patchWelcome,
  deleteWelcome,
  saveWelcomeImage,
  getFAQs,
  deleteFQA,
  saveFAQ,
  patchFAQ,
  getAgentModuleMaster,
  getAgentTransactionMaster,
  getAgentAudits,
  getPrescriptModules,
  addPrescriptModule,
  updatePrescriptModule,
  deletePrescriptModule,
  getPrescriptTitles,
  addPrescriptTitle,
  updatePrescriptTitle,
  deletePrescriptTitle,
  getPrescriptTexts,
  addPrescriptText,
  updatePrescriptText,
  deletePrescriptText,
  getAllPerscripts,
  getCCAgents,
  getInquiriesCount,
  getBusinessInquiriesCount,
  getCompoundInquiriesCount,
  getAssessmentTaxInquiriesCount,
  getChatbotSessionCount,
  getRatingsCount,
  getLiveChatSessionCount,
  getComplaintsCount,
}
