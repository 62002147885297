import {
  GET_RATINGS_COUNT_SUCCESS,
  GET_RATINGS_SUCCESS,
  GET_RATINGS,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  ratings: [],
  ratingsCount: {},
  loadingAvalability: true,
}

const ratings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RATINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_RATINGS_SUCCESS:
      return {
        ...state,
        ratings: action.payload ?? [],
        loadingAvalability: false,
        loading: false,
      }
    case GET_RATINGS_COUNT_SUCCESS:
      return {
        ...state,
        ratingsCount: action.payload ?? {},
      }
    default:
      return state
  }
}

export default ratings
