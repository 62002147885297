import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router"
import { nameOf } from "../../../../helpers/common_functions"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  saveUser,
  setUser,
  updateUser,
  setUserSaved,
  setUserUpdated,
  setError,
} from "../../../../store/actions"
import _ from "lodash"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import md5 from "md5"
import { Link } from "react-router-dom"

class AgentModel {
  constructor(obj) {
    if (obj != null) {
      Object.assign(this, obj)
    } else {
      this.id = 0
      this.staffId = ""
      this.name = ""
      this.ic = ""
      this.department = ""
      // this.designation = ""
      this.password = ""
      this.status = 1
      // this.availability = ""
      // this.phoneNumber = ""
      this.email = null
      // this.type = ""
      this.permissionLevel = 1
      // this.description = ""
    }
  }
}

const UserCrupdate = props => {
  const {
    user,
    error,
    loading,
    states,
    availabilities,
    userUpdated,
    userSaved,
    userTypes,
    departments,
    updateError,
  } = useSelector(state => {
    let { user, userUpdated, userSaved } = state.users
    const { states, availabilities, userTypes, departments } = state.app
    const hasKeys = !!Object.keys(user).length
    user = hasKeys ? new AgentModel(user) : new AgentModel()
    const { error, loading, updateError } = state.users
    return {
      user,
      error,
      loading,
      states,
      availabilities,
      userUpdated,
      userSaved,
      userTypes,
      departments,
      updateError,
    }
  })
  const dispatch = useDispatch()
  const [disablePassword, setDisablePassword] = useState(props.isEdit)
  const [password, setPassword] = useState("")

  useEffect(() => {
    if (userUpdated) {
      dispatch(setUserUpdated(false))
      toastr.success("", "Successfuly updated.")
    }
  }, [userUpdated])

  useEffect(() => {
    if (userSaved) {
      props.onSaveSuccess()
    }
    return () => {
      if (userSaved) {
        dispatch(setUserSaved(false))
      }
    }
  }, [userSaved])

  useEffect(() => {
    if (error && typeof error === "string") {
      setInterval(() => {
        dispatch(setError())
      }, 5000)
    }
  }, [error])

  const valueOnChange = event => {
    dispatch(setUser({ ...user, [event.target.name]: event.target.value }))
  }

  const handleValidSubmit = (event, values) => {
    let localUser = user
    localUser.password = md5(localUser.password)
    if (disablePassword) {
      delete localUser.password
    }
    if (user.id === 0) {
      dispatch(saveUser(localUser))
    } else {
      dispatch(updateUser(localUser))
    }
  }

  return (
    <React.Fragment>
      <AvForm
        model={new AgentModel()}
        onValidSubmit={handleValidSubmit}
        className="needs-validation"
      >
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-firstname-Input"
            className="col-sm-3 col-form-label"
          >
            Staff Id
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-firstname-Input"
                name={nameOf(user).staffId}
                value={user.staffId}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Staff Id" },
                  maxLength: {
                    value: 20,
                    errorMessage: "Your staff id must be below 20 characters",
                  },
                }}
                disabled={user.id !== 0 ? true : false}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-email-Input"
            className="col-sm-3 col-form-label"
          >
            Name
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-email-Input"
                name={nameOf(user).name}
                value={user.name}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Name" },
                }}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            IC
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-ic-Input"
                name={nameOf(user).ic}
                value={user.ic}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter IC" },
                  maxLength: {
                    value: 12,
                    errorMessage: "Your ic must be below 12 characters",
                  },
                  minLength: {
                    value: 12,
                    errorMessage: "Your ic must be 12 characters",
                  },

                }}
                disabled={user.id !== 0 ? true : false}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Department
          </Label>
          <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(user).department}
              value={user.department}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                {"SELECT DEPARTMENT"}
              </option>
              {departments.map(a => {
                return (
                  <option key={a.id} value={a.id}>
                    {a.title}
                  </option>
                )
              })}
            </AvField>
          </Col>
        </div>
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Designation
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-designation-Input"
                name={nameOf(user).designation}
                value={user.designation}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Designation" },
                  maxLength: {
                    value: 20,
                    errorMessage:
                      "Your designation must be below 20 characters",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Password
          </Label>
          <Col sm={props.isEdit ? 8 : 9}>
            <FormGroup>
              <AvField
                disabled={disablePassword}
                type={disablePassword ? "password" : "text"}
                className="form-control"
                id="horizontal-password-Input"
                name={nameOf(user).password}
                value={user.password}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Password" },
                  minLength: {
                    value: 6,
                    errorMessage:
                      "Your password must be between 6 and 20 characters",
                  },
                  maxLength: {
                    value: 35,
                    errorMessage:
                      "Your password must be between 6 and 35 characters",
                  },
                }}
              />
            </FormGroup>
          </Col>
          {props.isEdit ?
            <Col sm={1} style={{ alignSelf: "center" }}>
              <div>
                <Link
                  className="text-success"
                  to="#"
                  onClick={() => {
                    if (disablePassword) {
                      setPassword(user.password)
                      dispatch(setUser({ ...user, password: "" }))
                    } else {
                      dispatch(setUser({ ...user, password: password }))
                    }
                    setDisablePassword(!disablePassword)

                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
                </Link>
              </div>
            </Col> : null}
        </div>
        {/* <div className="row mb-4"> */}
          {/* <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Status
          </Label> */}
          {/* <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(user).status}
              value={user.status}
              onChange={valueOnChange}
              required
              type="select"
              style={{
                width: "100%",
                position: "relative",
                left: 0,
                top: 0,
                margin: "0 0 0 0",
              }}
            >
              <option key={0} value={""}>
                {"SELECT STATUS"}
              </option>
              {states.map(a => {
                return (
                  <option key={a.id} value={a.id}>
                    {a.type_code}
                  </option>
                )
              })}
            </AvField>
          </Col> */}
          {/* <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(user).status}
              value={user.status}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                {"SELECT STATUSKJBKJB"}
              </option>
              {states.map(a => {
                return (
                  <option key={a.id} value={a.id}>
                    {a.type_code}
                  </option>
                )
              })}
            </AvField>
          </Col> */}
        {/* </div> */}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Availability
          </Label>
          <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(user).availability}
              value={user.availability}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                {"SELECT AVAILABILITY"}
              </option>
              {availabilities.map(a => {
                return (
                  <option key={a.id} value={a.id}>
                    {a.type_code}
                  </option>
                )
              })}
            </AvField>
          </Col>
        </div>
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Phone No
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-phone-number-Input"
                name={nameOf(user).phoneNumber}
                value={user.phoneNumber}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Phone No" },
                  number: true,
                  minLength: {
                    value: 10,
                    errorMessage: "Your mobile number must be 10 digits",
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: "Your mobile number must be 10 digits",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Email
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="email"
                className="form-control"
                id="horizontal-email-Input"
                name={nameOf(user).email}
                value={user.email}
                onChange={valueOnChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Enter Email",
                    email: true,
                  },
                }}
                disabled={user.id !== 0 ? true : false}
              />
            </FormGroup>
          </Col>
        </div> */}
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Type
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-type-Input"
                name={nameOf(user).type}
                value={user.type}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Type" },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Permission Level
          </Label>
          <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(user).permissionLevel}
              value={user.permissionLevel}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                {"SELECT PERMISSION LEVEL"}
              </option>
              {userTypes.map(a => {
                return (
                  <option key={a.id} value={a.level}>
                    {a.description}
                  </option>
                )
              })}
            </AvField>
          </Col>
        </div>
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Description
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-password-Input"
                name={nameOf(user).description}
                value={user.description}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Description" },
                  minLength: {
                    value: 6,
                    errorMessage:
                      "Your description must be between 6 and 20 characters",
                  },
                  maxLength: {
                    value: 20,
                    errorMessage:
                      "Your description must be between 6 and 20 characters",
                  },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        <div className="row justify-content-end">
          <Col>
            {error && typeof error === "string" ? (
              <Alert color="danger">{error}</Alert>
            ) : null}
          </Col>
          <Col sm={3}>
            <div className="text-sm-end">
              <Button
                type="submit"
                // onClick={submit}
                color="primary"
                className="w-md"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : null}
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default withRouter(UserCrupdate)

UserCrupdate.propTypes = {
  user: PropTypes.object,
  error: PropTypes.any,
  history: PropTypes.object,
}
