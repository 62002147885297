import PropTypes from "prop-types"
import React, { Component, useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

// Other Layout related Component
import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"
import RightSidebar from "../CommonForBoth/RightSidebar"
import { getLocalStorage } from "helpers/common_functions"
import SocketContext from "context/socket"

const HorizontalLayout = props => {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    props.changeLayout("horizontal")
  })

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          theme={props.topbarTheme}
          isMenuOpened={menuOpen}
          openLeftMenuCallBack={() => setMenuOpen(!menuOpen)}
        />
        <Navbar menuOpen={menuOpen} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

HorizontalLayout.propTypes = {
  changeLayout: PropTypes.func /*  */,
  topbarTheme: PropTypes.any,
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStatetoProps, {
  changeLayout,
})(withRouter(HorizontalLayout))
