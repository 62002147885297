import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_SUCCESS,
  REMOVE_USER,
  SAVE_USER_SUCCESS,
  SET_USER,
  SAVE_USER_FAIL,
  REMOVE_USER_FROM_REDUX,
  SAVE_USER,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  SET_USER_SAVED,
  SET_USER_UPDATED,
  UPDATE_USER_FAIL,
  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  userUpdated: false,
  userSaved: false,
  loading: false,
  user: {},
  users: [],
  error: null,
  updateError: null,
  loadingAvalability: true
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: null,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload ?? [],
        loadingAvalability: false
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        users: [],
        error: action.payload,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload ?? {},
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case REMOVE_USER_FROM_REDUX:
      return {
        ...state,
        user: {},
      }
    case SAVE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userSaved: true,
      }
    case SAVE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        userUpdated: true,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        updateError: action.payload,
      }
    case SET_USER_SAVED:
      return {
        ...state,
        userSaved: action.payload,
      }
    case SET_USER_UPDATED:
      return {
        ...state,
        userUpdated: action.payload,
      }
    default:
      return state
  }
}

export default users
