import "flatpickr/dist/themes/material_blue.css"
import {
  getAgentModuleMaster,
  getAgentTransactionMaster,
} from "helpers/backend_helper"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Flatpickr from "react-flatpickr"
import "react-image-lightbox/style.css"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import {
  getAgentAudits,
  setAgentModulesToRedux,
  setAgentTransactionsToRedux,
} from "../../../store/actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import GridDownload from "components/GridDownLoad"

const UserAudit = props => {
  const dispatch = useDispatch()
  const {
    agentAudits,
    agentModules,
    agentTransactions,
    loadingAvalability,
    loading,
  } = useSelector(state => {
    const agentAudits = [...state.audit.agentAudits]
    const agentModules = [...state.app.agentModules]
    const agentTransactions = [...state.app.agentTransactions]
    const loadingAvalability = state.audit.agentAuditsLoading
    const loading = state.audit.loading

    return {
      agentAudits,
      agentModules,
      agentTransactions,
      loadingAvalability,
      loading,
    }
  })
  const [dateArray, setDateArray] = useState([
    moment().subtract(1, "week"),
    moment(),
  ])

  const [selectedModule, setSelectedModule] = useState({
    id: "ALL",
    name: "ALL",
  })
  const [selectedTransactionType, setSelectedTransactionType] = useState({
    id: "ALL",
    name: "ALL",
  })

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (rowContent, row) => {
        return moment(new Date(`${rowContent}`)).format("DD/MM/YYYY - HH:mm")
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "agent_transaction_master.type_code",
      text: "Transaction Type",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
    {
      dataField: "agent_module_master.type_code",
      text: "Module",
      sort: true,
      formatter: (rowContent, row) => {
        if (rowContent && rowContent !== null) {
          return rowContent
        } else {
          return "-"
        }
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: agentAudits.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(() => {
    Promise.all([getAgentModuleMaster(), getAgentTransactionMaster()])
      .then(values => {
        let moduleResponse = values[0]
        let transactionTypeResponse = values[1]
        if (moduleResponse && moduleResponse.status === 200) {
          dispatch(setAgentModulesToRedux(moduleResponse.data.data))
        }
        if (transactionTypeResponse && transactionTypeResponse.status === 200) {
          dispatch(
            setAgentTransactionsToRedux(transactionTypeResponse.data.data)
          )
        }
        dispatch(
          getAgentAudits({
            startDate: dateArray[0],
            endDate: dateArray[1],
            moduleId: "ALL",
            transactionType: "ALL",
          })
        )
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [])

  const { SearchBar } = Search

  const onRangeChange = dates => {
    var selectedDates = dates.map(date =>
      moment(date).format("YYYY-MM-DDTHH:mm:ss")
    )
    if (dates[0].valueOf() === dates[1].valueOf()) {
      selectedDates[1] = moment(selectedDates[1])
        .add(23, "h")
        .add(59, "m")
        .add(59, "s")
        .format("YYYY-MM-DDTHH:mm:ss")
    }
    setDateArray(selectedDates)
    dispatch(
      getAgentAudits({
        startDate: selectedDates[0],
        endDate: selectedDates[1],
        moduleId: selectedModule.id,
        transactionType: selectedTransactionType.id,
      })
    )
  }

  const onChangeModule = type => {
    setSelectedModule(type)
    dispatch(
      getAgentAudits({
        startDate: dateArray[0],
        endDate: dateArray[1],
        moduleId: type.id,
        transactionType: selectedTransactionType.id,
      })
    )
  }

  const onChangeTransactionType = type => {
    console.log(type)
    setSelectedTransactionType(type)
    dispatch(
      getAgentAudits({
        startDate: dateArray[0],
        endDate: dateArray[1],
        moduleId: selectedModule.id,
        transactionType: type.id,
      })
    )
  }

  const [reportModuleDropDown, setReportModuleDropDown] = useState(false)
  const [
    reporTransactionTypeDropDown,
    setReportTransactionTypeDropDown,
  ] = useState(false)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard User Audit</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <h3>Dashboard User Audit</h3>
          <Row className={loadingAvalability ? "loading-parent" : ""}>
            {!loadingAvalability && (
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={agentAudits}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={agentAudits}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={dates => {
                                      if (dates.length === 2) {
                                        onRangeChange(dates)
                                      }
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d-m-Y",
                                      defaultDate: [
                                        moment()
                                          .subtract(1, "week")
                                          .format("DD-MM-YYYY"),
                                        moment().format("DD-MM-YYYY"),
                                      ],
                                    }}
                                  />
                                </Col>

                                <Col md="1">
                                  <Dropdown
                                    isOpen={reportModuleDropDown}
                                    toggle={() =>
                                      setReportModuleDropDown(
                                        !reportModuleDropDown
                                      )
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedModule && selectedModule.name}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {agentModules.map(type => {
                                        return (
                                          <DropdownItem
                                            key={type.id}
                                            onClick={() => {
                                              onChangeModule(type)
                                            }}
                                          >
                                            {type.name}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </Col>

                                <Col md="1">
                                  <Dropdown
                                    isOpen={reporTransactionTypeDropDown}
                                    toggle={() =>
                                      setReportTransactionTypeDropDown(
                                        !reporTransactionTypeDropDown
                                      )
                                    }
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-primary"
                                    >
                                      {selectedTransactionType &&
                                        selectedTransactionType.name}
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {agentTransactions.map(type => {
                                        return (
                                          <DropdownItem
                                            key={type.id}
                                            onClick={() => {
                                              onChangeTransactionType(type)
                                            }}
                                          >
                                            {type.name}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </Col>

                                <Col md="3">
                                  <GridDownload
                                    title="Dashboard User Audit"
                                    dateArray={dateArray}
                                    columns={columns}
                                    data={agentAudits}
                                  />
                                </Col>
                              </Row>

                              {loading && (
                                <Row>
                                  <Col xl="12">
                                    <div style={{ height: 400 }}>
                                      <div
                                        style={{
                                          top: "40%",
                                          display: "flex",
                                          position: "absolute",
                                          left: "50%",
                                        }}
                                      >
                                        <div className="spinner-chase">
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                          <div className="chase-dot" />
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              {!loading && (
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Audits available
                                          </div>
                                        )}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            )}

            {loadingAvalability && (
              <div id="preloader">
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserAudit)
