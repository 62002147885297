export const GET_INQUIRIES = "GET_INQUIRIES"
export const GET_INQUIRIES_SUCCESS = "GET_INQUIRIES_SUCCESS"
export const GET_INQUIRIES_COUNT = "GET_INQUIRIES_COUNT"
export const GET_INQUIRIES_COUNT_SUCCESS = "GET_INQUIRIES_COUNT_SUCCESS"

export const GET_BUSINESS_INQUIRIES = "GET_BUSINESS_INQUIRIES"
export const GET_BUSINESS_INQUIRIES_SUCCESS = "GET_BUSINESS_INQUIRIES_SUCCESS"
export const GET_BUSINESS_INQUIRIES_COUNT = "GET_BUSINESS_INQUIRIES_COUNT"
export const GET_BUSINESS_INQUIRIES_COUNT_SUCCESS = "GET_BUSINESS_INQUIRIES_COUNT_SUCCESS"

export const GET_COMPOUND_INQUIRIES = "GET_COMPOUND_INQUIRIES"
export const GET_COMPOUND_INQUIRIES_COUNT = "GET_COMPOUND_INQUIRIES_COUNT"
export const GET_COMPOUND_INQUIRIES_COUNT_SUCCESS = "GET_COMPOUND_INQUIRIES_COUNT_SUCCESS"
export const GET_COMPOUND_INQUIRIES_SUCCESS = "GET_COMPOUND_INQUIRIES_SUCCESS"

export const GET_ASSESSMENT_TAX_INQUIRIES = "GET_ASSESSMENT_TAX_INQUIRIES"
export const GET_ASSESSMENT_TAX_INQUIRIES_SUCCESS = "GET_ASSESSMENT_TAX_INQUIRIES_SUCCESS"
export const GET_ASSESSMENT_TAX_INQUIRIES_COUNT = "GET_ASSESSMENT_TAX_INQUIRIES_COUNT"
export const GET_ASSESSMENT_TAX_INQUIRIES_COUNT_SUCCESS = "GET_ASSESSMENT_TAX_INQUIRIES_COUNT_SUCCESS"

