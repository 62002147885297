import {
  GET_AGENT_AUDITS,
  GET_AGENT_AUDITS_SUCCESS,
  GET_AUDIT_USER_SESSIONS,
  GET_AUDIT_USER_SESSIONS_SUCCESS,
  GET_CUSTOMER_AGENT_AUDITS,
  GET_CUSTOMER_AGENT_AUDITS_SUCCESS,
  GET_CHATBOT_SESSION_COUNT,
  GET_CHATBOT_SESSION_COUNT_SUCCESS,
  GET_LIVE_CHAT_SESSION_COUNT,
  GET_LIVE_CHAT_SESSION_COUNT_SUCCESS
} from "./actionTypes"

export const getAuditUserSessions = bodyParam => ({
  type: GET_AUDIT_USER_SESSIONS,
  payload: bodyParam,
})

export const getAuditUserSessionsSuccess = sessions => ({
  type: GET_AUDIT_USER_SESSIONS_SUCCESS,
  payload: sessions,
})

export const getCustomerAgentAudits = bodyParam => ({
  type: GET_CUSTOMER_AGENT_AUDITS,
  payload: bodyParam,
})

export const getCustomerAgentAuditsSuccess = sessions => ({
  type: GET_CUSTOMER_AGENT_AUDITS_SUCCESS,
  payload: sessions,
})

export const getAgentAudits = bodyParams => ({
  type: GET_AGENT_AUDITS,
  payload: bodyParams,
})

export const getAgentAuditsSuccess = audits => ({
  type: GET_AGENT_AUDITS_SUCCESS,
  payload: audits,
})

export const getChatbotSessionCount = filter => ({
  type: GET_CHATBOT_SESSION_COUNT,
  payload: filter,
})

export const getChatbotSessionCountSuccess = list => ({
  type: GET_CHATBOT_SESSION_COUNT_SUCCESS,
  payload: list,
})

export const getLiveChatSessionCount = filter => ({
  type: GET_LIVE_CHAT_SESSION_COUNT,
  payload: filter,
})

export const getLiveChatSessionCountSuccess = list => ({
  type: GET_LIVE_CHAT_SESSION_COUNT_SUCCESS,
  payload: list,
})