import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  deleteFAQ,
  getFAQs,
  removeFQAFromRedux,
  setFAQToRedux,
} from "store/actions"
import FAQCrupdate from "./FAQCrupdate"
import { API_URL } from "helpers/api_helper"

const FAQMaster = props => {
  const dispatch = useDispatch()
  const { faqs } = useSelector(state => {
    const faqs = [...state.app.faqs]
    return { faqs }
  })
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false)
  const [deleteFAQItem, setDeleteFAQItem] = useState({})

  useEffect(() => {
    dispatch(getFAQs())
  }, [])

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "language",
      text: "Description",
      sort: false,
      formatter: (rowContent, row) => {
        return rowContent === "EN" ? "English" : "Bahasa Malaysia"
      },
    },
    {
      dataField: "department_master.title",
      text: "Department",
      sort: true,
    },
    {
      dataField: "path",
      text: "PDF",
      sort: false,
      formatter: (rowContent, row) => {
        return (
          <div>
            <a
              href={`${API_URL}/masterdata/FAQ/file/${row.id}`}
              target={"_blank"}
              className="alert-link"
              rel="noopener noreferrer"
              download={row.fileName}
            >
              {row.fileName}
            </a>
          </div>
        )
      },
    },
    {
      dataField: "button",
      text: "Actions",
      formatter: (rowContent, row) => {
        return (
          <div className="d-flex gap-3">
            <Link
              className="text-success"
              to="#"
              onClick={() => {
                dispatch(removeFQAFromRedux())
                dispatch(setFAQToRedux(row))
                setIsEdit(true)
                setModal(true)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
            </Link>
            <Link
              className="text-danger"
              to="#"
              onClick={() => onDeleteFAQ(row)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
            </Link>
          </div>
        )
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 100,
    totalSize: faqs.length,
    custom: true,
  }

  const onDeleteFAQ = obj => {
    setDeleteFAQItem({ id: obj.id, name: obj.fileName })
    setDeleteConfirmAlert(true)
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <h3>List FAQ</h3>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={faqs}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={faqs}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => {
                                  dispatch(removeFQAFromRedux())
                                  setIsEdit(false)
                                  setModal(true)
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New FAQ
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              <Modal
                                isOpen={modal}
                                toggle={() => {
                                  setModal(!modal)
                                }}
                              >
                                <ModalHeader
                                  toggle={() => {
                                    setModal(!modal)
                                  }}
                                  tag="h4"
                                >
                                  {!!isEdit ? "Edit FAQ" : "Add FAQ"}
                                </ModalHeader>
                                <ModalBody>
                                  <FAQCrupdate
                                    onSaveSuccess={() => {
                                      setModal(false)
                                      toastr.success("", "Successfuly saved.")
                                      dispatch(getFAQs())
                                    }}
                                  />
                                </ModalBody>
                              </Modal>
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {deleteConfirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          onConfirm={() => {
            if (deleteFAQItem && deleteFAQItem.id > 0) {
              setDeleteConfirmAlert(false)
              dispatch(deleteFAQ(deleteFAQItem))
            }
          }}
          onCancel={() => setDeleteConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default withRouter(FAQMaster)
