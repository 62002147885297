import {
  UPDATE_CHAT,
  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_SUCCESS,
  POST_ADD_MESSAGE,
  POST_ADD_MESSAGE_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  SET_CURRENT_CHAT,
  CLEAR_CHAT_STATE,
  SET_MESSAGES,
  GET_ACTIVE_CHATS,
  GET_ACTIVE_CHATS_SUCCESS,
  GET_ACTIVE_CHATS_FAIL,
  GET_INACTIVE_CHATS,
  GET_INACTIVE_CHATS_SUCCESS,
  GET_INACTIVE_CHATS_FAIL,
  FILTER_ACTIVE_CHATS,
  FILTER_INACTIVE_CHATS,
  REMOVE_CHAT,
  SESSION_STATUS,
} from "./actionTypes"

export const clearChatState = () => ({
  type: CLEAR_CHAT_STATE,
})

export const getActiveChats = () => ({
  type: GET_ACTIVE_CHATS,
})

export const getActiveChatsSuccess = chats => ({
  type: GET_ACTIVE_CHATS_SUCCESS,
  payload: chats,
})

export const getActiveChatsFail = error => ({
  type: GET_ACTIVE_CHATS_FAIL,
  payload: error,
})

export const getInactiveChats = () => ({
  type: GET_INACTIVE_CHATS,
})

export const getInactiveChatsSuccess = chats => ({
  type: GET_INACTIVE_CHATS_SUCCESS,
  payload: chats,
})

export const getInactiveChatsFail = error => ({
  type: GET_INACTIVE_CHATS_FAIL,
  payload: error,
})

export const updateChat = chat => ({
  type: UPDATE_CHAT,
  payload: chat,
})

export const setCurrentChat = chat => ({
  type: SET_CURRENT_CHAT,
  payload: chat,
})

export const getMessages = sessionId => ({
  type: GET_MESSAGES,
  sessionId,
})

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages,
})

export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

export const addMessage = message => ({
  type: POST_ADD_MESSAGE,
  message,
})

export const addMessageSuccess = message => ({
  type: POST_ADD_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = error => ({
  type: POST_ADD_MESSAGE_FAIL,
  payload: error,
})

export const setMessages = messages => ({
  type: SET_MESSAGES,
  payload: messages,
})

export const filterActiveChats = searchText => ({
  type: FILTER_ACTIVE_CHATS,
  payload: searchText,
})

export const filterInactiveChats = searchText => ({
  type: FILTER_INACTIVE_CHATS,
  payload: searchText,
})

export const removeChatFromChatLists = chat => ({
  type: REMOVE_CHAT,
  payload: chat,
})

export const setChatSessionStatus = sessionId => ({
  type: SESSION_STATUS,
  payload: sessionId,
})
