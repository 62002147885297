import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_AGENT_AUDITS,
  GET_AUDIT_USER_SESSIONS,
  GET_CUSTOMER_AGENT_AUDITS,
  GET_CHATBOT_SESSION_COUNT,
  GET_LIVE_CHAT_SESSION_COUNT,
} from "./actionTypes"

import {
  getAgentAuditsSuccess,
  getAuditUserSessionsSuccess,
  getCustomerAgentAuditsSuccess,
  getChatbotSessionCountSuccess,
  getLiveChatSessionCountSuccess
} from "./actions"

import {
  getAgentAudits,
  getAllAuditUserSessions,
  getCustomerAgentAudits,
  getChatbotSessionCount,
  getLiveChatSessionCount
} from "../../helpers/backend_helper"

function* fetchAuditUserSessions({ payload }) {
  try {
    const response = yield call(getAllAuditUserSessions, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getAuditUserSessionsSuccess(response.data.data))
    } else {
      yield put(getAuditUserSessionsSuccess([]))
    }
  } catch (error) {
    yield put(getAuditUserSessionsSuccess([]))
  }
}

function* fetchCustomerAgentAudits({ payload }) {
  try {
    const response = yield call(getCustomerAgentAudits, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getCustomerAgentAuditsSuccess(response.data.data))
    } else {
      yield put(getCustomerAgentAuditsSuccess([]))
    }
  } catch (error) {
    yield put(getCustomerAgentAuditsSuccess([]))
  }
}

function* fetchAgentAudits({ payload }) {
  try {
    const response = yield call(getAgentAudits, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getAgentAuditsSuccess(response.data.data))
    } else {
      yield put(getAgentAuditsSuccess([]))
    }
  } catch (error) {
    yield put(getAgentAuditsSuccess([]))
  }
}

function* fetchChatbotSessionCount({ payload }) {
  try {
    const response = yield call(getChatbotSessionCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getChatbotSessionCountSuccess(response.data.data))
    } else {
      yield put(getChatbotSessionCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getChatbotSessionCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}

function* fetchLiveChatSessionCount({ payload }) {
  try {
    const response = yield call(getLiveChatSessionCount, payload)
    if (200 === response.status || 304 === response.status) {
      yield put(getLiveChatSessionCountSuccess(response.data.data))
    } else {
      yield put(getLiveChatSessionCountSuccess({daily: 0, monthly: 0, weekly: 0}))
    }
  } catch (error) {
    yield put(getLiveChatSessionCountSuccess({daily: 0, monthly: 0, weekly: 0}))
  }
}

function* auditSaga() {
  yield takeEvery(GET_AUDIT_USER_SESSIONS, fetchAuditUserSessions)
  yield takeEvery(GET_CUSTOMER_AGENT_AUDITS, fetchCustomerAgentAudits)
  yield takeEvery(GET_AGENT_AUDITS, fetchAgentAudits)
  yield takeEvery(GET_CHATBOT_SESSION_COUNT, fetchChatbotSessionCount)
  yield takeEvery(GET_LIVE_CHAT_SESSION_COUNT, fetchLiveChatSessionCount)
}

export default auditSaga
