import React, { useState } from "react"
import { withRouter } from "react-router"
import { nameOf } from "helpers/common_functions"
import { Button, Col, FormGroup, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { addPrescriptTitle, updatePrescriptTitle } from "helpers/backend_helper"

class PrescriptTitle {
  constructor(obj) {
    if (obj != null) {
      Object.assign(this, obj)
    } else {
      this.id = 0
      this.typeCode = ""
      this.description = ""
      this.language = "BM"
      this.moduleId = 0
    }
  }
}

const PrescriptTitleCrupdate = props => {
  const [title, setTitle] = useState(props.title ?? new PrescriptTitle())
  const [loading, setLoading] = useState(false)

  const valueOnChange = event => {
    setTitle({
      ...title,
      [event.target.name]: event.target.value,
    })
  }

  const saveTitle = () => {
    setLoading(true)
    addPrescriptTitle(title)
      .then(res => {
        setLoading(false)
        if (res.status === 200) {
          toastr.success("Successfuly saved.")
          props.hideModal()
        } else {
          toastr.error("Save error.")
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  const updateTitle = () => {
    setLoading(true)
    updatePrescriptTitle(title)
      .then(res => {
        setLoading(false)
        if (res.status === 200) {
          toastr.success("Successfuly updated.")
        } else {
          toastr.error("Update error.")
        }
      })
      .catch(e => {
        toastr.error(
          e,
          "Error."
        )
      })
  }

  const handleValidSubmit = (event, values) => {
    if (title.moduleId == 0) {
      toastr.error("Please selecte module.")
      return
    }
    if (title.id === 0) {
      saveTitle()
    } else {
      updateTitle()
    }
  }

  const handleInvalidSubmit = (event, errors, values) => {
    if (title.moduleId == 0) {
      toastr.error("Please selecte module.")
    }
  }

  return (
    <React.Fragment>
      <AvForm
        model={new PrescriptTitle()}
        onValidSubmit={handleValidSubmit}
        onInvalidSubmit={handleInvalidSubmit}
        className="needs-validation"
      >
        {/* <div className="row mb-4">
          <Label
            htmlFor="horizontal-firstname-Input"
            className="col-sm-3 col-form-label"
          >
            Code
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-firstname-Input"
                name={nameOf(title).typeCode}
                value={title.typeCode}
                onChange={valueOnChange}
                validate={{
                  required: { value: true, errorMessage: "Enter Code" },
                }}
              />
            </FormGroup>
          </Col>
        </div> */}
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Name
          </Label>
          <Col sm={9}>
            <FormGroup>
              <AvField
                type="text"
                className="form-control"
                id="horizontal-ic-Input"
                name={nameOf(title).description}
                value={title.description}
                onChange={valueOnChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Enter Description (max 300)",
                  },
                }}
                maxLength={300}
              />
            </FormGroup>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Language
          </Label>
          <Col sm={9}>
            <select
              className="form-control"
              name={nameOf(title).language}
              value={title.language}
              onChange={valueOnChange}
            >
              <option key={"BM"} value={"BM"}>
                Bahasa Malaysia
              </option>
              <option key={"EN"} value={"EN"}>
                English
              </option>
            </select>
          </Col>
        </div>
        <div className="row mb-4">
          <Label
            htmlFor="horizontal-password-Input"
            className="col-sm-3 col-form-label"
          >
            Module
          </Label>
          <Col sm={9}>
            <AvField
              className="form-control"
              name={nameOf(title).moduleId}
              value={title.moduleId}
              onChange={valueOnChange}
              required
              type="select"
            >
              <option key={0} value={""}>
                SELECT MODULE
              </option>
              {props.modules
                .filter(module => module.language === title.language)
                .map(module => {
                  return (
                    <option key={module.id} value={module.id}>
                      {module.description}
                    </option>
                  )
                })}
            </AvField>
          </Col>
        </div>
        <div className="row justify-content-end">
          <Col sm={9}>
            <div className="text-sm-end">
              <Button
                type="submit"
                // onClick={submit}
                color="primary"
                className="w-md"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : null}
                Submit
              </Button>
            </div>
          </Col>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default withRouter(PrescriptTitleCrupdate)
