import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import chat from "./chat/reducer"
import users from "./users/reducer"
import notifications from "./notifications/reducer"
import app from "./app/reducer"
import rating from "./rating/reducer"
import complaint from "./complaint/reducer"
import audit from "./audit/reducer"
import archive from "./archive/reducer"
import inquiry from "./inquiry/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  chat,
  users,
  notifications,
  app,
  rating,
  complaint,
  audit,
  inquiry,
})

export default rootReducer
