import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store"
import SocketContext, { socket } from "./context/socket"
import "./datatables.scss"
import "./custom-styles.scss"

import SessionTimeout from './components/SessionTimeout'


const app = (
  <SocketContext.Provider value={socket}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <SessionTimeout />
      </BrowserRouter>
    </Provider>
  </SocketContext.Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
