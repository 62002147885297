import { getAvailabilityMaster } from "helpers/backend_helper"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import {
  setAvailabilitiesToRedux,
  setCurrentUserAvalabilitySuccess,
  updateCurrentUserAvalability,
} from "store/actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const AgentAvailability = () => {
  const {
    profile,
    loadingAvalability,
    availabilityUpdateSuccess,
  } = useSelector(state => {
    return { ...state.Login }
  })
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const [availabilities, setAvailabilities] = useState([])
  const [selectedAvailability, setSelectedAvailability] = useState({})
  const [changeConfirmAlert, setChangeConfirmAlert] = useState(false)

  useEffect(() => {
    getAvailabilityMaster()
      .then(response => {
        if (response && response.status === 200) {
          setAvailabilities(response.data.data)
          dispatch(setAvailabilitiesToRedux(response.data.data))
        }
      })
      .catch(e => {
        toastr.error(e, "Error.")
      })
  }, [setAvailabilities])

  useEffect(() => {
    if (availabilityUpdateSuccess) {
      toastr.success("", "Successfuly updated.")
    }
    return () => {
      dispatch(setCurrentUserAvalabilitySuccess(false))
    }
  }, [availabilityUpdateSuccess])

  const changeAvalability = availability => {
    setSelectedAvailability(availability)
    setChangeConfirmAlert(true)
  }

  let status = profile
    ? availabilities.find(s => {
        if (s.id + "" == profile.availability) {
          return s?.type_code
        }
      })?.type_code
    : ""
  let statusColor = () => {
    switch (profile?.availability) {
      case 1:
      case "1":
        return "text-success"
      case 2:
      case "2":
        return "text-warning"
      case 3:
      case "3":
        return "text-warning"
      case 4:
      case "4":
        return "text-danger"
      default:
        return "text-secondary"
    }
  }

  return (
    <React.Fragment>
      <div className={loadingAvalability ? "loading-parent" : ""}>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button"
          >
            <span
              className={
                "mdi mdi-arrow-right-drop-circle float-end " + statusColor()
              }
            ></span>
            <span className="d-xl-inline-block me-1">{"Availability"}</span>

            <i className="mdi mdi-chevron-down d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {availabilities &&
              availabilities.map(a => {
                return (
                  <DropdownItem
                    key={a.id}
                    tag="button"
                    onClick={() => changeAvalability(a)}
                    className={
                      a.id == profile?.availability ? statusColor() : ""
                    }
                  >
                    {" "}
                    {/* {props.t("")} */}
                    {a.id == profile?.availability ? (
                      <span
                        className={
                          "mdi mdi-arrow-right-drop-circle float-end " +
                          statusColor()
                        }
                      ></span>
                    ) : (
                      ""
                    )}
                    {`${a.type_code}`}
                  </DropdownItem>
                )
              })}
          </DropdownMenu>
        </Dropdown>
        {changeConfirmAlert ? (
          <SweetAlert
            title="Are you sure you want change?"
            warning
            showCancel
            confirmButtonText="Yes, change it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              dispatch(
                updateCurrentUserAvalability({
                  availability: selectedAvailability.id,
                })
              )
              setChangeConfirmAlert(false)
            }}
            onCancel={() => setChangeConfirmAlert(false)}
          ></SweetAlert>
        ) : null}
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase-tiny">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AgentAvailability
