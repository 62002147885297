import {
  GET_COMPLAINTS_COUNT_SUCCESS,
  GET_COMPLAINTS_SUCCESS,
  GET_COMPLAINTS,
  GET_COMPLAINTS_FILE,
  GET_COMPLAINTS_FILE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  complaints: [],
  loadingAvalability: true,
  complaintsCount: {},
}

const complaints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPLAINTS:
      return {
        ...state,
        loading: true,
      }
    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: action.payload ?? [],
        loadingAvalability: false,
        loading: false,
      }
    case GET_COMPLAINTS_COUNT_SUCCESS:
      return {
        ...state,
        complaintsCount: action.payload ?? [],
      }
    default:
      return state
  }
}

export default complaints
