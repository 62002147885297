import React from "react"
import PropTypes, { bool, func } from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { CSVLink } from "react-csv"

const AssessmentTaxDetailModal = props => {
  const { isOpen, close, inquiry, details } = props

  const currencyFormatter = content => {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "MYR",
    })

    return formatter.format(parseFloat(content.replace(/,/g, "")))
  }

  const headers = [
    "#",
    "Message Timestamp",
    "Account No",
    "Name",
    "Address",
    "Lot Number",
    "Section District",
    "Property Address",
    "Rate",
    "Adjestment",
    "Capping Discount",
    "Yearly Value",
    "Balance",
    "Incentive",
    "Current Assesment Tax Total",
  ]

  const dateFormatter = string => {
    return moment(new Date(`${string}`)).format("DD/MM/YYYY - HH:mm")
  }

  const exportPDF = download => {
    const unit = "pt"
    const size = "legal" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = `Assessment Tax Inquiry Detail - ${
      (inquiry && inquiry.id) || ""
    }`
    const tableHeaders = [headers]
    const data = details.map((detail, index) => [
      index + 1,
      dateFormatter(detail.createdAt),
      detail.accountNumber,
      detail.name,
      detail.address,
      detail.lotNumber,
      detail.sectionDistrict,
      detail.propertyAddress,
      detail.rate,
      currencyFormatter(detail.adjustment),
      detail.cappingDiscount,
      currencyFormatter(detail.annualValue),
      currencyFormatter(detail.balanceAsAtDate),
      currencyFormatter(detail.incentive),
      currencyFormatter(detail.currentAssesmentTaxTotal),
    ])

    let content = {
      startY: 50,
      head: tableHeaders,
      body: data,
      styles: {
        fontSize: 5,
      },
      showHead: "everyPage",
      showFoot: "lastPage",
      // foot: [["", "", "Total", "", "13", "MYR 564.00"]],
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    if (download) {
      doc.save(
        `Assessment Tax Inquiry Detail - ${(inquiry && inquiry.id) || ""}`
      )
    } else {
      doc.output("pdfobjectnewwindow")
    }
  }

  const headerArrayOfArray = [headers]
  const detailsArrayOfArrays = details.map((detail, index) => [
    index + 1,
    dateFormatter(detail.createdAt),
    detail.accountNumber + "",
    detail.name,
    detail.address,
    detail.lotNumber,
    detail.sectionDistrict,
    detail.propertyAddress,
    detail.rate,
    currencyFormatter(detail.adjustment),
    detail.cappingDiscount,
    currencyFormatter(detail.annualValue),
    currencyFormatter(detail.balanceAsAtDate),
    currencyFormatter(detail.incentive),
    currencyFormatter(detail.currentAssesmentTaxTotal),
  ])
  const cvsData = headerArrayOfArray.concat(detailsArrayOfArrays)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={close}
      size={"xl"}
    >
      <div className="modal-content">
        <ModalHeader toggle={close}>Inquiry Details</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  {headers.map(header => {
                    return (
                      <th key={header} scope="col">
                        {header}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th>
                          <p className="text-muted mb-0">{index + 1}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {dateFormatter(item.createdAt)}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.accountNumber}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.name}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.address}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.lotNumber}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.sectionDistrict}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.propertyAddress}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">{item.rate}</p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {currencyFormatter(item.adjustment)}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {item.cappingDiscount}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {currencyFormatter(item.annualValue)}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {currencyFormatter(item.balanceAsAtDate)}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {currencyFormatter(item.incentive)}
                          </p>
                        </th>
                        <th>
                          <p className="text-muted mb-0">
                            {currencyFormatter(item.currentAssesmentTaxTotal)}
                          </p>
                        </th>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <CSVLink
            data={cvsData}
            filename={`Assessment Tax Inquiry Detail - ${
              (inquiry && inquiry.id) || ""
            }.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            CSV
          </CSVLink>
          <Button
            color="primary"
            onClick={() => {
              exportPDF(true)
            }}
          >
            <i className="mdi mdi-download" />
          </Button>
          <Button
            color="primary"
            onClick={() => {
              exportPDF(false)
            }}
          >
            <i className="mdi mdi-printer" />
          </Button>
          <Button type="button" color="secondary" onClick={close}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

AssessmentTaxDetailModal.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  inquiry: PropTypes.object,
  details: PropTypes.array,
}

export default AssessmentTaxDetailModal
